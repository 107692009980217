import script from "./QuizGraph.vue?vue&type=script&setup=true&lang=ts"
export * from "./QuizGraph.vue?vue&type=script&setup=true&lang=ts"

import "./QuizGraph.vue?vue&type=style&index=0&id=4d88d510&lang=scss"

const __exports__ = script;

export default __exports__
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading});
