import script from "./DBScanListModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./DBScanListModal.vue?vue&type=script&setup=true&lang=ts"

import "./DBScanListModal.vue?vue&type=style&index=0&id=b75e3d2e&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSpace,QBtn,QTable});qInstall(script, 'directives', {ClosePopup});
