import AnomalyMonitor from "../views/AnomalyMonitor.vue";
import ProjectList from "../views/ProjectList.vue";
import ProjectPage from "../views/ProjectPage.vue";

const routes = [
  {
    path: "/user-digital-project",
    name: "ProjectList",
    component: ProjectList,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        {
          text: "Цифровой профиль проекта",
          to: { name: "ProjectList" },
        },
      ],
    },
  },
  {
    path: "/user-digital-project/report",
    name: "ProjectPage",
    component: ProjectPage,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        // {
        //   text: "Цифровой профиль проекта",
        //   to: { name: "ProjectList" },
        // },
        {
          text: "Профиль проекта",
        },
      ],
    },
  },
  {
    path: "/user-digital-project/anomaly-monitor",
    name: "AnomalyMonitor",
    component: AnomalyMonitor,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        {
          text: "Цифровой профиль проекта",
          to: { name: "ProjectList" },
        },
        {
          text: "Монитор аномалий",
        },
      ],
    },
  },
];

export default {
  routes,
};
