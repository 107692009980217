<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">
      НСИ
    </div>
    <NSIList />
  </div>
</template>

<script>
import NSIList from "@/components/NSIList.vue";

export default {
    name: "NSI",
    components: {NSIList},
}
</script>

<style scoped>

</style>