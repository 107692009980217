
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProjectsChoice",
  props: {
    forIntersections: Boolean,
    inline: {
      type: Boolean,
      default: false,
    },
    projectsLimit: {
      type: Number,
      default: 1,
    },
    disableOthersIfLimit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkedProjects: [] as any,
      search: "",
      filter: "",
      tooltips: [] as any,
      show: false,
    };
  },

  computed: {
    getProjectsItems() {
      const projectItems = store.getters.getProjectsItems;
      console.log("PROJECT ITEMS", projectItems);
      let options: any = [];
      if (projectItems !== undefined) {
        for (let projectItem of projectItems) {
          options.push({
            value: projectItem.getProjectId(),
            label: projectItem.getProjectName(),
            ico: `project-logo-${projectItem.getProjectId()}`,
            disable: false,
          });
        }
      }

      if (this.forIntersections && this.checkedProjects.length === 3) {
        options.forEach((item: any, idx: number) => {
          item.disable = true;
          for (let i in this.checkedProjects) {
            if (item.value === this.checkedProjects[i]) {
              item.disable = false;
            }
          }
          if (item.disable === true) {
            this.tooltips.push(`label-${idx}`);
          }
        });
      }

      return options;
    },

    isGroupInline(): boolean {
      return !!this.inline;
    },
  },

  watch: {
    checkedProjects(val) {
      this.tooltips = [];
      this.loadProjectInfoItems(this.makeFilters(val));
      if (this.forIntersections) {
        this.disableUncheckedProjects();
      }
      if (this.disableOthersIfLimit) {
        this.disableUncheckedProjects();
      }

      console.log("CHECKED PROJECTS ", val);
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    makeChecked() {
      for (let i in store.getters.getCheckedProjects) {
        this.checkedProjects.push(store.getters.getCheckedProjects[i]);
        this.show = true;
      }
      if (this.disableOthersIfLimit) {
        this.disableUncheckedProjects();
      }
    },

    // TODO Что это за фильтр был
    getFilters() {
      return [
        // {
        //   field_name: 'project_id',
        //   operand: 'ilike',
        //   value: this.search,
        // },
      ];
    },
    disableUncheckedProjects() {
      if (this.checkedProjects.length === this.projectsLimit) {
        this.getProjectsItems.forEach((item: any) => {
          item.disable = true;
          for (let i in this.checkedProjects) {
            if (item.value === this.checkedProjects[i]) {
              item.disable = false;
            }
          }
        });
      } else {
        this.getProjectsItems.forEach((item: any) => {
          item.disable = false;
        });
      }
    },

    loadProjectItems() {
      return store
        .dispatch("getProjectsItems", {
          filter: this.getFilters(),
          sort: {
            name: "project_id",
            exp: "asc",
          },
        })
        .then(
          (resolve) => {
            let defaultChecked: any = [];

            resolve.items.forEach((item: any) => {
              if (defaultChecked.length < this.projectsLimit) {
                defaultChecked.push(item.getProjectId());
              }
            });

            store.dispatch("setCheckedProjects", defaultChecked);
            this.makeChecked();

            // store.dispatch('setCheckedProjects', this.checkedProjects)
            console.log("getProjectsItems resolve", resolve);
          },
          (reject) => {
            console.log("getProjectsItems reject", reject);
          }
        );
    },

    loadProjectInfoItems(filters: any) {
      console.log("LOAD PJ INFO ITEMS ", filters);
      return store
        .dispatch("getProjectInfoItems", {
          filter: filters,
        })
        .then(
          (resolve) => {
            console.log("getProjectInfoItems resolve", resolve);
          },
          (reject) => {
            console.log("getProjectInfoItems reject", reject);
          }
        );
    },

    makeFilters(checkedProjects: any) {
      let arr = [...checkedProjects];
      let filters: any = [];
      console.log("MAKE FILTERS ", arr);
      arr.forEach((item, idx) => {
        if (idx === 0) {
          filters.push({
            field_name: "pi.project_id",
            operand: "=",
            value: item,
            or: [],
          });
        } else {
          filters[0].or.push({
            field_name: "pi.project_id",
            operand: "=",
            value: item,
          });
        }
      });
      console.log("MAKE FILTERS FILTER ", filters);
      return filters;
    },

    loadData() {
      this.loadProjectItems();
    },
  },
});
