
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ScorecardDelete',

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        id: 0,
        parent_id: 0,
        title: '',
        hasChilds: false,
      },
    }
  },

  computed: {
    isScorecardBusy() {
      return store.getters.getIsScorecardBusy
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    actualDelete() {
      store.dispatch('deleteScorecardItem', { id: this.item.id }).then(
        (resolve) => {
          console.log('resolve', resolve)
          store.dispatch('setCurrentScorecardNode', null) // Очищаем текущий узел
          this.$emit(
            'afterAction',
            0 !== this.item.parent_id ? this.item.parent_id : null
          )
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
    },

    closeScorecardDelete() {
      this.$emit('hideModal')
    },

    loadData() {
      let currentScorecardNode = store.getters.getCurrentScorecardNode
      if (currentScorecardNode) {
        this.item = {
          id: currentScorecardNode.id,
          parent_id: currentScorecardNode.parent_id,
          title: currentScorecardNode.title,
          hasChilds: currentScorecardNode.hasChilds,
        }
      } else {
        this.closeScorecardDelete()
      }
    },
  },
})
