// source: social_network_message_src.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.SocialNetworkListMessageSrcRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageSrcResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageSrcSourceResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrc', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrcFilter', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrcFilterItem', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrcPagination', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrcSort', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageSrcSource', null, global);
goog.exportSymbol('proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrc.displayName = 'proto.proto.SocialNetworkMessageSrc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrcSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrcSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrcSource.displayName = 'proto.proto.SocialNetworkMessageSrcSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrcFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrcFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrcFilterItem.displayName = 'proto.proto.SocialNetworkMessageSrcFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrcFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkMessageSrcFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrcFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrcFilter.displayName = 'proto.proto.SocialNetworkMessageSrcFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrcSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrcSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrcSort.displayName = 'proto.proto.SocialNetworkMessageSrcSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageSrcPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageSrcPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageSrcPagination.displayName = 'proto.proto.SocialNetworkMessageSrcPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageSrcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageSrcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageSrcRequest.displayName = 'proto.proto.SocialNetworkListMessageSrcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageSrcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageSrcResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageSrcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageSrcResponse.displayName = 'proto.proto.SocialNetworkListMessageSrcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageSrcSourceRequest.displayName = 'proto.proto.SocialNetworkListMessageSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageSrcSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageSrcSourceResponse.displayName = 'proto.proto.SocialNetworkListMessageSrcSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.displayName = 'proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.displayName = 'proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrc.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrc.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    messageSrcText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageSrcTime: (f = msg.getMessageSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    project: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrc}
 */
proto.proto.SocialNetworkMessageSrc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrc;
  return proto.proto.SocialNetworkMessageSrc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrc}
 */
proto.proto.SocialNetworkMessageSrc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageSrcText(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMessageSrcTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessageSrcText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageSrcTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 source_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_guid = 4;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message_src_text = 5;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getMessageSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.setMessageSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp message_src_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getMessageSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
*/
proto.proto.SocialNetworkMessageSrc.prototype.setMessageSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.clearMessageSrcTime = function() {
  return this.setMessageSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageSrc.prototype.hasMessageSrcTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string project = 7;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrc.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrc} returns this
 */
proto.proto.SocialNetworkMessageSrc.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrcSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrcSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messageSrcText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    messageSrcTime: (f = msg.getMessageSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    project: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrcSource}
 */
proto.proto.SocialNetworkMessageSrcSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrcSource;
  return proto.proto.SocialNetworkMessageSrcSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrcSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrcSource}
 */
proto.proto.SocialNetworkMessageSrcSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageSrcText(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMessageSrcTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrcSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrcSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessageSrcText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMessageSrcTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 source_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 project_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_guid = 4;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sso_id = 5;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string message_src_text = 6;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getMessageSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setMessageSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp message_src_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getMessageSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
*/
proto.proto.SocialNetworkMessageSrcSource.prototype.setMessageSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.clearMessageSrcTime = function() {
  return this.setMessageSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.hasMessageSrcTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string project = 8;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSource} returns this
 */
proto.proto.SocialNetworkMessageSrcSource.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrcFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrcFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.SocialNetworkMessageSrcFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrcFilterItem;
  return proto.proto.SocialNetworkMessageSrcFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrcFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.SocialNetworkMessageSrcFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrcFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrcFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem} returns this
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem} returns this
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem} returns this
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SocialNetworkMessageSrcFilter or = 4;
 * @return {?proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcFilter, 4));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcFilter|undefined} value
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem} returns this
*/
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem} returns this
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageSrcFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkMessageSrcFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrcFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrcFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrcFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageSrcFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkMessageSrcFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrcFilter;
  return proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrcFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrcFilterItem;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrcFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrcFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrcFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageSrcFilterItem items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageSrcFilterItem>}
 */
proto.proto.SocialNetworkMessageSrcFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageSrcFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageSrcFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageSrcFilterItem>} value
 * @return {!proto.proto.SocialNetworkMessageSrcFilter} returns this
*/
proto.proto.SocialNetworkMessageSrcFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageSrcFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageSrcFilterItem}
 */
proto.proto.SocialNetworkMessageSrcFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageSrcFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkMessageSrcFilter} returns this
 */
proto.proto.SocialNetworkMessageSrcFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrcSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrcSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrcSort}
 */
proto.proto.SocialNetworkMessageSrcSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrcSort;
  return proto.proto.SocialNetworkMessageSrcSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrcSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrcSort}
 */
proto.proto.SocialNetworkMessageSrcSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrcSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrcSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSort} returns this
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageSrcSort} returns this
 */
proto.proto.SocialNetworkMessageSrcSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageSrcPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageSrcPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageSrcPagination}
 */
proto.proto.SocialNetworkMessageSrcPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageSrcPagination;
  return proto.proto.SocialNetworkMessageSrcPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageSrcPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageSrcPagination}
 */
proto.proto.SocialNetworkMessageSrcPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageSrcPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageSrcPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageSrcPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcPagination} returns this
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcPagination} returns this
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcPagination} returns this
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageSrcPagination} returns this
 */
proto.proto.SocialNetworkMessageSrcPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageSrcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageSrcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageSrcPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest}
 */
proto.proto.SocialNetworkListMessageSrcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageSrcRequest;
  return proto.proto.SocialNetworkListMessageSrcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageSrcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest}
 */
proto.proto.SocialNetworkListMessageSrcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrcFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageSrcSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageSrcPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageSrcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageSrcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageSrcPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SocialNetworkMessageSrcFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageSrcSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageSrcSort}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageSrcPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageSrcPagination}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageSrcResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageSrcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageSrcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageSrc.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageSrcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse}
 */
proto.proto.SocialNetworkListMessageSrcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageSrcResponse;
  return proto.proto.SocialNetworkListMessageSrcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageSrcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse}
 */
proto.proto.SocialNetworkListMessageSrcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrc;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrc.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageSrcRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageSrcRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageSrcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageSrcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrc.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageSrcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageSrc items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageSrc>}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageSrc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageSrc, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageSrc>} value
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse} returns this
*/
proto.proto.SocialNetworkListMessageSrcResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageSrc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageSrc}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageSrc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse} returns this
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageSrcRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageSrcRequest}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageSrcRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageSrcRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageSrcRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse} returns this
*/
proto.proto.SocialNetworkListMessageSrcResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcResponse} returns this
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageSrcPagination.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageSrcSourceRequest;
  return proto.proto.SocialNetworkListMessageSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrcFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageSrcSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageSrcPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageSrcPagination.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageSrcFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageSrcSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageSrcSort}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageSrcPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageSrcPagination}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
*/
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 message_grp_id = 4;
 * @return {number}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_full = 5;
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageSrcSource.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageSrcSourceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageSrcSourceResponse;
  return proto.proto.SocialNetworkListMessageSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrcSource;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcSource.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageSrcSourceRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageSrcSourceRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrcSource.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageSrcSourceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageSrcSource items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageSrcSource>}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageSrcSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageSrcSource, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageSrcSource>} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse} returns this
*/
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageSrcSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageSrcSource}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageSrcSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageSrcSourceRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageSrcSourceRequest}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageSrcSourceRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageSrcSourceRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageSrcSourceRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse} returns this
*/
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageSrcSourceResponse} returns this
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageSrcSourceResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageSrcFilter.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest;
  return proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageSrcFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageSrcFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageSrcFilter}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageSrcFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageSrcFilter|undefined} value
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} returns this
*/
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 message_grp_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_full = 3;
 * @return {boolean}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest} returns this
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse;
  return proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse} returns this
 */
proto.proto.SocialNetworkPersonalListMessageSrcSourceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
