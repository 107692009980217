

import store from '@/store'
import MainMixin from '@/mixins/main'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'
import ExportAnswers from "@/components/modules/polls/views/answers/ExportAnswers.vue";

export default defineComponent({
  name: 'TableAnswerSource',

  components: {
    ExportAnswers,
    DataLoading,
  },

  mixins: [MainMixin],

  props: {
    answer: Object as any,
    isFull: Boolean,
  },

  data() {
    return {
      columnsSource: [
        {
          name: 'id',
          field: 'id',
          label: 'Код',
          align: 'center',
        },
        {
          name: 'sso_id',
          field: 'sso_id',
          label: 'Пользователь',
          align: 'left',
        },
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'time',
          field: 'time',
          label: 'Время',
          align: 'center',
        },
      ],
      sourceFilter: [
        {
          field_name: 'al.answer_src_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      sourcePagination: {
        sortBy: 'asrc.anwser_src_text',
        descending: false,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageSourceOptions: [10, 25, 50, 100],
      markedFind: [] as string[],
      search: '',
      navigated:false
    }
  },

  computed: {

    isAnswerSrcSourceBusy() {
      return store.getters.isAnswerSrcSourceBusy
    },

    getAnswerSrcSourceItems() {
      const answerItems = store.getters.getAnswerSrcSourceItems
      let rows: any = []
      if (answerItems !== undefined) {
        for (let answerItem of answerItems) {
          rows.push({
            id: answerItem.getAnswerSrcId(),
            guid: answerItem.getAccountGuid(),
            sso_id: answerItem.getSsoId(),
            text: answerItem.getAnswerSrcText(),
            time: answerItem.getAnswerSrcTime(),
          })
        }
      }
      return rows
    },

  },

  created() {
    this.loadData()
  },

  methods: {

    // Переход на профиль пользователя
    openUserProfile(profile: string) {
      const routeData = this.$router.resolve({
        path: '/user-profile/' + profile,// + '?group=' + this.groupId + '&poll=' + this.pollId + '&question=' + this.questionId,
      })
      window.open(routeData.href, '_blank')
    },

    closeTableAnswerSource() {
      this.$emit('hideModal')
    },

    onRequestSource(props: any) {
      console.log('onRequestSource props', props)
      this.sourcePagination = props.pagination
      this.loadSourceAnswers(false)
    },

    findAnswers() {
      let extFilter: any[] = []
      this.markedFind = []

      if (this.search.length) {
        extFilter.push({
          field_name: 'asrc.answer_src_text',
          operand: 'ilike',
          value: this.search,
        }, {
          field_name: 'ac.sso_id::d_char64',
          operand: 'ilike',
          value: this.search,
        })
        this.markedFind.push(this.search)
      }

      this.sourceFilter[0].or = extFilter
      this.loadSourceAnswers(true) // true - переключаем на первую страницу
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
        new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
        "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
          (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка непривязанных ответов
    loadSourceAnswers(isResetPagination: boolean) {
      if (isResetPagination) {
        this.sourcePagination.page = 1
        this.sourcePagination.rowsNumber = 0
      }
      this.sourcePagination.sortBy = 'asrc.answer_src_text'
      this.sourcePagination.descending = false

      console.log('loadSourceAnswers this.sourceFilter', this.sourceFilter)
      store
        .dispatch('getAnswerSrcSourceItems', {
          filter: this.sourceFilter,
          sort: {
            name: this.sourcePagination.sortBy,
            exp: this.sourcePagination.descending ? 'DESC' : 'ASC',
          },
          pagination: {
            page: this.sourcePagination.page,
            limit: this.sourcePagination.rowsPerPage,
            pages: 0,
            cnt: this.sourcePagination.rowsNumber,
          },
          answer_grp_id: this.answer.id,
          is_full: this.isFull,
        })
        .then(
          (resolve) => {
            console.log('getAnswerSrcSourceItems resolve', resolve)
            this.sourcePagination.rowsNumber = resolve.pagination.getCnt()
          },
          (reject) => {
            console.log('getAnswerSrcSourceItems reject', reject)
          }
        )
    },

    loadPersonalSourceAnswers() {
      store
          .dispatch('getPersonalAnswerSrcSourceItems', {
            filter: this.sourceFilter,
            answer_grp_id: this.answer.id,
            is_full: this.isFull,
          })
          .then(() => {
            this.closeTableAnswerSource()

            this.$router.push({
              name: 'UserProjects',
              query: {
                data: 'personal'
              }
            })
            .finally(() => {
              this.navigated = true
            })
          })
    },

    loadData() {
      this.markedFind = []
      this.search = ''

      this.loadSourceAnswers(true)
    },
  },
})
