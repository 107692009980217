/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: scorecard_account_value.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as scorecard_account_value_pb from './scorecard_account_value_pb';


export class ScorecardAccountValueServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListScorecardAccountValue = new grpcWeb.MethodDescriptor(
    '/proto.ScorecardAccountValueService/ListScorecardAccountValue',
    grpcWeb.MethodType.UNARY,
    scorecard_account_value_pb.ListScorecardAccountValueRequest,
    scorecard_account_value_pb.ListScorecardAccountValueResponse,
    (request: scorecard_account_value_pb.ListScorecardAccountValueRequest) => {
      return request.serializeBinary();
    },
    scorecard_account_value_pb.ListScorecardAccountValueResponse.deserializeBinary
  );

  listScorecardAccountValue(
    request: scorecard_account_value_pb.ListScorecardAccountValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<scorecard_account_value_pb.ListScorecardAccountValueResponse>;

  listScorecardAccountValue(
    request: scorecard_account_value_pb.ListScorecardAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: scorecard_account_value_pb.ListScorecardAccountValueResponse) => void): grpcWeb.ClientReadableStream<scorecard_account_value_pb.ListScorecardAccountValueResponse>;

  listScorecardAccountValue(
    request: scorecard_account_value_pb.ListScorecardAccountValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: scorecard_account_value_pb.ListScorecardAccountValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ScorecardAccountValueService/ListScorecardAccountValue',
        request,
        metadata || {},
        this.methodDescriptorListScorecardAccountValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ScorecardAccountValueService/ListScorecardAccountValue',
    request,
    metadata || {},
    this.methodDescriptorListScorecardAccountValue);
  }

}

