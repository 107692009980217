<template>
  <q-card class="q-pa-md q-mb-md">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </q-card>
</template>

<script>
export default {
  name: 'LevelEducationChart',
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
        },
        legend: { enabled: false },
        title: {
          text: 'Уровень образования',
        },
        xAxis: {
          categories: [
            'Начальное общее',
            'Основное общее',
            'Среднее общее',
            'Среднее профессиональное',
            'Высшее (бакалавр)',
            'Высшее (специалист, магистратура)',
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Количество (тыс.)',
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} тыс.</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          bar: {
            pointPadding: 0.1,
            borderWidth: 0,
            borderRadius: 5,
          },
        },
        series: [
          {
            name: 'Уровень образования',
            colorByPoint: true,
            data: [
              {
                name: 'Начальное общее',
                y: 12,
              },
              {
                name: 'Основное общее',
                y: 10,
              },
              {
                name: 'Среднее общее',
                y: 6,
              },
              {
                name: 'Среднее профессиональное',
                y: 4,
              },
              {
                name: 'Высшее (бакалавр)',
                y: 2,
              },
              {
                name: 'Высшее (специалист, магистратура)',
                y: 1,
              },
            ],
          },
        ],
      },
    }
  },
}
</script>
