import script from "./AgChildExports.vue?vue&type=script&setup=true&lang=ts"
export * from "./AgChildExports.vue?vue&type=script&setup=true&lang=ts"

import "./AgChildExports.vue?vue&type=style&index=0&id=59f62a57&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
