import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

import store from "@/store";

import HomePage from "../views/HomePage.vue";
//TODO: Refactor this modules probably, most of them are useless being
// in main views folder and can be moved to components to their corresponding folders
import UserProfile from "../components/UserProfile/UserProfile.vue";
import NSI from "@/views/NSI.vue";
import RespProcess from "@/views/RespProcess.vue";
import EstimationUser from "@/views/EstimationUser.vue";
import RespAG from "@/views/RespAG.vue";
import RespGeneral from "@/views/RespGeneral.vue";
import ReferencePage from "@/views/ReferencePage.vue";
import SetPassword from "@/SetPassword.vue";
import ReportsPage from "@/views/ReportsPage.vue";
import VkMsg from "@/views/VkMsg.vue";
import RecSystem from "@/views/RecSystem.vue";
import ChangePassword from "@/ChangePassword.vue";

//@ts-expect-error
import * as modules0 from "../../src/components/modules/accounts/router/index.ts"; import * as modules1 from "../../src/components/modules/activity/router/index.ts"; import * as modules2 from "../../src/components/modules/cluster/router/index.ts"; import * as modules3 from "../../src/components/modules/intersection/router/index.ts"; import * as modules4 from "../../src/components/modules/main-page/router/index.ts"; import * as modules5 from "../../src/components/modules/manual-builder/router/index.ts"; import * as modules6 from "../../src/components/modules/polls/router/index.ts"; import * as modules7 from "../../src/components/modules/projects/router/index.ts"; import * as modules8 from "../../src/components/modules/rec-system/router/index.ts"; import * as modules9 from "../../src/components/modules/scoring/router/index.ts"; import * as modules10 from "../../src/components/modules/sql-builder/router/index.ts"; import * as modules11 from "../../src/components/modules/user-digital-project/router/index.ts"; import * as modules12 from "../../src/components/modules/users/router/index.ts"; let modules = [modules0, modules1, modules2, modules3, modules4, modules5, modules6, modules7, modules8, modules9, modules10, modules11, modules12];

declare const modules: { default: { routes: RouteRecordRaw[] } }[];

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      breadCrumb: [{ text: "Главная", icon: "home" }],
    },
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Просмотр профиля выбранного пользователя" },
      ],
    },
  },
  {
    path: "/nsi",
    name: "NSI",
    component: NSI,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "НСИ" },
      ],
    },
  },
  {
    path: "/reference",
    name: "ReferencePage",
    component: ReferencePage,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Справка" },
      ],
    },
  },
  {
    path: "/cls",
    name: "RespProcess",
    component: RespProcess,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Сообщения пользователей" },
      ],
    },
  },
  {
    path: "/cls/general",
    name: "RespGeneral",
    component: RespGeneral,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Сообщения пользователей", to: { name: "RespProcess" } },
        { text: "Общие" },
      ],
    },
  },
  {
    path: "/cls/ag_polls",
    name: "RespAG",
    component: RespAG,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Сообщения пользователей", to: { name: "RespProcess" } },
        { text: "Опросы АГ" },
      ],
    },
  },
  {
    path: "/cls/vk_messages",
    name: "VkMsg",
    component: VkMsg,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Сообщения пользователей", to: { name: "RespProcess" } },
        { text: "Сообщения ВК" },
      ],
    },
  },
  {
    path: "/estimation",
    name: "Estimation",
    component: EstimationUser,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Оценка пользователей" },
      ],
    },
  },
  {
    path: "/reports",
    name: "ReportsPage",
    component: ReportsPage,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Отчеты" },
      ],
    },
  },
  {
    path: "/recommend-system",
    name: "RecSystem",
    component: RecSystem,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Рекомендательная система" },
      ],
    },
  },
  {
    path: "/registration-confirm",
    name: "RegistrationConfirm",
    component: SetPassword,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
];

for (const m of modules) {
  routes.push(...m.default.routes);
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const isAdmin = to.matched.some((record) => record.meta.admin);
  const isEditor = to.matched.some((record) => record.meta.editor);

  if (localStorage.getItem("access-key")) {
    store.dispatch("getUserAccess").then((res) => {
      if (isAdmin && !isEditor) {
        if (res === "admin") return next();

        return next(HomePage);
      }

      if (isAdmin && isEditor) {
        if (res === "editor" || res === "admin") return next();

        return next(HomePage);
      }

      if (to.path.includes("/registration-confirm")) return next("/");

      next();
    });
  } else {
    next();
  }
});

export default () => router;
