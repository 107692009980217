/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: actions.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as actions_pb from './actions_pb';


export class ActionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAction = new grpcWeb.MethodDescriptor(
    '/proto.ActionService/ListAction',
    grpcWeb.MethodType.UNARY,
    actions_pb.ListActionRequest,
    actions_pb.ListActionResponse,
    (request: actions_pb.ListActionRequest) => {
      return request.serializeBinary();
    },
    actions_pb.ListActionResponse.deserializeBinary
  );

  listAction(
    request: actions_pb.ListActionRequest,
    metadata: grpcWeb.Metadata | null): Promise<actions_pb.ListActionResponse>;

  listAction(
    request: actions_pb.ListActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: actions_pb.ListActionResponse) => void): grpcWeb.ClientReadableStream<actions_pb.ListActionResponse>;

  listAction(
    request: actions_pb.ListActionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: actions_pb.ListActionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActionService/ListAction',
        request,
        metadata || {},
        this.methodDescriptorListAction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActionService/ListAction',
    request,
    metadata || {},
    this.methodDescriptorListAction);
  }

  methodDescriptorListActionPoint = new grpcWeb.MethodDescriptor(
    '/proto.ActionService/ListActionPoint',
    grpcWeb.MethodType.UNARY,
    actions_pb.ListActionPointRequest,
    actions_pb.ListActionPointResponse,
    (request: actions_pb.ListActionPointRequest) => {
      return request.serializeBinary();
    },
    actions_pb.ListActionPointResponse.deserializeBinary
  );

  listActionPoint(
    request: actions_pb.ListActionPointRequest,
    metadata: grpcWeb.Metadata | null): Promise<actions_pb.ListActionPointResponse>;

  listActionPoint(
    request: actions_pb.ListActionPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: actions_pb.ListActionPointResponse) => void): grpcWeb.ClientReadableStream<actions_pb.ListActionPointResponse>;

  listActionPoint(
    request: actions_pb.ListActionPointRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: actions_pb.ListActionPointResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ActionService/ListActionPoint',
        request,
        metadata || {},
        this.methodDescriptorListActionPoint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ActionService/ListActionPoint',
    request,
    metadata || {},
    this.methodDescriptorListActionPoint);
  }

}

