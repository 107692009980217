
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'
import SplitFormSingle from './SplitFormSingle.vue'

const defaultSimilarity = '0.6'

export default defineComponent({
  name: 'WorkingAnswerPanel',

  components: {
    SplitFormSingle,
    DataLoading,
  },

  data() {
    return {
      isActiveModal: false,
      isActiveModalSplitSingle: false,
      isActiveModalSource: false,
      currentActionAnswer: null as any,
      columnsWorked: [
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество голосов',
          align: 'center',
        },
        {
          name: 'action',
          label: '',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 40px',
          style: 'width: 40px',
        },
      ],
      similarColumn: {
          name: 'similarity',
          field: 'similarity',
          label: 'Коэффициент схожести',
          align: 'center',
      },
      workedFilter: [
        {
          field_name: 'ag.answer_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
        {
          field_name: 'ag.answer_grp_id',
          operand: '<>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      workedPagination: {
        sortBy: 'answer_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageWorkedOptions: [10, 25, 50, 100],
      findType: 'similar',
      findTypeOptions: [
        { label: 'Семантика', value: 'similar', disable: false },
        { label: 'Простой поиск', value: 'single' },
      ],
      markedFind: [] as string[],
      selectedWorked: [],
      search: '',
      similarity: defaultSimilarity as any,
      typeOptions:[
        { id: 0, title: 'Поиск по семантике', value: 'similar', disable: true },
        { id: 1, title: 'Простой поиск', value: 'single' },
      ],
      typeSelect: null as any,
    }
  },

  computed: {

    getQuestionId() {
      let currentQuestion = store.getters.getQuestionItem
      return currentQuestion ? currentQuestion.id : 0
    },

    isCurrentAnswerResult() {
      return null !== store.getters.getCurrentAnswerResult
    },

    getCurrentAnswerResult() {
      let curAnswerResult = store.getters.getCurrentAnswerResult
      return curAnswerResult
        ? curAnswerResult
        : {
            id: 0,
            text: '',
            count: 0,
            processed: false,
            sum: 0,
            has_childs: false
          }
    },

    isAnswerGrpWorkedBusy() {
      return store.getters.isAnswerGrpWorkedBusy
    },

    getAnswerGrpWorkedItems() {
      const answerItems = store.getters.getAnswerGrpWorkedItems
      let rows: any = []
      if (answerItems !== undefined) {
        for (let answerItem of answerItems) {
          rows.push({
            id: answerItem.getAnswerGrpId(),
            text: answerItem.getAnswerGrpText(),
            count: answerItem.getAnswerGrpCount(),
            processed: answerItem.getAnswerGrpProcessed(),
            sum: answerItem.getAnswerGrpSum(),
            similarity: answerItem.getSimilarity()
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  created() {
    this.loadData(false, true)
  },

  methods: {

    onRowShowAnswerSource(row: any, isFull: boolean) {
      this.$emit('showSource', row, isFull)
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalSplitSingle = false
      this.currentActionAnswer = null
    },

    splitSingle() {
      this.isActiveModalSplitSingle = true
      console.log('splitSingle')
      this.isActiveModal = true
    },

    actionRow(method: any, row: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('row', row)
      this.currentActionAnswer = Object.assign(row)
      switch (method) {
        case 'split':
          this.splitSingle()
          break
        default:
          console.log('Неизвестный метод')
          console.log('method', method)
      }
    },

    afterActionSplitSingle() {
      this.$emit('afterAction', this.getCurrentAnswerResult.id)
      this.hideModal()
    },

    makeActionWorked() {
        if (!this.isCurrentAnswerResult) {
            console.log('Не выбран ответ в левой панели')
            return false
        }
        if (this.selectedWorked.length < 1) {
            console.log('Для объединения ответов должен быть выбран хотя бы один ответ')
            return false
        }

        let reducedIds = this.selectedWorked.map((item: any) => item.id)

        store.dispatch('reduceAnswerGrpItems', {
            id: this.getCurrentAnswerResult.id,
            reduced_ids: reducedIds
        })
        .then(
          (resolve) => {
            console.log('makeActionWorked resolve', resolve)
            this.$emit('afterAction', resolve.getAnswerGrpId())
          },
          (reject) => {
            console.log('makeActionWorked reject', reject)
          }
        )
        return true
    },

    onUpdateSelectedWorked(event: any) {
        console.log('onUpdateSelectedWorked event', event)
        /*
        if (!this.isCurrentAnswerResult && ('single' == this.findType)) {
            if (event.length) {
                if (event[0].text != this.reduceAnswerText) {
                    this.reduceAnswerText = event[0].text
                }
            } else {
                this.reduceAnswerText = ''
            }
        }
        */
    },

    changeFindType(event: any) {
        console.log('changeFindType event', event.value)
        switch (event.value) {
            case 'single':
                if (this.columnsWorked.length == 4) {
                    this.columnsWorked.splice(2, 1)
                }
                break
            case 'similar':
                if (this.columnsWorked.length == 3) {
                    this.columnsWorked.splice(2, 0, this.similarColumn)
                }
                break
        }
        this.findAnswers()
    },

    onRequestWorked(props: any) {
      console.log('onRequestWorked props', props)
      this.workedPagination = props.pagination
      this.loadWorkedAnswers(false)
    },

    findAnswers() {
      let extFilter: any[] = []
      this.markedFind = []

      switch (this.typeSelect.value) {
        case 'single': {
          if (this.search.length) {
            extFilter.push({
              field_name: 'ag.answer_grp_text',
              operand: 'ilike',
              value: this.search,
            })
            this.markedFind.push(this.search)
          }
          break
        }
        case 'similar': {
          console.log('findAnswers - similar')

          let similarity = parseFloat(this.similarity)
          extFilter = [
              {
                field_name: 'ag.answer_grp_text',
                operand: 'similar_' + similarity.toFixed(2),
                //operand: 'w_similar_' + similarity.toFixed(2),
                value: this.getCurrentAnswerResult.text,
              }
          ]
          break
        }
        default: {
          console.log(
            'findAnswers - неизвестный тип поиска this.findType',
            this.typeSelect.value
          )
          this.markedFind = []
          return false
        }
      }

      this.workedFilter[0].or = extFilter
      this.loadWorkedAnswers(true) // true - переключаем на первую страницу

      return true
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
        new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
        "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
          (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка непривязанных ответов
    loadWorkedAnswers(isResetPagination: boolean) {
      if (isResetPagination) {
        this.workedPagination.page = 1
        this.workedPagination.rowsNumber = 0
      }
      switch (this.typeSelect.value) {
        case 'single': {
          this.workedPagination.sortBy = 'answer_grp_sum'
          this.workedPagination.descending = true
          break
        }
        case 'similar': {
          this.workedPagination.sortBy = 'similarity'
          this.workedPagination.descending = true
          break
        }
        default: {
          console.log(
            'loadWorkedAnswers - неизвестный тип поиска this.findType',
            this.typeSelect.value
          )
        }
      }
      console.log('loadWorkedAnswers this.workedFilter', this.workedFilter)
      store
        .dispatch('getAnswerGrpWorkedItems', {
          filter: this.workedFilter,
          sort: {
            name: this.workedPagination.sortBy,
            exp: this.workedPagination.descending ? 'DESC' : 'ASC',
          },
          pagination: {
            page: this.workedPagination.page,
            limit: this.workedPagination.rowsPerPage,
            pages: 0,
            cnt: this.workedPagination.rowsNumber,
          },
          question_id: this.getQuestionId,
        })
        .then(
          (resolve) => {
            console.log('getAnswerGrpWorkedItems resolve', resolve)
            this.workedPagination.rowsNumber = resolve.pagination.getCnt()
          },
          (reject) => {
            console.log('getAnswerGrpWorkedItems reject', reject)
          }
        )
    },

    loadData(isLoadAnswers: boolean, isResetSimilarity: boolean) {
      this.typeSelect = this.typeOptions[0]
      let findType = this.typeSelect.value

      this.selectedWorked = []
      this.markedFind = []
      
      if (isResetSimilarity) {
        this.search = ''
        findType = 'similar'
        this.similarity = defaultSimilarity
      }
      this.typeOptions[0].disable = !this.isCurrentAnswerResult
      if (('similar' == findType) && !this.isCurrentAnswerResult) {
        findType = 'single'
      }
      switch (findType) {
        case 'single':
          if (this.columnsWorked.length == 4) {
            this.columnsWorked.splice(2, 1)
          }
          break
        case 'similar':
          if (this.columnsWorked.length == 3) {
            this.columnsWorked.splice(2, 0, this.similarColumn)
          }
          break
      }
      this.typeSelect.value = findType

      if (isLoadAnswers) {
        let extFilter: any[] = []
        this.workedFilter[1].value = this.getCurrentAnswerResult.id

        switch (this.typeSelect.value) {
          case 'single': {
            if (this.search.length) {
              extFilter.push({
                field_name: 'ag.answer_grp_text',
                operand: 'ilike',
                value: this.search,
              })
              this.markedFind.push(this.search)
            }
            break
          }
          case 'similar': {
            console.log('findAnswers - similar')
            let similarity = parseFloat(this.similarity)
            extFilter = [
              {
                field_name: 'ag.answer_grp_text',
                operand: 'similar_' + similarity.toFixed(2),
                //operand: 'w_similar_' + similarity.toFixed(2),
                value: this.getCurrentAnswerResult.text,
              }
            ]
            break
          }
          default: {
            console.log(
              'findAnswers - неизвестный тип поиска this.findType',
              this.typeSelect.value
            )
            this.markedFind = []
            return false
          }
        }
        this.workedFilter[0].or = extFilter
        this.loadWorkedAnswers(true)
      }
    },
  },
})
