/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: poll_load.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as poll_load_pb from './poll_load_pb';


export class PollLoadServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListPollLoad = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/ListPollLoad',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadRequest,
    poll_load_pb.ListPollLoadResponse,
    (request: poll_load_pb.ListPollLoadRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.ListPollLoadResponse.deserializeBinary
  );

  listPollLoad(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.ListPollLoadResponse>;

  listPollLoad(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.ListPollLoadResponse>;

  listPollLoad(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/ListPollLoad',
        request,
        metadata || {},
        this.methodDescriptorListPollLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/ListPollLoad',
    request,
    metadata || {},
    this.methodDescriptorListPollLoad);
  }

  methodDescriptorListPollLoadChildren = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/ListPollLoadChildren',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadRequest,
    poll_load_pb.ListPollLoadResponse,
    (request: poll_load_pb.ListPollLoadRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.ListPollLoadResponse.deserializeBinary
  );

  listPollLoadChildren(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.ListPollLoadResponse>;

  listPollLoadChildren(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.ListPollLoadResponse>;

  listPollLoadChildren(
    request: poll_load_pb.ListPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/ListPollLoadChildren',
        request,
        metadata || {},
        this.methodDescriptorListPollLoadChildren,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/ListPollLoadChildren',
    request,
    metadata || {},
    this.methodDescriptorListPollLoadChildren);
  }

  methodDescriptorLoadPollLoad = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/LoadPollLoad',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.LoadPollLoadRequest,
    poll_load_pb.LoadPollLoadResponse,
    (request: poll_load_pb.LoadPollLoadRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.LoadPollLoadResponse.deserializeBinary
  );

  loadPollLoad(
    request: poll_load_pb.LoadPollLoadRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.LoadPollLoadResponse>;

  loadPollLoad(
    request: poll_load_pb.LoadPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.LoadPollLoadResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.LoadPollLoadResponse>;

  loadPollLoad(
    request: poll_load_pb.LoadPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.LoadPollLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/LoadPollLoad',
        request,
        metadata || {},
        this.methodDescriptorLoadPollLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/LoadPollLoad',
    request,
    metadata || {},
    this.methodDescriptorLoadPollLoad);
  }

  methodDescriptorCheckPollLoad = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/CheckPollLoad',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.CheckPollLoadRequest,
    poll_load_pb.CheckPollLoadResponse,
    (request: poll_load_pb.CheckPollLoadRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.CheckPollLoadResponse.deserializeBinary
  );

  checkPollLoad(
    request: poll_load_pb.CheckPollLoadRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.CheckPollLoadResponse>;

  checkPollLoad(
    request: poll_load_pb.CheckPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.CheckPollLoadResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.CheckPollLoadResponse>;

  checkPollLoad(
    request: poll_load_pb.CheckPollLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.CheckPollLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/CheckPollLoad',
        request,
        metadata || {},
        this.methodDescriptorCheckPollLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/CheckPollLoad',
    request,
    metadata || {},
    this.methodDescriptorCheckPollLoad);
  }

  methodDescriptorListPollLoadAccounts = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/ListPollLoadAccounts',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadAccountsRequest,
    poll_load_pb.ListPollLoadAccountsResponse,
    (request: poll_load_pb.ListPollLoadAccountsRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.ListPollLoadAccountsResponse.deserializeBinary
  );

  listPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.ListPollLoadAccountsResponse>;

  listPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadAccountsResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.ListPollLoadAccountsResponse>;

  listPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/ListPollLoadAccounts',
        request,
        metadata || {},
        this.methodDescriptorListPollLoadAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/ListPollLoadAccounts',
    request,
    metadata || {},
    this.methodDescriptorListPollLoadAccounts);
  }

  methodDescriptorPollSourcesDistrChart = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/PollSourcesDistrChart',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.PollSourcesDistrChartRequest,
    poll_load_pb.PollSourcesDistrChartResponse,
    (request: poll_load_pb.PollSourcesDistrChartRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.PollSourcesDistrChartResponse.deserializeBinary
  );

  pollSourcesDistrChart(
    request: poll_load_pb.PollSourcesDistrChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.PollSourcesDistrChartResponse>;

  pollSourcesDistrChart(
    request: poll_load_pb.PollSourcesDistrChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.PollSourcesDistrChartResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.PollSourcesDistrChartResponse>;

  pollSourcesDistrChart(
    request: poll_load_pb.PollSourcesDistrChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.PollSourcesDistrChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/PollSourcesDistrChart',
        request,
        metadata || {},
        this.methodDescriptorPollSourcesDistrChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/PollSourcesDistrChart',
    request,
    metadata || {},
    this.methodDescriptorPollSourcesDistrChart);
  }

  methodDescriptorPersonalListPollLoadAccounts = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/PersonalListPollLoadAccounts',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadAccountsRequest,
    poll_load_pb.PersonalListPollLoadAccountsResponse,
    (request: poll_load_pb.ListPollLoadAccountsRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.PersonalListPollLoadAccountsResponse.deserializeBinary
  );

  personalListPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.PersonalListPollLoadAccountsResponse>;

  personalListPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.PersonalListPollLoadAccountsResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.PersonalListPollLoadAccountsResponse>;

  personalListPollLoadAccounts(
    request: poll_load_pb.ListPollLoadAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.PersonalListPollLoadAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/PersonalListPollLoadAccounts',
        request,
        metadata || {},
        this.methodDescriptorPersonalListPollLoadAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/PersonalListPollLoadAccounts',
    request,
    metadata || {},
    this.methodDescriptorPersonalListPollLoadAccounts);
  }

  methodDescriptorGetPollLoadVotesByDate = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/GetPollLoadVotesByDate',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.GetPollLoadVotesByDateRequest,
    poll_load_pb.GetPollLoadVotesByDateResponse,
    (request: poll_load_pb.GetPollLoadVotesByDateRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.GetPollLoadVotesByDateResponse.deserializeBinary
  );

  getPollLoadVotesByDate(
    request: poll_load_pb.GetPollLoadVotesByDateRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.GetPollLoadVotesByDateResponse>;

  getPollLoadVotesByDate(
    request: poll_load_pb.GetPollLoadVotesByDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.GetPollLoadVotesByDateResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.GetPollLoadVotesByDateResponse>;

  getPollLoadVotesByDate(
    request: poll_load_pb.GetPollLoadVotesByDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.GetPollLoadVotesByDateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/GetPollLoadVotesByDate',
        request,
        metadata || {},
        this.methodDescriptorGetPollLoadVotesByDate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/GetPollLoadVotesByDate',
    request,
    metadata || {},
    this.methodDescriptorGetPollLoadVotesByDate);
  }

  methodDescriptorGetPollLoadAnswers = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/GetPollLoadAnswers',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.GetPollLoadAnswersRequest,
    poll_load_pb.GetPollLoadAnswersResponse,
    (request: poll_load_pb.GetPollLoadAnswersRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.GetPollLoadAnswersResponse.deserializeBinary
  );

  getPollLoadAnswers(
    request: poll_load_pb.GetPollLoadAnswersRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.GetPollLoadAnswersResponse>;

  getPollLoadAnswers(
    request: poll_load_pb.GetPollLoadAnswersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.GetPollLoadAnswersResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.GetPollLoadAnswersResponse>;

  getPollLoadAnswers(
    request: poll_load_pb.GetPollLoadAnswersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.GetPollLoadAnswersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/GetPollLoadAnswers',
        request,
        metadata || {},
        this.methodDescriptorGetPollLoadAnswers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/GetPollLoadAnswers',
    request,
    metadata || {},
    this.methodDescriptorGetPollLoadAnswers);
  }

  methodDescriptorListPollLoadAnswerAccounts = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/ListPollLoadAnswerAccounts',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadAnswerAccountsRequest,
    poll_load_pb.ListPollLoadAnswerAccountsResponse,
    (request: poll_load_pb.ListPollLoadAnswerAccountsRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.ListPollLoadAnswerAccountsResponse.deserializeBinary
  );

  listPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.ListPollLoadAnswerAccountsResponse>;

  listPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadAnswerAccountsResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.ListPollLoadAnswerAccountsResponse>;

  listPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.ListPollLoadAnswerAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/ListPollLoadAnswerAccounts',
        request,
        metadata || {},
        this.methodDescriptorListPollLoadAnswerAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/ListPollLoadAnswerAccounts',
    request,
    metadata || {},
    this.methodDescriptorListPollLoadAnswerAccounts);
  }

  methodDescriptorPersonalListPollLoadAnswerAccounts = new grpcWeb.MethodDescriptor(
    '/proto.PollLoadService/PersonalListPollLoadAnswerAccounts',
    grpcWeb.MethodType.UNARY,
    poll_load_pb.ListPollLoadAnswerAccountsRequest,
    poll_load_pb.PersonalListPollLoadAnswerAccountsResponse,
    (request: poll_load_pb.ListPollLoadAnswerAccountsRequest) => {
      return request.serializeBinary();
    },
    poll_load_pb.PersonalListPollLoadAnswerAccountsResponse.deserializeBinary
  );

  personalListPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_load_pb.PersonalListPollLoadAnswerAccountsResponse>;

  personalListPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_load_pb.PersonalListPollLoadAnswerAccountsResponse) => void): grpcWeb.ClientReadableStream<poll_load_pb.PersonalListPollLoadAnswerAccountsResponse>;

  personalListPollLoadAnswerAccounts(
    request: poll_load_pb.ListPollLoadAnswerAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_load_pb.PersonalListPollLoadAnswerAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollLoadService/PersonalListPollLoadAnswerAccounts',
        request,
        metadata || {},
        this.methodDescriptorPersonalListPollLoadAnswerAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollLoadService/PersonalListPollLoadAnswerAccounts',
    request,
    metadata || {},
    this.methodDescriptorPersonalListPollLoadAnswerAccounts);
  }

}

