<template>
  <div style="width: 100%; position: relative">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
    <q-inner-loading
      :showing="isDynamicListBusy"
      color="grey"
    />
  </div>
</template>

<script>
import store from '@/store'
import {toRaw} from "vue";

export default {
  name: 'DynamicChart',

  props: {
    sortType: String,
    dateMin: String,
    dateMax: String
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          zoomType: 'x',
        },
        boost: {
          useGPUTranslations: true,
        },
        title: {
          text: 'Динамика регистраций',
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Количество пользователей',
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        series: [],
      },
    }
  },

  computed: {
    dynamicList() {
      const rawList = store.getters.getDynamicList
      const filteredList = {}

      for (let i in rawList) {
        filteredList[rawList[i].getProjectName()] = rawList[i].getItemsList()
      }

      return filteredList
    },

    isDynamicListBusy() {
      return store.getters.isDynamicListBusy
    },

    metricValues() {
      const metricList = store.getters.getMetricChecked

      return toRaw(metricList)
    }
  },

  watch: {
    dynamicList: {
      handler(newValue) {
        if (Object.keys(newValue).length != 0) {
          console.log(newValue)
          let index = 0
          this.chartOptions.series = []

          for (let i in newValue) {
            for (let j of newValue[i]) {
              if (!this.chartOptions.series[index]) {
                this.chartOptions.series[index] = { data: [], name: i }
                let date = j.getRegInterval().split('-')

                if (this.sortType === 'day') {
                  this.chartOptions.series[index].data.push([
                    Date.UTC(date[0], date[1] - 1, date[2]),
                    j.getRegCount(),
                  ])
                }

                if (this.sortType === 'month') {
                    this.chartOptions.series[index].data.push([
                        Date.UTC(date[0], date[1] - 1),
                        j.getRegCount(),
                    ])
                }

                if (this.sortType === 'year') {
                    this.chartOptions.series[index].data.push([
                        Date.UTC(date[0]),
                        j.getRegCount(),
                    ])
                }
              } else {
                let date = j.getRegInterval().split('-')

                if (this.sortType === 'day') {
                  this.chartOptions.series[index].data.push([
                    Date.UTC(date[0], date[1] - 1, date[2]),
                    j.getRegCount(),
                  ])
                }

                if (this.sortType === 'month') {
                    this.chartOptions.series[index].data.push([
                        Date.UTC(date[0], date[1] - 1),
                        j.getRegCount(),
                    ])
                }

                if (this.sortType === 'year') {
                    this.chartOptions.series[index].data.push([
                        Date.UTC(date[0]),
                        j.getRegCount(),
                    ])
                }
              }
            }

            index++
          }
        }
      },
    },

    // sortType(newValue) {
    //   console.log('NEW WATCHER', newValue)
    //   store.dispatch('getDynamicList', {
    //     project_ids: [1, 2, 3, 4, 5, 6],
    //     type_interval: newValue,
    //     date_begin: '2012-01-01',
    //     date_end: '2024-01-01'
    //   })
    // }
  },

  created() {
    store.dispatch('getDynamicList', {
      project_ids: [],
      type_interval: this.sortType,
      date_begin: this.dateMin,
      date_end: this.dateMax,
      activity: this.metricValues['Активность'],
      loyalty: this.metricValues['Лояльность'],
      useful: this.metricValues['Полезность'],
      interest: this.metricValues['Заинтересованность'],
    })
  },
}
</script>

<style scoped></style>
