/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: class.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as class_pb from './class_pb';


export class ClassServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateClass = new grpcWeb.MethodDescriptor(
    '/proto.ClassService/CreateClass',
    grpcWeb.MethodType.UNARY,
    class_pb.CreateClassRequest,
    class_pb.CreateClassResponse,
    (request: class_pb.CreateClassRequest) => {
      return request.serializeBinary();
    },
    class_pb.CreateClassResponse.deserializeBinary
  );

  createClass(
    request: class_pb.CreateClassRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_pb.CreateClassResponse>;

  createClass(
    request: class_pb.CreateClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_pb.CreateClassResponse) => void): grpcWeb.ClientReadableStream<class_pb.CreateClassResponse>;

  createClass(
    request: class_pb.CreateClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_pb.CreateClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassService/CreateClass',
        request,
        metadata || {},
        this.methodDescriptorCreateClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassService/CreateClass',
    request,
    metadata || {},
    this.methodDescriptorCreateClass);
  }

  methodDescriptorUpdateClass = new grpcWeb.MethodDescriptor(
    '/proto.ClassService/UpdateClass',
    grpcWeb.MethodType.UNARY,
    class_pb.UpdateClassRequest,
    class_pb.UpdateClassResponse,
    (request: class_pb.UpdateClassRequest) => {
      return request.serializeBinary();
    },
    class_pb.UpdateClassResponse.deserializeBinary
  );

  updateClass(
    request: class_pb.UpdateClassRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_pb.UpdateClassResponse>;

  updateClass(
    request: class_pb.UpdateClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_pb.UpdateClassResponse) => void): grpcWeb.ClientReadableStream<class_pb.UpdateClassResponse>;

  updateClass(
    request: class_pb.UpdateClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_pb.UpdateClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassService/UpdateClass',
        request,
        metadata || {},
        this.methodDescriptorUpdateClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassService/UpdateClass',
    request,
    metadata || {},
    this.methodDescriptorUpdateClass);
  }

  methodDescriptorDeleteClass = new grpcWeb.MethodDescriptor(
    '/proto.ClassService/DeleteClass',
    grpcWeb.MethodType.UNARY,
    class_pb.DeleteClassRequest,
    class_pb.DeleteClassResponse,
    (request: class_pb.DeleteClassRequest) => {
      return request.serializeBinary();
    },
    class_pb.DeleteClassResponse.deserializeBinary
  );

  deleteClass(
    request: class_pb.DeleteClassRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_pb.DeleteClassResponse>;

  deleteClass(
    request: class_pb.DeleteClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_pb.DeleteClassResponse) => void): grpcWeb.ClientReadableStream<class_pb.DeleteClassResponse>;

  deleteClass(
    request: class_pb.DeleteClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_pb.DeleteClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassService/DeleteClass',
        request,
        metadata || {},
        this.methodDescriptorDeleteClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassService/DeleteClass',
    request,
    metadata || {},
    this.methodDescriptorDeleteClass);
  }

  methodDescriptorListClass = new grpcWeb.MethodDescriptor(
    '/proto.ClassService/ListClass',
    grpcWeb.MethodType.UNARY,
    class_pb.ListClassRequest,
    class_pb.ListClassResponse,
    (request: class_pb.ListClassRequest) => {
      return request.serializeBinary();
    },
    class_pb.ListClassResponse.deserializeBinary
  );

  listClass(
    request: class_pb.ListClassRequest,
    metadata: grpcWeb.Metadata | null): Promise<class_pb.ListClassResponse>;

  listClass(
    request: class_pb.ListClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: class_pb.ListClassResponse) => void): grpcWeb.ClientReadableStream<class_pb.ListClassResponse>;

  listClass(
    request: class_pb.ListClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: class_pb.ListClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassService/ListClass',
        request,
        metadata || {},
        this.methodDescriptorListClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassService/ListClass',
    request,
    metadata || {},
    this.methodDescriptorListClass);
  }

}

