
import store from '@/store'
import { defineComponent } from 'vue'
import UserInfo from '@/components/modules/scoring/views/users/UserInfo.vue'
//import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'UserList',

  components: {
    UserInfo,
    //DataLoading
  },

  data() {
    return {
      columns: [
        {
          name: 'action',
          label: 'Действие',
          field: 'guid',
          align: 'center',
          headerStyle:
            'width: 100px; min-width: 100px; max-width: 100px; text-align: center;',
          style: 'width: 40px; min-width: 40px; max-width: 40px;',
        },
        {
          name: 'guid',
          label: 'Пользователь',
          field: 'guid',
          align: 'center',
          headerStyle:
            'width: 300px; min-width: 300px; max-width: 300px; text-align: center;',
          style: 'width: 280px; min-width: 280px; max-width: 280px;',
        },
        {
          name: 'points',
          label: 'Баллы',
          field: 'points',
          align: 'center',
          headerStyle:
            'width: 100px; min-width: 100px; max-width: 100px; text-align: center;',
          style: 'width: 100px; min-width: 100px; max-width: 100px;',
        },
      ],
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      isActiveModal: false,
      isActiveModalInfo: false,
      selectedUserGuid: null as any,
    }
  },

  computed: {
    isUsersBusy() {
      const usersBusy =
        store.getters.isScorecardBusy ||
        store.getters.isScorecardValueAccountBusy
      return usersBusy
    },

    getUserItems() {
      const userItems = store.getters.getScorecardValueAccountMappedItems
      return userItems ? userItems : []
    },

    getColumns() {
      let cols: any = []
      console.log('getColumns')
      for (let colItem of this.columns) {
        console.log('colItem', colItem)
        cols.push({
          name: colItem.name,
          label: colItem.label,
          field: colItem.field,
          align: colItem.align,
          headerStyle: colItem.headerStyle,
          style: colItem.style,
        })
      }

      const headerItems = store.getters.getScorecardItems
      if (headerItems !== undefined) {
        for (let headerItem of headerItems) {
          const title = headerItem.getScorecardTitle()
          const id = headerItem.getScorecardId()
          cols.push({
            name: 'field_' + id.toString(),
            label: title,
            field: 'field_' + id.toString(),
            align: 'center',
            headerStyle:
              'width: 200px; min-width: 200px; max-width: 300px; text-align: center;',
            style: 'width: 200px; min-width: 200px; max-width: 300px;',
          })
        }
      }

      return cols
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalInfo = false
      this.selectedUserGuid = null
    },

    userInfo(guid: any) {
      this.selectedUserGuid = guid
      this.isActiveModalInfo = true
      console.log('userInfo guid', guid)
      this.isActiveModal = true
    },

    actionRow(method: any, guid: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('guid', guid)

      switch (method) {
        case 'info':
          this.userInfo(guid)
          break
        case 'calculate':
          this.calculateUserPoints(guid)
          break
        default:
          console.log('Неизвестный метод')
      }
    },

    calculateUserPoints(guid: any) {
      console.log('calculateUserPoints guid', guid)
      store
        .dispatch('calculateScorecardValueAccount', {
          filter: [
            {
              field_name: 'sva.account_guid',
              operand: '=',
              value: guid,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('calculateUserPoints resolve', resolve)
            this.loadUserItems()
          },
          (reject) => {
            console.log('calculateUserPoints reject', reject)
          }
        )
    },

    calculateAllUsersPoints() {
      console.log('calculateAllUsersPoints')
      store.dispatch('calculateScorecardValueAccount').then(
        (resolve) => {
          console.log('calculateUserPoints resolve', resolve)
          this.loadUserItems()
        },
        (reject) => {
          console.log('calculateUserPoints reject', reject)
        }
      )
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          this.loadUserItems()
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        this.loadUserItems()
      }
    },

    getFilters() {
      if (this.search.length) {
        return [
          {
            field_name: 'account_guid',
            operand: 'ilike',
            value: this.search,
          },
        ]
      } else {
        return []
      }
    },

    loadUserItems() {
      const headerIds = store.getters.getScorecardIds
      store
        .dispatch('getScorecardValueAccountMappedItems', {
          filter: this.getFilters(),
          header: headerIds,
        })
        .then(
          (resolve) => {
            console.log('getScorecardValueAccountMappedItems resolve', resolve)
          },
          (reject) => {
            console.log('getScorecardValueAccountMappedItems reject', reject)
          }
        )
    },

    loadHeaderItems() {
      store
        .dispatch('getScorecardItems', {
          filter: [
            {
              field_name: 'scorecard_parent_id',
              operand: '<>',
              value: 0,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('getScorecardItems resolve', resolve)
            this.loadUserItems()
          },
          (reject) => {
            console.log('getScorecardItems reject', reject)
          }
        )
    },

    loadData() {
      this.hideModal()
      this.initSearch()
      this.loadHeaderItems()
    },
  },
})
