// source: answer_grp.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.proto.AnswerGrp', null, global);
goog.exportSymbol('proto.proto.AnswerGrpFilter', null, global);
goog.exportSymbol('proto.proto.AnswerGrpFilterItem', null, global);
goog.exportSymbol('proto.proto.AnswerGrpPagination', null, global);
goog.exportSymbol('proto.proto.AnswerGrpSort', null, global);
goog.exportSymbol('proto.proto.CreateAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.CreateAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.DeleteAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.DeleteAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpChildsRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpChildsResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpWithFindAnswerRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpWithFindAnswerResponse', null, global);
goog.exportSymbol('proto.proto.ReduceAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.ReduceAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.SeparateAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.SeparateAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.SplitAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.SplitAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.UpdateAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.UpdateAnswerGrpResponse', null, global);
goog.exportSymbol('proto.proto.UpdateTextAnswerGrpRequest', null, global);
goog.exportSymbol('proto.proto.UpdateTextAnswerGrpResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrp.displayName = 'proto.proto.AnswerGrp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpFilterItem.displayName = 'proto.proto.AnswerGrpFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AnswerGrpFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AnswerGrpFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpFilter.displayName = 'proto.proto.AnswerGrpFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpSort.displayName = 'proto.proto.AnswerGrpSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpPagination.displayName = 'proto.proto.AnswerGrpPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpRequest.displayName = 'proto.proto.ListAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpResponse.displayName = 'proto.proto.ListAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpWithFindAnswerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpWithFindAnswerRequest.displayName = 'proto.proto.ListAnswerGrpWithFindAnswerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpWithFindAnswerResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpWithFindAnswerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpWithFindAnswerResponse.displayName = 'proto.proto.ListAnswerGrpWithFindAnswerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpChildsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpChildsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpChildsRequest.displayName = 'proto.proto.ListAnswerGrpChildsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpChildsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpChildsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpChildsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpChildsResponse.displayName = 'proto.proto.ListAnswerGrpChildsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateAnswerGrpRequest.displayName = 'proto.proto.CreateAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateAnswerGrpResponse.displayName = 'proto.proto.CreateAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateAnswerGrpRequest.displayName = 'proto.proto.UpdateAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateAnswerGrpResponse.displayName = 'proto.proto.UpdateAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateTextAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateTextAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateTextAnswerGrpRequest.displayName = 'proto.proto.UpdateTextAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateTextAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateTextAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateTextAnswerGrpResponse.displayName = 'proto.proto.UpdateTextAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteAnswerGrpRequest.displayName = 'proto.proto.DeleteAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteAnswerGrpResponse.displayName = 'proto.proto.DeleteAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReduceAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ReduceAnswerGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ReduceAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReduceAnswerGrpRequest.displayName = 'proto.proto.ReduceAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReduceAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReduceAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReduceAnswerGrpResponse.displayName = 'proto.proto.ReduceAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SplitAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SplitAnswerGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SplitAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SplitAnswerGrpRequest.displayName = 'proto.proto.SplitAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SplitAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SplitAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SplitAnswerGrpResponse.displayName = 'proto.proto.SplitAnswerGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SeparateAnswerGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SeparateAnswerGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SeparateAnswerGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SeparateAnswerGrpRequest.displayName = 'proto.proto.SeparateAnswerGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SeparateAnswerGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SeparateAnswerGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SeparateAnswerGrpResponse.displayName = 'proto.proto.SeparateAnswerGrpResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrp.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrp.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerGrpParentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerGrpCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    answerGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    answerGrpSum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    similarity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    hasChilds: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrp}
 */
proto.proto.AnswerGrp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrp;
  return proto.proto.AnswerGrp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrp}
 */
proto.proto.AnswerGrp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpParentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpCount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnswerGrpProcessed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpSum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSimilarity(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChilds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAnswerGrpProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSimilarity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getHasChilds();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_grp_parent_id = 2;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 answer_id = 3;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer_grp_text = 4;
 * @return {string}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 answer_grp_count = 5;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool answer_grp_processed = 6;
 * @return {boolean}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 answer_grp_sum = 7;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double similarity = 8;
 * @return {number}
 */
proto.proto.AnswerGrp.prototype.getSimilarity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setSimilarity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool has_childs = 9;
 * @return {boolean}
 */
proto.proto.AnswerGrp.prototype.getHasChilds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.AnswerGrp} returns this
 */
proto.proto.AnswerGrp.prototype.setHasChilds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AnswerGrpFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpFilterItem}
 */
proto.proto.AnswerGrpFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpFilterItem;
  return proto.proto.AnswerGrpFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpFilterItem}
 */
proto.proto.AnswerGrpFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AnswerGrpFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AnswerGrpFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFilterItem} returns this
 */
proto.proto.AnswerGrpFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AnswerGrpFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFilterItem} returns this
 */
proto.proto.AnswerGrpFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AnswerGrpFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpFilterItem} returns this
 */
proto.proto.AnswerGrpFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AnswerGrpFilter or = 4;
 * @return {?proto.proto.AnswerGrpFilter}
 */
proto.proto.AnswerGrpFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AnswerGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFilter, 4));
};


/**
 * @param {?proto.proto.AnswerGrpFilter|undefined} value
 * @return {!proto.proto.AnswerGrpFilterItem} returns this
*/
proto.proto.AnswerGrpFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerGrpFilterItem} returns this
 */
proto.proto.AnswerGrpFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerGrpFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AnswerGrpFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpFilter}
 */
proto.proto.AnswerGrpFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpFilter;
  return proto.proto.AnswerGrpFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpFilter}
 */
proto.proto.AnswerGrpFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFilterItem;
      reader.readMessage(value,proto.proto.AnswerGrpFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpFilterItem items = 1;
 * @return {!Array<!proto.proto.AnswerGrpFilterItem>}
 */
proto.proto.AnswerGrpFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpFilterItem>} value
 * @return {!proto.proto.AnswerGrpFilter} returns this
*/
proto.proto.AnswerGrpFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpFilterItem}
 */
proto.proto.AnswerGrpFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AnswerGrpFilter} returns this
 */
proto.proto.AnswerGrpFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpSort}
 */
proto.proto.AnswerGrpSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpSort;
  return proto.proto.AnswerGrpSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpSort}
 */
proto.proto.AnswerGrpSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpSort} returns this
 */
proto.proto.AnswerGrpSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.AnswerGrpSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpSort} returns this
 */
proto.proto.AnswerGrpSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpPagination}
 */
proto.proto.AnswerGrpPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpPagination;
  return proto.proto.AnswerGrpPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpPagination}
 */
proto.proto.AnswerGrpPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.AnswerGrpPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpPagination} returns this
 */
proto.proto.AnswerGrpPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.AnswerGrpPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpPagination} returns this
 */
proto.proto.AnswerGrpPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.AnswerGrpPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpPagination} returns this
 */
proto.proto.AnswerGrpPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.AnswerGrpPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpPagination} returns this
 */
proto.proto.AnswerGrpPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerGrpPagination.toObject(includeInstance, f),
    questionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isWorked: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpRequest}
 */
proto.proto.ListAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpRequest;
  return proto.proto.ListAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpRequest}
 */
proto.proto.ListAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpSort;
      reader.readMessage(value,proto.proto.AnswerGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerGrpPagination;
      reader.readMessage(value,proto.proto.AnswerGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWorked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getIsWorked();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional AnswerGrpFilter filter = 1;
 * @return {?proto.proto.AnswerGrpFilter}
 */
proto.proto.ListAnswerGrpRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
*/
proto.proto.ListAnswerGrpRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
 */
proto.proto.ListAnswerGrpRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpSort sort = 2;
 * @return {?proto.proto.AnswerGrpSort}
 */
proto.proto.ListAnswerGrpRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
*/
proto.proto.ListAnswerGrpRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
 */
proto.proto.ListAnswerGrpRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerGrpPagination pagination = 3;
 * @return {?proto.proto.AnswerGrpPagination}
 */
proto.proto.ListAnswerGrpRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpPagination, 3));
};


/**
 * @param {?proto.proto.AnswerGrpPagination|undefined} value
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
*/
proto.proto.ListAnswerGrpRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
 */
proto.proto.ListAnswerGrpRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 question_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerGrpRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
 */
proto.proto.ListAnswerGrpRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_worked = 5;
 * @return {boolean}
 */
proto.proto.ListAnswerGrpRequest.prototype.getIsWorked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListAnswerGrpRequest} returns this
 */
proto.proto.ListAnswerGrpRequest.prototype.setIsWorked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpResponse}
 */
proto.proto.ListAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpResponse;
  return proto.proto.ListAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpResponse}
 */
proto.proto.ListAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrp;
      reader.readMessage(value,proto.proto.AnswerGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrp items = 1;
 * @return {!Array<!proto.proto.AnswerGrp>}
 */
proto.proto.ListAnswerGrpResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrp, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrp>} value
 * @return {!proto.proto.ListAnswerGrpResponse} returns this
*/
proto.proto.ListAnswerGrpResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrp}
 */
proto.proto.ListAnswerGrpResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpResponse} returns this
 */
proto.proto.ListAnswerGrpResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpRequest}
 */
proto.proto.ListAnswerGrpResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpResponse} returns this
*/
proto.proto.ListAnswerGrpResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpResponse} returns this
 */
proto.proto.ListAnswerGrpResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpWithFindAnswerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerGrpPagination.toObject(includeInstance, f),
    questionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpWithFindAnswerRequest;
  return proto.proto.ListAnswerGrpWithFindAnswerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpSort;
      reader.readMessage(value,proto.proto.AnswerGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerGrpPagination;
      reader.readMessage(value,proto.proto.AnswerGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpWithFindAnswerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional AnswerGrpFilter filter = 1;
 * @return {?proto.proto.AnswerGrpFilter}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
*/
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpSort sort = 2;
 * @return {?proto.proto.AnswerGrpSort}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
*/
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerGrpPagination pagination = 3;
 * @return {?proto.proto.AnswerGrpPagination}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpPagination, 3));
};


/**
 * @param {?proto.proto.AnswerGrpPagination|undefined} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
*/
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 question_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 answer_grp_id = 5;
 * @return {number}
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerRequest} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpWithFindAnswerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpWithFindAnswerRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpWithFindAnswerResponse;
  return proto.proto.ListAnswerGrpWithFindAnswerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrp;
      reader.readMessage(value,proto.proto.AnswerGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpWithFindAnswerRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpWithFindAnswerRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpWithFindAnswerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpWithFindAnswerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpWithFindAnswerRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrp items = 1;
 * @return {!Array<!proto.proto.AnswerGrp>}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrp, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrp>} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse} returns this
*/
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrp}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpWithFindAnswerRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpWithFindAnswerRequest}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpWithFindAnswerRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpWithFindAnswerRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpWithFindAnswerRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse} returns this
*/
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpWithFindAnswerResponse} returns this
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpWithFindAnswerResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpChildsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpChildsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpChildsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerGrpPagination.toObject(includeInstance, f),
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpChildsRequest}
 */
proto.proto.ListAnswerGrpChildsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpChildsRequest;
  return proto.proto.ListAnswerGrpChildsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpChildsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpChildsRequest}
 */
proto.proto.ListAnswerGrpChildsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpFilter;
      reader.readMessage(value,proto.proto.AnswerGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpSort;
      reader.readMessage(value,proto.proto.AnswerGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerGrpPagination;
      reader.readMessage(value,proto.proto.AnswerGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpChildsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpChildsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpChildsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional AnswerGrpFilter filter = 1;
 * @return {?proto.proto.AnswerGrpFilter}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
*/
proto.proto.ListAnswerGrpChildsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpSort sort = 2;
 * @return {?proto.proto.AnswerGrpSort}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
*/
proto.proto.ListAnswerGrpChildsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerGrpPagination pagination = 3;
 * @return {?proto.proto.AnswerGrpPagination}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpPagination, 3));
};


/**
 * @param {?proto.proto.AnswerGrpPagination|undefined} value
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
*/
proto.proto.ListAnswerGrpChildsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 answer_grp_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpChildsRequest} returns this
 */
proto.proto.ListAnswerGrpChildsRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpChildsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpChildsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpChildsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpChildsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpChildsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpChildsResponse}
 */
proto.proto.ListAnswerGrpChildsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpChildsResponse;
  return proto.proto.ListAnswerGrpChildsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpChildsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpChildsResponse}
 */
proto.proto.ListAnswerGrpChildsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrp;
      reader.readMessage(value,proto.proto.AnswerGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpChildsRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpChildsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpChildsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpChildsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpChildsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpChildsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrp items = 1;
 * @return {!Array<!proto.proto.AnswerGrp>}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrp, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrp>} value
 * @return {!proto.proto.ListAnswerGrpChildsResponse} returns this
*/
proto.proto.ListAnswerGrpChildsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrp}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpChildsResponse} returns this
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpChildsRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpChildsRequest}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpChildsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpChildsRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpChildsRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpChildsResponse} returns this
*/
proto.proto.ListAnswerGrpChildsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpChildsResponse} returns this
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpChildsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpParentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerGrpCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    answerGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateAnswerGrpRequest}
 */
proto.proto.CreateAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateAnswerGrpRequest;
  return proto.proto.CreateAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateAnswerGrpRequest}
 */
proto.proto.CreateAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpParentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnswerGrpProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAnswerGrpProcessed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 answer_grp_parent_id = 1;
 * @return {number}
 */
proto.proto.CreateAnswerGrpRequest.prototype.getAnswerGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateAnswerGrpRequest} returns this
 */
proto.proto.CreateAnswerGrpRequest.prototype.setAnswerGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_id = 2;
 * @return {number}
 */
proto.proto.CreateAnswerGrpRequest.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateAnswerGrpRequest} returns this
 */
proto.proto.CreateAnswerGrpRequest.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string answer_grp_text = 3;
 * @return {string}
 */
proto.proto.CreateAnswerGrpRequest.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateAnswerGrpRequest} returns this
 */
proto.proto.CreateAnswerGrpRequest.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 answer_grp_count = 4;
 * @return {number}
 */
proto.proto.CreateAnswerGrpRequest.prototype.getAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateAnswerGrpRequest} returns this
 */
proto.proto.CreateAnswerGrpRequest.prototype.setAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool answer_grp_processed = 5;
 * @return {boolean}
 */
proto.proto.CreateAnswerGrpRequest.prototype.getAnswerGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CreateAnswerGrpRequest} returns this
 */
proto.proto.CreateAnswerGrpRequest.prototype.setAnswerGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateAnswerGrpResponse}
 */
proto.proto.CreateAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateAnswerGrpResponse;
  return proto.proto.CreateAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateAnswerGrpResponse}
 */
proto.proto.CreateAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.CreateAnswerGrpResponse.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateAnswerGrpResponse} returns this
 */
proto.proto.CreateAnswerGrpResponse.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerGrpParentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerGrpCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    answerGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateAnswerGrpRequest}
 */
proto.proto.UpdateAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateAnswerGrpRequest;
  return proto.proto.UpdateAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateAnswerGrpRequest}
 */
proto.proto.UpdateAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpParentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpCount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnswerGrpProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAnswerGrpProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_grp_parent_id = 2;
 * @return {number}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 answer_id = 3;
 * @return {number}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer_grp_text = 4;
 * @return {string}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 answer_grp_count = 5;
 * @return {number}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool answer_grp_processed = 6;
 * @return {boolean}
 */
proto.proto.UpdateAnswerGrpRequest.prototype.getAnswerGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateAnswerGrpRequest} returns this
 */
proto.proto.UpdateAnswerGrpRequest.prototype.setAnswerGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateAnswerGrpResponse}
 */
proto.proto.UpdateAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateAnswerGrpResponse;
  return proto.proto.UpdateAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateAnswerGrpResponse}
 */
proto.proto.UpdateAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.UpdateAnswerGrpResponse.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateAnswerGrpResponse} returns this
 */
proto.proto.UpdateAnswerGrpResponse.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateTextAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateTextAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateTextAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateTextAnswerGrpRequest}
 */
proto.proto.UpdateTextAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateTextAnswerGrpRequest;
  return proto.proto.UpdateTextAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateTextAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateTextAnswerGrpRequest}
 */
proto.proto.UpdateTextAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateTextAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateTextAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateTextAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateTextAnswerGrpRequest} returns this
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string answer_grp_text = 4;
 * @return {string}
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateTextAnswerGrpRequest} returns this
 */
proto.proto.UpdateTextAnswerGrpRequest.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateTextAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateTextAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateTextAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateTextAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateTextAnswerGrpResponse}
 */
proto.proto.UpdateTextAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateTextAnswerGrpResponse;
  return proto.proto.UpdateTextAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateTextAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateTextAnswerGrpResponse}
 */
proto.proto.UpdateTextAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateTextAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateTextAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateTextAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateTextAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.UpdateTextAnswerGrpResponse.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateTextAnswerGrpResponse} returns this
 */
proto.proto.UpdateTextAnswerGrpResponse.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteAnswerGrpRequest}
 */
proto.proto.DeleteAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteAnswerGrpRequest;
  return proto.proto.DeleteAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteAnswerGrpRequest}
 */
proto.proto.DeleteAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.DeleteAnswerGrpRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteAnswerGrpRequest} returns this
 */
proto.proto.DeleteAnswerGrpRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteAnswerGrpResponse}
 */
proto.proto.DeleteAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteAnswerGrpResponse;
  return proto.proto.DeleteAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteAnswerGrpResponse}
 */
proto.proto.DeleteAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.DeleteAnswerGrpResponse.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteAnswerGrpResponse} returns this
 */
proto.proto.DeleteAnswerGrpResponse.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ReduceAnswerGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReduceAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReduceAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReduceAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReduceAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reducedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReduceAnswerGrpRequest}
 */
proto.proto.ReduceAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReduceAnswerGrpRequest;
  return proto.proto.ReduceAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReduceAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReduceAnswerGrpRequest}
 */
proto.proto.ReduceAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setReducedIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReduceAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReduceAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReduceAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReduceAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReducedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.ReduceAnswerGrpRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReduceAnswerGrpRequest} returns this
 */
proto.proto.ReduceAnswerGrpRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 reduced_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ReduceAnswerGrpRequest.prototype.getReducedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ReduceAnswerGrpRequest} returns this
 */
proto.proto.ReduceAnswerGrpRequest.prototype.setReducedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ReduceAnswerGrpRequest} returns this
 */
proto.proto.ReduceAnswerGrpRequest.prototype.addReducedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ReduceAnswerGrpRequest} returns this
 */
proto.proto.ReduceAnswerGrpRequest.prototype.clearReducedIdsList = function() {
  return this.setReducedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReduceAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReduceAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReduceAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReduceAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReduceAnswerGrpResponse}
 */
proto.proto.ReduceAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReduceAnswerGrpResponse;
  return proto.proto.ReduceAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReduceAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReduceAnswerGrpResponse}
 */
proto.proto.ReduceAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReduceAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReduceAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReduceAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReduceAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.ReduceAnswerGrpResponse.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReduceAnswerGrpResponse} returns this
 */
proto.proto.ReduceAnswerGrpResponse.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SplitAnswerGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SplitAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SplitAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SplitAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SplitAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    splitString: jspb.Message.getFieldWithDefault(msg, 1, ""),
    splittedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SplitAnswerGrpRequest}
 */
proto.proto.SplitAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SplitAnswerGrpRequest;
  return proto.proto.SplitAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SplitAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SplitAnswerGrpRequest}
 */
proto.proto.SplitAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitString(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSplittedIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SplitAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SplitAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SplitAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SplitAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSplittedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional string split_string = 1;
 * @return {string}
 */
proto.proto.SplitAnswerGrpRequest.prototype.getSplitString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SplitAnswerGrpRequest} returns this
 */
proto.proto.SplitAnswerGrpRequest.prototype.setSplitString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 splitted_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.SplitAnswerGrpRequest.prototype.getSplittedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.SplitAnswerGrpRequest} returns this
 */
proto.proto.SplitAnswerGrpRequest.prototype.setSplittedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.SplitAnswerGrpRequest} returns this
 */
proto.proto.SplitAnswerGrpRequest.prototype.addSplittedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SplitAnswerGrpRequest} returns this
 */
proto.proto.SplitAnswerGrpRequest.prototype.clearSplittedIdsList = function() {
  return this.setSplittedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SplitAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SplitAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SplitAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SplitAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SplitAnswerGrpResponse}
 */
proto.proto.SplitAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SplitAnswerGrpResponse;
  return proto.proto.SplitAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SplitAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SplitAnswerGrpResponse}
 */
proto.proto.SplitAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SplitAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SplitAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SplitAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SplitAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.SplitAnswerGrpResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SplitAnswerGrpResponse} returns this
 */
proto.proto.SplitAnswerGrpResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SeparateAnswerGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SeparateAnswerGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SeparateAnswerGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SeparateAnswerGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SeparateAnswerGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    separatedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SeparateAnswerGrpRequest}
 */
proto.proto.SeparateAnswerGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SeparateAnswerGrpRequest;
  return proto.proto.SeparateAnswerGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SeparateAnswerGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SeparateAnswerGrpRequest}
 */
proto.proto.SeparateAnswerGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSeparatedIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SeparateAnswerGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SeparateAnswerGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SeparateAnswerGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SeparateAnswerGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeparatedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 separated_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.SeparateAnswerGrpRequest.prototype.getSeparatedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.SeparateAnswerGrpRequest} returns this
 */
proto.proto.SeparateAnswerGrpRequest.prototype.setSeparatedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.SeparateAnswerGrpRequest} returns this
 */
proto.proto.SeparateAnswerGrpRequest.prototype.addSeparatedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SeparateAnswerGrpRequest} returns this
 */
proto.proto.SeparateAnswerGrpRequest.prototype.clearSeparatedIdsList = function() {
  return this.setSeparatedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SeparateAnswerGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SeparateAnswerGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SeparateAnswerGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SeparateAnswerGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SeparateAnswerGrpResponse}
 */
proto.proto.SeparateAnswerGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SeparateAnswerGrpResponse;
  return proto.proto.SeparateAnswerGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SeparateAnswerGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SeparateAnswerGrpResponse}
 */
proto.proto.SeparateAnswerGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SeparateAnswerGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SeparateAnswerGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SeparateAnswerGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SeparateAnswerGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.SeparateAnswerGrpResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SeparateAnswerGrpResponse} returns this
 */
proto.proto.SeparateAnswerGrpResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
