import script from "./DBScan.vue?vue&type=script&setup=true&lang=ts"
export * from "./DBScan.vue?vue&type=script&setup=true&lang=ts"

import "./DBScan.vue?vue&type=style&index=0&id=fc7c3828&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QInnerLoading,QDialog});
