
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'
import SplitFormSingle from './SplitFormSingle.vue'

export default defineComponent({
  name: 'AddQuestionPanel',

  components: {
    SplitFormSingle,
    DataLoading,
  },

  props: {
    pollId: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      isActiveModal: false,
      isActiveModalSplitSingle: false,
      isActiveModalSource: false,
      currentActionAnswer: null as any,
      columnsWorked: [
        {
          name: 'question_stitle',
          field: 'question_stitle',
          label: 'Наименование вопроса',
          align: 'left',
        },
        {
          name: 'answer_src_sum',
          field: 'answer_src_sum',
          label: 'Количество ответов',
          align: 'center',
        },
      ],
      similarColumn: {
        name: 'similarity',
        field: 'similarity',
        label: 'Коэффициент схожести',
        align: 'center',
      },
      workedFilter: [] as any,
      workedPagination: {
        sortBy: 'answer_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageWorkedOptions: [10, 25, 50, 100],
      findType: 'similar',
      findTypeOptions: [
        { label: 'Семантика', value: 'similar', disable: false },
        { label: 'Простой поиск', value: 'single' },
      ],
      markedFind: [] as string[],
      selectedWorked: [],
      search: '',
      similarity: 0.6,
      typeOptions:[
        { id: 0, title: 'Поиск по семантике', value: 'similar', disable: true },
        { id: 1, title: 'Простой поиск', value: 'single' },
      ],
      typeSelect: null as any,
    }
  },

  computed: {
    isQuestionForSearchBusy() {
      return store.getters.isQuestionForSearchBusy
    },

    getQuestionId() {
      let currentQuestion = store.getters.getQuestionItem
      return currentQuestion ? currentQuestion.id : 0
    },

    isCurrentQuestion() {
      return null !== store.getters.getCurrentQuestion
    },

    getCurrentAnswerResult() {
      let curAnswerResult = store.getters.getCurrentQuestion
      return curAnswerResult
          ? curAnswerResult
          : {
            question_id: 0,
            question_parent_id: 0,
            poll_id: 0,
            question_type_id: 0,
            question_idx: 0,
            question_title: '',
            question_stitle: '',
            question_processed: false,
            answer_src_count: 0,
            answer_src_sum: 0,
            similarity: '',
            has_childs: false,
          }
    },

    isAnswerGrpWorkedBusy() {
      return store.getters.isAnswerGrpWorkedBusy
    },

    getAnswerGrpWorkedItems() {
      const answerItems = store.getters.getQuestionForSearchItems
      let rows: any = []
      if (answerItems !== undefined) {
        for (let i of answerItems) {
          rows.push({
            question_id: i.getQuestionId(),
            question_parent_id: i.getQuestionParentId(),
            poll_id: i.getPollId(),
            question_type_id: i.getQuestionTypeId(),
            question_idx: i.getQuestionIdx(),
            question_title: i.getQuestionTitle(),
            question_stitle: i.getQuestionStitle(),
            question_processed: i.getQuestionProcessed(),
            answer_src_count: i.getAnswerSrcCount(),
            answer_src_sum: i.getAnswerSrcSum(),
            similarity: i.getSimilarity(),
            has_childs: i.getHasChilds(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  created() {
    this.loadData(false, true)
  },

  methods: {
    onRowShowAnswerSource(row: any, isFull: boolean) {
      this.$emit('showSource', row, isFull)
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalSplitSingle = false
      this.currentActionAnswer = null
    },

    splitSingle() {
      this.isActiveModalSplitSingle = true
      console.log('splitSingle')
      this.isActiveModal = true
    },

    actionRow(method: any, row: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('row', row)
      this.currentActionAnswer = Object.assign(row)
      switch (method) {
        case 'split':
          this.splitSingle()
          break
        default:
          console.log('Неизвестный метод')
          console.log('method', method)
      }
    },

    afterActionSplitSingle() {
      this.$emit('afterAction', this.getCurrentAnswerResult.question_id)
      this.hideModal()
    },

    makeActionWorked() {
      let reducedIds = this.selectedWorked.map((item: any) => item.question_id)

      store.dispatch('reduceQuestion', {
        question_id: this.getCurrentAnswerResult.question_id,
        reduced_ids_list: reducedIds
      })
          .then(
              (resolve) => {
                console.log('makeActionWorked resolve', resolve)
                this.$emit('afterAction', resolve.getQuestionId())
              },
              (reject) => {
                console.log('makeActionWorked reject', reject)
              }
          )
      return true
    },

    onUpdateSelectedWorked(event: any) {
      console.log('onUpdateSelectedWorked event', event)
      /*
      if (!this.isCurrentAnswerResult && ('single' == this.findType)) {
          if (event.length) {
              if (event[0].text != this.reduceAnswerText) {
                  this.reduceAnswerText = event[0].text
              }
          } else {
              this.reduceAnswerText = ''
          }
      }
      */
    },

    changeFindType(event: any) {
      console.log('changeFindType event', event.value)
      switch (event.value) {
        case 'single':
          if (this.columnsWorked.length == 3) {
            this.columnsWorked.splice(2, 1)
          }
          break
        case 'similar':
          if (this.columnsWorked.length == 2) {
            this.columnsWorked.splice(2, 0, this.similarColumn)
          }
          break
      }
      this.findAnswers()
    },

    onRequestWorked(props: any) {
      console.log('onRequestWorked props', props)
      this.workedPagination = props.pagination
      this.loadWorkedAnswers(false)
    },

    findAnswers() {
      this.markedFind = []

      if (this.typeSelect.value === 'single') {
        this.markedFind.push(this.search)
      }

      this.markedFind = []

      this.loadWorkedAnswers(true) // true - переключаем на первую страницу

      return true
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка непривязанных ответов
    loadWorkedAnswers(isResetPagination: boolean) {
      if (isResetPagination) {
        this.workedPagination.page = 1
        this.workedPagination.rowsNumber = 0
      }
      switch (this.typeSelect.value) {
        case 'single': {
          this.workedPagination.sortBy = 'answer_src_sum'
          this.workedPagination.descending = true
          break
        }
        case 'similar': {
          this.workedPagination.sortBy = 'similarity'
          this.workedPagination.descending = true
          break
        }
        default: {
          console.log(
              'loadWorkedAnswers - неизвестный тип поиска this.findType',
              this.typeSelect.value
          )
        }
      }
      store
          .dispatch('getQuestionItemsForSearch', {
            filter: this.getFilters(),
            sort: {
              name: this.workedPagination.sortBy,
              exp: this.workedPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.workedPagination.page,
              limit: this.workedPagination.rowsPerPage,
              pages: 0,
              cnt: 0,
            },
            poll_id: this.pollId,
            is_worked: true,
          })
          .then(
              (resolve) => {
                console.log('getAnswerGrpWorkedItems resolve', resolve)
                this.workedPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getAnswerGrpWorkedItems reject', reject)
              }
          )
    },

    loadData(isLoadAnswers: boolean, isResetSimilarity: boolean) {
      this.typeSelect = this.typeOptions[0]
      let findType = this.typeSelect.value

      this.selectedWorked = []
      this.markedFind = []

      if (isResetSimilarity) {
        this.search = ''
        findType = 'similar'
        this.similarity = 0.6
      }

      this.typeOptions[0].disable = !this.isCurrentQuestion

      if (('similar' == findType) && !this.isCurrentQuestion) {
        findType = 'single'
      }

      if (findType === 'single') {
        if (this.columnsWorked.length == 3) {
          this.columnsWorked.splice(2, 1)
        }
      } else {
        if (this.columnsWorked.length == 2) {
          this.columnsWorked.splice(2, 0, this.similarColumn)
        }
      }

      this.typeSelect.value = findType

      if (isLoadAnswers) {
        this.markedFind = []

        if (this.typeSelect.value === 'single') {
          this.markedFind.push(this.search)
        }

        this.loadWorkedAnswers(true)
      }
    },

    getFilters() {
      const filters = [
        {
          field_name: 'q.question_id',
          operand: '<>',
          value: String(this.getCurrentAnswerResult.question_id),
        },
      ]

      if (this.search.length && this.typeSelect.value === 'single') {
        filters.push({
          field_name: 'q.question_stitle',
          operand: 'ilike',
          value: this.search,
        })
      }

      if (this.similarity && this.typeSelect.value === 'similar') {
        console.log('dab')
        filters.push({
          field_name: 'q.question_stitle',
          operand: 'similar_' + this.similarity.toFixed(2),
          value: this.getCurrentAnswerResult.question_stitle,
        })
      }

      return filters
    }
  },
})
