
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ValueDelete',

  props: {
    valueItem: {
      type: Object as any,
    },
  },

  data() {
    return {
      item: {
        index: 0,
        id: 0,
        title: '',
        idx: 1,
        points: 0,
        check_rule: '',
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    actualDelete() {
      this.$emit('actualDelete', this.item.index)
    },

    closeValueDelete() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        index: this.valueItem.index,
        id: this.valueItem.id,
        idx: this.valueItem.idx,
        title: this.valueItem.title,
        points: this.valueItem.points,
        check_rule: this.valueItem.check_rule,
      }
    },
  },
})
