import { EventServiceClient } from "../../proto/pb/EventsServiceClientPb";
import {
  Event,
  EventSort,
  ListEventRequest,
  EventPagination,
  LastEventRequest,
  ListEventSourceRequest,
} from "../../proto/pb/events_pb";
import {
  Filter,
  FilterItem,
  Sort,
  Pagination
} from "../../proto/pb/filter_pb"
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000;

export class EventsApi {
  Actions: Array<Event>;
  client: EventServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new EventServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
    this.metadata = {
      Authorization: localStorage.getItem("access-key"),
    };
  }

  createNewFilter(data: any) {
    const filter = new Filter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new FilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createNewFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }

        //TODO: Will be removed after global refactor. currently was made when using Type.asObject for request
        // current function was made by hand and filters doesn't have "field_name" property instead it should've been
        // "fieldName" but proper type was not set and here we have a collapse of proper usage and made by hand "type"
        if (data[i].fieldName) {
          const fItem = new FilterItem();
          fItem
            .setFieldName(data[i].fieldName)
            .setOperand(data[i].operand || "=")
            .setValue(data[i].value || "");

          if (data[i]?.or?.itemsList?.length) {
            fItem.setOr(this.createNewFilter(data[i].or.itemsList || []));
          } else {
            fItem.setOr(new Filter());
          }

          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  createSort(data: any) {
    const sort = new EventSort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "event_date";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "desc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createPagination(data: any) {
    const pagination = new EventPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);
    pagination.setCnt(cnt);

    return pagination;
  }

  createNewSort(data: any) {
    const sort = new Sort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "normal_name";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createNewPagination(data: any) {
    const pagination = new Pagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);
    pagination.setCnt(cnt);

    return pagination;
  }

  getEventsList(filter: any, pagination: any, sort: any = {}, fields: any) {
    const request = new ListEventRequest();
    console.log("EVENT REQ ", filter, pagination, fields);
    // Устанавливаем параметры запроса
    request
      .setFilter(this.createNewFilter(filter))
      .setPagination(this.createPagination(pagination))
      .setSort(this.createSort(sort))
      .setActionNamesList(fields.action_names)
      .setDateBegin(fields.date_begin)
      .setDateEnd(fields.date_end)
      .setProjectIdsList(fields.project_ids)
      .setSsoId(fields.sso_id)

    return this.client.listEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getLastActivity(id: any) {
    const request = new LastEventRequest();

    const date = new Date().toJSON().slice(0, 10);

    request.setSsoId(id).setDateBegin("2012-01-01").setDateEnd(date);

    return this.client.lastEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getEventsCount(filter: any, pagination: any, sort: any = {}, fields: any) {
    const request = new ListEventRequest();
    // Устанавливаем параметры запроса
    request
      .setFilter(this.createNewFilter(filter))
      .setPagination(this.createPagination(pagination))
      .setSort(this.createSort(sort))
      .setActionNamesList(fields.action_names)
      .setDateBegin(fields.date_begin)
      .setDateEnd(fields.date_end)
      .setProjectIdsList(fields.project_ids)
      .setSsoId(fields.sso_id)

    return this.client.countEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getListEventSource(filter: any, sort: any, pagination: any) {
    const request = new ListEventSourceRequest()

    request
      .setFilter(this.createNewFilter(filter))
      .setSort(this.createNewSort(sort))
      .setPagination(this.createNewPagination(pagination))
    
    return this.client.listEventSource(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any)
  }
}
