/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: anomaly.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as anomaly_pb from './anomaly_pb';


export class DashboardAnomalyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListDashboardAnomalyBlocks = new grpcWeb.MethodDescriptor(
    '/proto.DashboardAnomalyService/ListDashboardAnomalyBlocks',
    grpcWeb.MethodType.UNARY,
    anomaly_pb.ListDashboardAnomalyBlocksRequest,
    anomaly_pb.ListDashboardAnomalyBlocksResponse,
    (request: anomaly_pb.ListDashboardAnomalyBlocksRequest) => {
      return request.serializeBinary();
    },
    anomaly_pb.ListDashboardAnomalyBlocksResponse.deserializeBinary
  );

  listDashboardAnomalyBlocks(
    request: anomaly_pb.ListDashboardAnomalyBlocksRequest,
    metadata: grpcWeb.Metadata | null): Promise<anomaly_pb.ListDashboardAnomalyBlocksResponse>;

  listDashboardAnomalyBlocks(
    request: anomaly_pb.ListDashboardAnomalyBlocksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: anomaly_pb.ListDashboardAnomalyBlocksResponse) => void): grpcWeb.ClientReadableStream<anomaly_pb.ListDashboardAnomalyBlocksResponse>;

  listDashboardAnomalyBlocks(
    request: anomaly_pb.ListDashboardAnomalyBlocksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: anomaly_pb.ListDashboardAnomalyBlocksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardAnomalyService/ListDashboardAnomalyBlocks',
        request,
        metadata || {},
        this.methodDescriptorListDashboardAnomalyBlocks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardAnomalyService/ListDashboardAnomalyBlocks',
    request,
    metadata || {},
    this.methodDescriptorListDashboardAnomalyBlocks);
  }

  methodDescriptorListDashboardAnomalyTypes = new grpcWeb.MethodDescriptor(
    '/proto.DashboardAnomalyService/ListDashboardAnomalyTypes',
    grpcWeb.MethodType.UNARY,
    anomaly_pb.ListDashboardAnomalyTypesRequest,
    anomaly_pb.ListDashboardAnomalyTypesResponse,
    (request: anomaly_pb.ListDashboardAnomalyTypesRequest) => {
      return request.serializeBinary();
    },
    anomaly_pb.ListDashboardAnomalyTypesResponse.deserializeBinary
  );

  listDashboardAnomalyTypes(
    request: anomaly_pb.ListDashboardAnomalyTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<anomaly_pb.ListDashboardAnomalyTypesResponse>;

  listDashboardAnomalyTypes(
    request: anomaly_pb.ListDashboardAnomalyTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: anomaly_pb.ListDashboardAnomalyTypesResponse) => void): grpcWeb.ClientReadableStream<anomaly_pb.ListDashboardAnomalyTypesResponse>;

  listDashboardAnomalyTypes(
    request: anomaly_pb.ListDashboardAnomalyTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: anomaly_pb.ListDashboardAnomalyTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardAnomalyService/ListDashboardAnomalyTypes',
        request,
        metadata || {},
        this.methodDescriptorListDashboardAnomalyTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardAnomalyService/ListDashboardAnomalyTypes',
    request,
    metadata || {},
    this.methodDescriptorListDashboardAnomalyTypes);
  }

}

