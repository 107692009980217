
import store from '@/store'
import { defineComponent } from 'vue'
import ClsAdd from '../cls/ClsAdd.vue'
import ClsEdit from '../cls/ClsEdit.vue'
import ClsDelete from '../cls/ClsDelete.vue'
//import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClsList',

  components: {
    ClsAdd,
    ClsEdit,
    ClsDelete,
    //DataLoading
  },

  data() {
    return {
      columns: [
        {
          name: 'name',
          label: 'Наименование',
          field: 'name',
          align: 'left',
        },
        {
          name: 'desrc',
          label: 'Описание',
          field: 'descr',
          align: 'left',
        },
        {
          name: 'notes',
          label: 'Примечания',
          field: 'notes',
          align: 'left',
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px',
          style: 'width: 40px',
        },
      ] as any,
      classifierPagination: {
        sortBy: 'classifier_name',
        descending: false,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageClassifierOptions: [10, 25, 50, 100],
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedClsId: null as any,
    }
  },

  computed: {
    isClassifierBusy() {
      return store.getters.getIsClassifierBusy
    },

    getClassifierItems() {
      const clsItems = store.getters.getClassifierItems
      let rows: any = []
      if (clsItems !== undefined) {
        for (let clsItem of clsItems) {
          rows.push({
            id: clsItem.getClassifierId(),
            name: clsItem.getClassifierName(),
            descr: clsItem.getClassifierDescr(),
            notes: clsItem.getClassifierNotes(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedClsId = null
    },

    addCls() {
      this.isActiveModalAdd = true
      console.log('addCls')
      this.isActiveModal = true
    },

    editCls() {
      this.isActiveModalEdit = true
      console.log('editCls')
      this.isActiveModal = true
    },

    deleteCls() {
      this.isActiveModalDelete = true
      console.log('deleteCls')
      this.isActiveModal = true
    },

    actionRow(method: any, id: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('id', id)
      this.selectedClsId = id
      switch (method) {
        case 'list':
          this.$router.push('/nsi/classifiers/tree/' + id)
          break
        case 'edit':
          this.editCls()
          break
        case 'delete':
          this.deleteCls()
          break
        default:
          console.log('Неизвестный метод')
          console.log('method', method)
          console.log('id', id)
      }
    },

    afterAction() {
      console.log('afterAction')
      this.loadClassifierItems(true)
      this.hideModal()
    },

    onRequestClassifier(props: any) {
      console.log('onRequestClassifier props', props)
      this.classifierPagination = props.pagination
      this.loadClassifierItems(false)
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
        new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
        "<span style='color: red;'><b>$1</b></span>"
      )
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          this.loadClassifierItems(true)
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        this.loadClassifierItems(true)
      }
    },

    getFilters() {
      return (this.search.length > 0) ?
        [
          {
            field_name: 'classifier_id',
            operand: '>',
            value: 0,
            or: [
              {
                field_name: 'classifier_name',
                operand: 'ilike',
                value: this.search,
              },
              {
                field_name: 'classifier_descr',
                operand: 'ilike',
                value: this.search,
              },
              {
                field_name: 'classifier_notes',
                operand: 'ilike',
                value: this.search,
              },
            ]
          },
        ] :
        []
    },

    loadClassifierItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.classifierPagination.page = 1
        this.classifierPagination.rowsNumber = 0
      }
      store
        .dispatch('getClassifierItems', {
          filter: this.getFilters(),
          sort: {
            name: this.classifierPagination.sortBy,
            exp: this.classifierPagination.descending ? 'DESC' : 'ASC',
          },
          pagination: {
            page: this.classifierPagination.page,
            limit: this.classifierPagination.rowsPerPage,
            pages: 0,
            cnt: this.classifierPagination.rowsNumber,
          },          
        })
        .then(
          (resolve) => {
            console.log('getClassifierItems resolve', resolve)
            this.classifierPagination.rowsNumber = resolve.pagination.getCnt()
          },
          (reject) => {
            console.log('getClassifierItems reject', reject)
          }
        )
    },

    loadData() {
      this.hideModal()
      this.initSearch()
      this.loadClassifierItems(true)
    },
  },
})
