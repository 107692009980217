
import { defineComponent } from "vue";
import MenuElement from "./modules/user-digital-project/components/Design/MenuElement.vue";

export default defineComponent({
  name: "CheckList",

  components: {
    MenuElement,
  },

  data() {
    return {
      addonList: [
        {
          title: "Общие",
          url: "/cls/general",
        },
        {
          title: "Опросы АГ",
          url: "/cls/ag_polls",
        },
        {
          title: "Сообщения ВК",
          url: "/cls/vk_messages",
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },
});
