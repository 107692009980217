/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: address.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as address_pb from './address_pb';


export class AddressServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAddress = new grpcWeb.MethodDescriptor(
    '/proto.AddressService/ListAddress',
    grpcWeb.MethodType.UNARY,
    address_pb.ListAddressRequest,
    address_pb.ListAddressResponse,
    (request: address_pb.ListAddressRequest) => {
      return request.serializeBinary();
    },
    address_pb.ListAddressResponse.deserializeBinary
  );

  listAddress(
    request: address_pb.ListAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<address_pb.ListAddressResponse>;

  listAddress(
    request: address_pb.ListAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: address_pb.ListAddressResponse) => void): grpcWeb.ClientReadableStream<address_pb.ListAddressResponse>;

  listAddress(
    request: address_pb.ListAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: address_pb.ListAddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AddressService/ListAddress',
        request,
        metadata || {},
        this.methodDescriptorListAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AddressService/ListAddress',
    request,
    metadata || {},
    this.methodDescriptorListAddress);
  }

}

