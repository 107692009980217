import script from "./ModalPointsSpentCity.vue?vue&type=script&setup=true&lang=ts"
export * from "./ModalPointsSpentCity.vue?vue&type=script&setup=true&lang=ts"

import "./ModalPointsSpentCity.vue?vue&type=style&index=0&id=05564065&lang=scss"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QInnerLoading,QSpinner});
