
import store from '@/store'
import { defineComponent } from 'vue'
import ScorecardPanel from '../set-model/scorecard/ScorecardPanel.vue'
import ScorecardAdd from '../set-model/scorecard/ScorecardAdd.vue'
import ScorecardEdit from '../set-model/scorecard/ScorecardEdit.vue'
import ScorecardDelete from '../set-model/scorecard/ScorecardDelete.vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'AssessSetModel',

  components: {
    ScorecardPanel,
    ScorecardAdd,
    ScorecardEdit,
    ScorecardDelete,
    DataLoading,
  },

  data() {
    return {
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      splitterSize: 40,
      selectedNode: null as any,
    }
  },

  computed: {
    isScorecardBusy() {
      return store.getters.getIsScorecardBusy
    },

    isScorecardTreeBusy() {
      return store.getters.getIsScorecardTreeBusy
    },

    getScorecardTreeItems() {
      let nodes = store.getters.getScorecardTreeItems
      return nodes ? nodes : []
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
    },

    addScorecard() {
      this.isActiveModalAdd = true
      console.log('addScorecard')
      this.isActiveModal = true
    },

    editScorecard() {
      this.isActiveModalEdit = true
      console.log('editScorecard')
      this.isActiveModal = true
    },

    deleteScorecard() {
      this.isActiveModalDelete = true
      console.log('deleteScorecard')
      this.isActiveModal = true
    },

    afterAction(setNode: any) {
      console.log('afterAction')
      this.reopenedTree(setNode, true) // true - перегружаем дерево с сервера
      this.hideModal()
    },

    selectNode() {
      let currentScorecardNode: any = null
      console.log('selectNode this.selectedNode', this.selectedNode)
      if (this.selectedNode) {
        const tree: any = this.$refs.scorecardTree
        const nodeData: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        console.log('selectNode nodeData', nodeData)
        if (nodeData) {
          const parentNode: any = nodeData.parent_id
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(nodeData.parent_id)))
            : null
          currentScorecardNode = {
            id: nodeData.id,
            parent_id: nodeData.parent_id ? nodeData.parent_id : 0,
            idx: nodeData.idx,
            title: nodeData.title,
            weight: nodeData.weight,
            hasChilds:
              nodeData.children !== undefined && nodeData.children.length > 0,
            parentName: parentNode ? parentNode.title : '',
          }
        }
      }
      // Устанавливаем текущий узел
      store
        .dispatch('setCurrentScorecardNode', currentScorecardNode)
        .then(() => {
          if (currentScorecardNode) {
            // Подгрузка значений для выбранного показателя
            store
              .dispatch('getScorecardValueItems', {
                filter: [
                  {
                    field_name: 'sv.scorecard_id',
                    operand: '=',
                    value: currentScorecardNode.id,
                  },
                ],
              })
              .then(
                (resolve) => {
                  console.log('getScorecardValueItems resolve', resolve)
                },
                (reject) => {
                  console.log('getScorecardValueItems reject', reject)
                }
              )
          }
        })
    },

    // Перегружаем дерево классификатора
    reopenedTree(setNode: any, isReloadTree: any) {
      if (isReloadTree) {
        this.loadScorecardTreeItems(setNode)
      } else {
        this.setTreeNode(setNode)
      }
    },

    setTreeNode(setNode: any) {
      if (setNode) {
        console.log('setTreeNode setNode', setNode)
        this.selectedNode = setNode
        this.selectNode()
        const tree: any = this.$refs.scorecardTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        while (curNode && curNode.parent_id !== undefined) {
          curNode = JSON.parse(
            JSON.stringify(tree.getNodeByKey(curNode.parent_id))
          )
          if (curNode) {
            tree.setExpanded(curNode.id, true)
          }
        }
      }
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          //this.loadScorecardTreeItems(null) // Временно убран серверный поиск !!!
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        //this.loadScorecardTreeItems(null)// Временно убран серверный поиск !!!
      }
    },

    getFilters() {
      return [
        /*, { // Временно убран серверный поиск !!!
                    field_name: 'scorecard_title',
                    operand: 'ilike',
                    value: this.search
                }*/
      ]
    },

    loadScorecardTreeItems(setNode: any) {
      store.dispatch('setCurrentScorecardNode', null)
      store
        .dispatch('getScorecardTreeItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getScorecardTreeItems resolve', resolve)
            this.setTreeNode(setNode)
          },
          (reject) => {
            console.log('getScorecardTreeItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadScorecardTreeItems(null)
    },
  },
})
