
import store from '@/store'
import MainMixin from '@/mixins/main'
import { defineComponent } from 'vue'
import VkMessageSource from "@/components/modules/polls/views/vk/generalizing/components/VkMessageSource.vue";
import VkMessageChildren from "@/components/modules/polls/views/vk/generalizing/components/VkMessageChildren.vue";
import VkMessagePanel from "@/components/modules/polls/views/vk/generalizing/components/VkMessagePanel.vue";

export default defineComponent({
  name: 'VkGeneralizing',

  components: {
    VkMessageSource,
    VkMessageChildren,
    VkMessagePanel,
  },

  mixins: [MainMixin],

  data() {
    return {
      isActiveModal: false,
      isActiveModalWordForm: false,
      isActiveModalSource: false,
      currentCountMessage: null as any,
      isFullCount: false,
      pollGroup: {
        id: 0,
        title: '',
        count: 0,
      },
      pollGroupOptions: [] as any[],
      pollGroupVOptions: [] as any[],
      poll: {
        id: 0,
        group_id: 0,
        title: '',
        origin_title: '',
        date_begin: '',
        date_end: '',
        notes: '',
        target: '',
        points: 0,
      },
      pollOptions: [] as any[],
      pollVOptions: [] as any[],
      question: {
        id: 0,
        poll_id: 0,
        type_id: 2,
        idx: 0,
        title: '',
        stitle: '',
        count: 0,
      },
      questionOptions: [] as any[],
      questionVOptions: [] as any[],
      splitterSize: 40,
      columnsResult: [
        {
          name: 'text',
          field: 'text',
          label: 'Сообщение',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество ответов',
          align: 'center',
        },
      ] as any,
      columnsChild: [
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'count',
          field: 'count',
          label: 'Количество ответов',
          align: 'center',
        },
      ] as any,
      formFilter: [],
      resultFilter: [
        {
          field_name: 'ag.message_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      filterProcessed: {
        field_name: 'ag.message_grp_processed',
        operand: '=',
        value: false as any,
        or: [] as any,
      },
      resultPagination: {
        sortBy: 'message_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageResultOptions: [10, 25, 50, 100],
      search: '',
      processedSign: null as any,
      processedOptions: [
        { id: 0, title: 'Все сообщения' },
        { id: 1, title: 'Только обработанные' },
        { id: 2, title: 'Только необработанные' },
      ],
      selectedResult: [] as any[],
      expandedResult: [] as any[],
      markedFind: [] as string[],
      refChildTables: [] as any[],
      getTableRefVar: [],
    }
  },

  computed: {
    isMessageGrpResultBusy() {
      return store.getters.isSocialNetworkMessageGrpResultBusy
    },

    isMessageGrpWorkedBusy() {
      return store.getters.isSocialNetworkMessageGrpWorkedBusy
    },

    getMessageGrpResultItems() {
      const messageItems = store.getters.getSocialNetworkMessageGrpResultItems
      let rows: any = []
      if (messageItems !== undefined) {
        for (let messageItem of messageItems) {
          rows.push({
            id: messageItem.getMessageGrpId(),
            text: messageItem.getMessageGrpText(),
            count: messageItem.getMessageGrpCount(),
            processed: messageItem.getMessageGrpProcessed(),
            sum: messageItem.getMessageGrpSum(),
            has_childs: messageItem.getHasChilds(),
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    }
  },

  created() {
    console.log('WorkingMessages')
    this.loadData()
  },

  methods: {
    onSelectionResult(details: any) {
      console.log('onSelectionResult details', details)
    },

    openChildTable(prop: any) {
      console.log('openChildTable prop', prop)

      setTimeout(() => {
        let refStr = 'childTableRow_' + prop.row.id,
            childTableRow = this.$refs[refStr] as any
        if (childTableRow) {
          childTableRow.$el.classList.remove('selected')
        }
      }, 300)

      if (this.expandedResult[0] === prop.row.id) {
        this.expandedResult = []
        this.getTableRefVar = []
      } else {
        this.getTableRefVar = []
        this.expandedResult = [prop.row.id]
      }
    },

    returnRef() {
      return this.$refs.vkMessagePanel
    },

    changeProcessedSign(event: any) {
      console.log('changeProcessedSign event', event)
      const processed = JSON.parse(JSON.stringify(event))
      console.log('processed', processed)
      switch (processed.id) {
        case 0:
          if (this.resultFilter.length > 1) {
            this.resultFilter.pop()
          }

          this.findMessagesResult()
          break
        case 1:
          if (this.resultFilter.length < 2) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[1].value = true

          this.findMessagesResult()
          break
        case 2:
          if (this.resultFilter.length < 2) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[1].value = false

          this.findMessagesResult()
          break
        default:
          console.log('Неизвестный признак обработки ответа: ', processed)

          this.findMessagesResult()
          break
      }
      console.log('this.resultFilter', this.resultFilter)
    },

    afterAction(setMessage: any) {
      console.log('afterAction setMessage', setMessage)
      this.search = ''
      this.clearSelectedMessageResult()
      this.expandedResult = [] // Скрываем все "раскрытые" ответы
      if (this.processedSign.id > 1) {
        this.processedSign = this.processedOptions[0]
        this.changeProcessedSign(this.processedSign)
      }

      this.resultPagination.page = 1
      this.resultPagination.rowsNumber = 0

      console.log('filter', this.resultFilter)
      store
          .dispatch('getSocialNetworkMessageGrpWithFindAnswerResultItems', {
            filter: this.resultFilter,
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: 0,
            },
            find_id: setMessage,
          })
          .then(
              (resolve) => {
                console.log(
                    'getMessageGrpWithFindAnswerResultItems resolve',
                    resolve
                )
                this.resultPagination.page = resolve.pagination.getPage()
                this.resultPagination.rowsNumber = resolve.pagination.getCnt()
                const messageItems = store.getters.getSocialNetworkMessageGrpResultItems
                if (messageItems !== undefined) {
                  for (let messageItem of messageItems) {
                    if (setMessage == messageItem.getMessageGrpId()) {
                      this.selectedResult.push({
                        id: messageItem.getMessageGrpId(),
                        text: messageItem.getMessageGrpText(),
                        count: messageItem.getMessageGrpCount(),
                        processed: messageItem.getMessageGrpProcessed(),
                        sum: messageItem.getMessageGrpSum(),
                        has_childs: messageItem.getHasChilds(),
                      })
                      this.onRowClickMessageResult(this.selectedResult[0], false) // false - не ресетить similarity
                      break
                    }
                  }
                }
              },
              (reject) => {
                console.log('getMessageGrpWithFindAnswerResultItems reject', reject)
              }
          )
    },

    clearSelectedMessageResult() {
      store.dispatch('setSocialNetworkCurrentMessageResult', null).then(() => {
        this.selectedResult = [] // Очищаем селект при поиске или смене фильтров
      })
    },

    onRowClickMessageResult(row: any, isResetSimilarity: boolean) {
      console.log('onRowClickMessageResult row', row)
      store.dispatch('setSocialNetworkCurrentMessageResult', row).then(() => {
        this.selectedResult = [row]
        setTimeout(() => {
          let refStr = 'childTableRow_' + row.id,
              childTableRow = this.$refs[refStr] as any
          if (childTableRow) {
            childTableRow.$el.classList.remove('selected')
          }
        }, 300)
        const workingMessagePanel: any = this.$refs.vkMessagePanel
        if (workingMessagePanel) {
          workingMessagePanel.loadData(true, isResetSimilarity) // true - загружать ответы
        }
      })
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalWordForm = false
      this.isActiveModalSource = false
      this.currentCountMessage = null
      this.isFullCount = false
    },

    onRowShowMessageSource(row: any, isFull: boolean) {
      this.hideModal()
      console.log('onRowShowMessageSource row', row)
      console.log('isFull', isFull)
      this.currentCountMessage = Object.assign(row)
      this.isFullCount = isFull
      this.isActiveModalSource = true
      this.isActiveModal = true
    },

    onRequestResult(props: any) {
      console.log('onRequestResult props', props)
      this.resultPagination = props.pagination
      this.loadMessageResultItems(false)
    },

    findMessagesResult() {
      let extFilter: any[] = []
      this.markedFind = []
      if (this.search.length) {
        extFilter.push({
          field_name: 'ag.message_grp_text',
          operand: 'ilike',
          value: this.search,
        })
        this.markedFind.push(this.search)
      }

      // Подгрузка ответов учитывая поиск
      this.resultFilter[0].or = extFilter
      console.log('this.resultFilter', this.resultFilter)
      this.loadMessageResultItems(true) // true - переключаем на первую страницу
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка ответов в результирующую таблицу
    loadMessageResultItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.clearSelectedMessageResult() // Очищаем селект при поиске или смене фильтров
        this.resultPagination.page = 1
        this.resultPagination.rowsNumber = 0
      }
      console.log('loadMessageResultItems filter', this.resultFilter)
      store
          .dispatch('getSocialNetworkMessageGrpResultItems', {
            filter: this.resultFilter,
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: this.resultPagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getMessageGrpResultItems resolve', resolve)
                this.resultPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageGrpResultItems reject', reject)
              }
          )
    },

    separateTableChild() {
      let childRef = this.$refs.tableMessageRef as any
      childRef.separateChild()
    },

    afterSeparate() {
      this.afterAction(this.expandedResult[0])
      this.getTableRefVar = []
    },

    checkChildLength(childLength: any) {
      this.getTableRefVar = childLength
    },

    loadData() {
      this.hideModal()
      this.processedSign = this.processedOptions[0]
      this.loadMessageResultItems(true)
    },
  },
})
