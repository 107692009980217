
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'UserAdd',

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        last_name: '',
        first_name: '',
        middle_name: '',
        email: '',
        user_role: {
          role: 'user',
          role_name: 'Пользователь',
        },
        username: '',
      },
    }
  },

  computed: {
    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },

    getUserRoles() {
      const roleItems = store.getters.getUserRoles
      let rows: any = []
      if (roleItems !== undefined) {
        for (let roleItem of roleItems) {
          rows.push({
            role: roleItem.getUserRole(),
            role_name: roleItem.getUserRoleName(),
          })
        }
      }
      return rows
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    saveUser() {
      const surname = this.$refs.surnameRef as any
      const firstname = this.$refs.firstnameRef as any
      const email = this.$refs.emailAuthRef as any
      const login = this.$refs.loginAuthRef as any

      surname.validate()
      firstname.validate()
      email.validate()
      login.validate()

      if (surname.hasError || firstname.hasError || email.hasError || login.hasError) {
        console.log(123)
      } else {
        let data = {
          last_name: this.item.last_name,
          first_name: this.item.first_name,
          middle_name: this.item.middle_name,
          email: this.item.email,
          user_role: this.item.user_role.role,
          username: this.item.username,
        }

        store.dispatch('createUsersItem', data).then(
            (resolve) => {
              console.log('resolve', resolve)
              this.$emit('afterAction')
            },
            (reject) => {
              console.log('reject', reject)
            }
        )

        return true
      }
    },

    closeUserAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      store.dispatch('getUserRoles').then(
        (resolve) => {
          console.log('resolve', resolve)
          this.item = {
            last_name: '',
            first_name: '',
            middle_name: '',
            email: '',
            user_role: {
              role: 'user',
              role_name: 'Пользователь',
            },
            username: '',
          }
          console.log('loadData getUserRoles', this.getUserRoles)
        },
        (reject) => {
          console.log('reject', reject)
          this.$emit('hideModal')
        }
      )
    },

    checkSpace(e: any) {
      if (e.which === 32) {
        e.preventDefault()
      }
    }
  },
})
