/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: region.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as region_pb from './region_pb';


export class RegionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListRegion = new grpcWeb.MethodDescriptor(
    '/proto.RegionService/ListRegion',
    grpcWeb.MethodType.UNARY,
    region_pb.ListRegionRequest,
    region_pb.ListRegionResponse,
    (request: region_pb.ListRegionRequest) => {
      return request.serializeBinary();
    },
    region_pb.ListRegionResponse.deserializeBinary
  );

  listRegion(
    request: region_pb.ListRegionRequest,
    metadata: grpcWeb.Metadata | null): Promise<region_pb.ListRegionResponse>;

  listRegion(
    request: region_pb.ListRegionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: region_pb.ListRegionResponse) => void): grpcWeb.ClientReadableStream<region_pb.ListRegionResponse>;

  listRegion(
    request: region_pb.ListRegionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: region_pb.ListRegionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.RegionService/ListRegion',
        request,
        metadata || {},
        this.methodDescriptorListRegion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.RegionService/ListRegion',
    request,
    metadata || {},
    this.methodDescriptorListRegion);
  }

}

