
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PeriodsChoice",
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkedProjects: [] as any,
      search: "",
      filter: "",
      tooltips: [] as any,
      periods: [],
      defaultChecked: [] as any,
    };
  },

  computed: {
    isGroupInline(): boolean {
      return !!this.inline;
    },

    getPeriodItems() {
      const periodItems = store.getters.getPeriodItems;

      let periods: any = [];
      if (periodItems !== undefined) {
        for (let periodItem of periodItems) {
          periods.push({
            value: periodItem.getPeriodId(),
            label: periodItem.getPeriodTitle(),
          });
        }
      }

      return periods;
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    makeChecked() {
      for (let i in store.getters.getCheckedPeriods) {
        this.checkedProjects.push(store.getters.getCheckedPeriods[i]);
      }
    },
    updateCheckedPeriod(check: any) {
      store.dispatch("setCheckedPeriods", check);
      // console.log('CHECKED ', store.getters.getCheckedPeriods)
    },
    getFilters() {
      return [];
    },

    loadPeriodItems() {
      store
        .dispatch("getPeriodItems", {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            resolve.forEach((item: any) => {
              this.defaultChecked.push(item.getPeriodId());
            });
            store
              .dispatch("setCheckedPeriods", this.defaultChecked)
              .then(() => this.makeChecked());
            console.log("getPeriodItems resolve", resolve);
          },
          (reject) => {
            console.log("getPeriodItems reject", reject);
          }
        );
    },

    loadData() {
      this.loadPeriodItems();
    },
  },
});
