
import store from '@/store'
import MainMixin from '@/mixins/main'
import { defineComponent } from 'vue'
import ClassAdd from '@/components/modules/polls/views/class/ClassAdd.vue'
import ClassEdit from '@/components/modules/polls/views/class/ClassEdit.vue'
import ClassDelete from '@/components/modules/polls/views/class/ClassDelete.vue'
import DataLoading from '@/components/DataLoading.vue'
import VkMessageClsPanel from "@/components/modules/polls/views/vk/classification/components/VkMessageClsPanel.vue";
import VkMessageClsSource from "@/components/modules/polls/views/vk/classification/components/VkMessageClsSource.vue";

export default defineComponent({
  name: 'VkClassification',

  components: {
    VkMessageClsPanel,
    VkMessageClsSource,
    ClassAdd,
    ClassEdit,
    ClassDelete,
    DataLoading,
  },

  mixins: [MainMixin],

  data() {
    return {
      isActiveModal: false,
      isActiveModalSource: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      currentCountMessage: null as any,
      isFullCount: false,
      classifier: {
        id: 0,
        name: '',
        descr: '',
        notes: '',
      },
      classifierOptions: [] as any[],
      classifierVOptions: [] as any[],
      filter: '',
      search: '',
      oldSearch: '',
      searchTimeout: setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>,
      minSearchSymbol: 3,
      splitterSize: 30,
      selectedNode: null as any,
      currentTab: '',
    }
  },

  computed: {
    isClassifierBusy() {
      return store.getters.getIsClassifierBusy
    },

    isClassTreeBusy() {
      return store.getters.getIsClassBusy
    },

    getClassTreeItems() {
      let nodes = store.getters.getClassTreeItems
      return nodes ? nodes : []
    },

    userRole() {
      return store.getters.getUserAccess
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalSource = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
    },

    onRowShowMessageSource(row: any, isFull: boolean) {
      this.hideModal()
      console.log('onRowShowMessageSource row', row)
      console.log('isFull', isFull)
      this.currentCountMessage = Object.assign(row)
      this.isFullCount = isFull
      this.isActiveModalSource = true
      this.isActiveModal = true
    },

    addClass() {
      this.hideModal()
      this.isActiveModalAdd = true
      console.log('addClass')
      this.isActiveModal = true
    },

    editClass(tagSpec: boolean) {
      if (tagSpec) {
        this.currentTab = 'tags'
      } else {
        this.currentTab = 'main'
      }

      this.hideModal()
      this.isActiveModalEdit = true
      console.log('editClass')
      this.isActiveModal = true

      const editClassPanel: any = this.$refs.editClassRef

      if (editClassPanel) {
        console.log('EDIT CLASS PANEL', editClassPanel)
      }
    },

    deleteClass() {
      this.hideModal()
      this.isActiveModalDelete = true
      console.log('deleteClass')
      this.isActiveModal = true
    },

    afterAction(setNode: any) {
      console.log('afterAction')
      this.reopenedTree(setNode, true) // true - перегружаем дерево с сервера
      this.hideModal()
    },

    popupHideClassifier(evt: any) {
      console.log('popupHideClassifier evt', evt)
      const classifierSelect: any = this.$refs.classifierSelect
      const modelValue: any = JSON.parse(
          JSON.stringify(classifierSelect.modelValue)
      )
      this.classifierVOptions = this.classifierOptions
      if (this.classifier.id == modelValue.id) {
        this.changeListClassifier(modelValue)
      }
    },

    filterClassifier(inputValue: string, doneFn: Function) {
      doneFn(
          () => {
            if (inputValue === '') {
              this.classifierVOptions = this.classifierOptions
            } else {
              const needle = inputValue.toLowerCase()
              const options = JSON.parse(
                  JSON.stringify(
                      this.classifierOptions.filter(
                          (v: any) => v.name.toLowerCase().indexOf(needle) > -1
                      )
                  )
              )
              if (options && options.length) {
                this.classifierVOptions = options.map((opt: any) => {
                  return {
                    id: opt.id,
                    name: this.strMarkRedPlus(inputValue, opt.name),
                    descr: opt.descr,
                    notes: opt.notes,
                  }
                })
              } else {
                this.classifierVOptions = []
              }
            }
          },
          (ref: any) => {
            if (
                inputValue !== '' &&
                ref.options.length > 0 &&
                ref.getOptionIndex() === -1
            ) {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
              ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
            }
          }
      )
    },

    abortFilterClassifier() {
      console.log(
          'abortFilterClassifier - что-то пошло не так при поиске в classifier'
      )
    },

    changeListClassifier(event: any) {
      console.log('changeListClassifier event', event)
      if (!event) {
        const classifierSelect: any = this.$refs.classifierSelect
        if (classifierSelect && this.classifier) {
          classifierSelect.updateInputValue(this.classifier.name)
        }
      } else {
        const name: string = event.name
        if (
            name.indexOf("<span style='color: red;'>") > -1 &&
            name.indexOf('</b></span>') > -1
        ) {
          //this.classifierVOptions = this.classifierOptions
          this.classifier = this.classifierOptions.find(
              (opt: any) => event.id == opt.id
          )
        } else {
          this.classifier = {
            id: event.id,
            name: event.name,
            descr: event.descr,
            notes: event.notes,
          }
        }
        this.initSearch()
        this.loadClassTreeItems(null)
      }
    },

    selectNode() {
      let currentNode: any = null
      console.log('selectNode this.selectedNode', this.selectedNode)
      if (this.selectedNode) {
        const tree: any = this.$refs.classTree
        const nodeData: any =
            tree !== undefined
                ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
                : null
        console.log('selectNode nodeData', nodeData)
        if (nodeData) {
          console.log('node data', nodeData)
          const parentNode: any = nodeData.parent_id
              ? JSON.parse(JSON.stringify(tree.getNodeByKey(nodeData.parent_id)))
              : null
          currentNode = {
            id: nodeData.id,
            cls_id: this.classifier.id,
            parent_id: nodeData.parent_id ? nodeData.parent_id : 0,
            idx: nodeData.idx,
            name: nodeData.name,
            group_name: nodeData.group_name,
            color: nodeData.color,
            descr: nodeData.descr,
            notes: nodeData.notes,
            ratio: nodeData.ratio,
            hasChilds:
                nodeData.children !== undefined && nodeData.children.length > 0,
            parentName: parentNode ? parentNode.name : '',
          }

          console.log('CURR NODE', currentNode)
        }
      }
      // Устанавливаем текущий узел
      store.dispatch('setCurrentNode', currentNode).then(() => {
        if (currentNode) {
          // Подгрузка тэгов для класса
          store
              .dispatch('getClassTagItems', {
                filter: [
                  {
                    field_name: 'class_id',
                    operand: '=',
                    value: currentNode.id,
                  },
                ],
              })
              .then(
                  (resolve) => {
                    console.log('resolve', resolve)
                    const clsMessagePanel: any = this.$refs.vkClsMessagePanel
                    const editClassPanel: any = this.$refs.editClassRef

                    if (clsMessagePanel) {
                      console.log('CLS MESS PANEL REF', clsMessagePanel)
                      console.log('CLS EDIT PANEL REF', editClassPanel)
                      clsMessagePanel.loadData(true) // true - загружать ответы
                    }
                  },
                  (reject) => {
                    console.log('reject', reject)
                  }
              )
        }
      })
    },

    // Перегружаем дерево классификатора
    reopenedTree(setNode: any, isReloadTree: any) {
      if (isReloadTree) {
        this.loadClassTreeItems(setNode)
      } else {
        this.setTreeNode(setNode)
      }
    },

    setTreeNode(setNode: any) {
      if (setNode) {
        console.log('setTreeNode setNode', setNode)
        this.selectedNode = setNode
        this.selectNode()
        const tree: any = this.$refs.classTree
        let curNode: any =
            tree !== undefined
                ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
                : null
        while (curNode && curNode.parent_id !== undefined) {
          curNode = JSON.parse(
              JSON.stringify(tree.getNodeByKey(curNode.parent_id))
          )
          if (curNode) {
            tree.setExpanded(curNode.id, true)
          }
        }
      }
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    initSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.filter = ''
      this.search = ''
      this.oldSearch = ''
      this.searchTimeout = setTimeout(() => {}, 500)
    },

    onInputSearchControl(event: any) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      console.log('onInputSearchControl event', event)
      this.searchTimeout = setTimeout(() => {
        const searchStr: string = event.target.value

        if (searchStr.length >= this.minSearchSymbol) {
          // Устанавливаем строку поиска
          this.search = searchStr.toLowerCase()
          //this.loadClassTreeItems(null) // Временно убран серверный поиск !!!
        } else {
          this.clearSearch(this.oldSearch.length >= this.minSearchSymbol)
        }
        this.oldSearch = searchStr
      }, 500)
    },

    clearSearch(isReload = true) {
      this.search = ''
      if (isReload) {
        //this.loadClassTreeItems(null)// Временно убран серверный поиск !!!
      }
    },

    getFilters() {
      return [
        {
          field_name: 'classifier_id',
          operand: '=',
          value: this.classifier.id,
        } /*, { // Временно убран серверный поиск !!!
                    field_name: 'class_name',
                    operand: 'ilike',
                    value: this.search
                }*/,
      ]
    },

    loadClassTreeItems(setNode: any) {
      store.dispatch('setCurrentNode', null)
      store
          .dispatch('getClassTreeItems', {
            filter: this.getFilters(),
          })
          .then(
              (resolve) => {
                console.log('getClassTreeItems resolve', resolve)
                this.setTreeNode(setNode)
              },
              (reject) => {
                console.log('getClassTreeItems reject', reject)
              }
          )
    },

    clearClassifierItems() {
      this.classifier = {
        id: 0,
        name: '',
        descr: '',
        notes: '',
      }
      this.classifierOptions = []
      this.classifierVOptions = []
      this.initSearch()
    },

    loadClassifierItems() {
      store.dispatch('getClassifierItems').then(
          (resolve) => {
            console.log('getClassifierItems resolve', resolve)
            if (resolve && resolve.items && resolve.items.length) {
              this.classifierOptions = []
              for (let clsItem of resolve.items) {
                this.classifierOptions.push({
                  id: clsItem.getClassifierId(),
                  name: clsItem.getClassifierName(),
                  descr: clsItem.getClassifierDescr(),
                  notes: clsItem.getClassifierNotes(),
                })
              }
              this.classifierVOptions = this.classifierOptions
              this.changeListClassifier(this.classifierVOptions[0])
            } else {
              this.clearClassifierItems()
            }
          },
          (reject) => {
            console.log('getClassifierItems reject', reject)
            this.clearClassifierItems()
          }
      )
    },

    loadData() {
      this.hideModal()
      this.loadClassifierItems()
    },
  },
})
