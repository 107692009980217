/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: poll_info_form.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as poll_info_form_pb from './poll_info_form_pb';


export class PollInfoFormServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPollInfoForm = new grpcWeb.MethodDescriptor(
    '/proto.PollInfoFormService/PollInfoForm',
    grpcWeb.MethodType.UNARY,
    poll_info_form_pb.PollInfoFormRequest,
    poll_info_form_pb.PollInfoFormResponse,
    (request: poll_info_form_pb.PollInfoFormRequest) => {
      return request.serializeBinary();
    },
    poll_info_form_pb.PollInfoFormResponse.deserializeBinary
  );

  pollInfoForm(
    request: poll_info_form_pb.PollInfoFormRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_info_form_pb.PollInfoFormResponse>;

  pollInfoForm(
    request: poll_info_form_pb.PollInfoFormRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_info_form_pb.PollInfoFormResponse) => void): grpcWeb.ClientReadableStream<poll_info_form_pb.PollInfoFormResponse>;

  pollInfoForm(
    request: poll_info_form_pb.PollInfoFormRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_info_form_pb.PollInfoFormResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollInfoFormService/PollInfoForm',
        request,
        metadata || {},
        this.methodDescriptorPollInfoForm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollInfoFormService/PollInfoForm',
    request,
    metadata || {},
    this.methodDescriptorPollInfoForm);
  }

  methodDescriptorPollInfoFormCls = new grpcWeb.MethodDescriptor(
    '/proto.PollInfoFormService/PollInfoFormCls',
    grpcWeb.MethodType.UNARY,
    poll_info_form_pb.PollInfoFormClsRequest,
    poll_info_form_pb.PollInfoFormClsResponse,
    (request: poll_info_form_pb.PollInfoFormClsRequest) => {
      return request.serializeBinary();
    },
    poll_info_form_pb.PollInfoFormClsResponse.deserializeBinary
  );

  pollInfoFormCls(
    request: poll_info_form_pb.PollInfoFormClsRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_info_form_pb.PollInfoFormClsResponse>;

  pollInfoFormCls(
    request: poll_info_form_pb.PollInfoFormClsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_info_form_pb.PollInfoFormClsResponse) => void): grpcWeb.ClientReadableStream<poll_info_form_pb.PollInfoFormClsResponse>;

  pollInfoFormCls(
    request: poll_info_form_pb.PollInfoFormClsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_info_form_pb.PollInfoFormClsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollInfoFormService/PollInfoFormCls',
        request,
        metadata || {},
        this.methodDescriptorPollInfoFormCls,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollInfoFormService/PollInfoFormCls',
    request,
    metadata || {},
    this.methodDescriptorPollInfoFormCls);
  }

}

