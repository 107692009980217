
import store from '@/store'
import MainMixin from '@/mixins/main'
import * as XLSX from 'xlsx'
import {defineComponent} from 'vue'
import TableAnswerSource from './TableAnswerSource.vue'
import WordFormSettings from './WordFormSettings.vue';
import date from 'quasar/src/utils/date/date.js';
import AddQuestionPanel from "@/components/modules/polls/views/answers/AddQuestionPanel.vue";
import QuestionChilds from "@/components/modules/polls/views/answers/QuestionChilds.vue";

export default defineComponent({
  name: 'WorkingQuestions',

  components: {
    QuestionChilds,
    AddQuestionPanel,
    TableAnswerSource,
    WordFormSettings,
    //TableRecursive,
    //DataLoading,
  },

  mixins: [MainMixin],

  props: {
    pollId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isActiveModal: false,
      isActiveModalWordForm: false,
      isActiveModalSource: false,
      currentCountAnswer: null as any,
      isFullCount: false,
      pollGroup: {
        id: 0,
        title: '',
        count: 0,
      },
      pollGroupOptions: [] as any[],
      pollGroupVOptions: [] as any[],
      poll: {
        id: 0,
        group_id: 0,
        title: '',
        origin_title: '',
        date_begin: '',
        date_end: '',
        notes: '',
        target: '',
        points: 0,
        total_answers: 0,
        open_answers: 0,
        loaded_answers: 0,
        loading_flag: false,
        date_update: '',
        user_count: 0,
      },
      pollOptions: [] as any[],
      pollVOptions: [] as any[],
      question: {
        id: 0,
        poll_id: 0,
        type_id: 2,
        idx: 0,
        title: '',
        stitle: '',
        count: 0,
      },
      questionOptions: [] as any[],
      questionVOptions: [] as any[],
      splitterSize: 40,
      columnsResult: [
        {
          name: 'question_stitle',
          field: 'question_stitle',
          label: 'Наименование вопроса',
          align: 'left',
        },
        {
          name: 'answer_src_sum',
          field: 'answer_src_sum',
          label: 'Количество ответов',
          align: 'center',
        },
      ] as any,
      columnsChild: [
        {
          name: 'question_stitle',
          field: 'question_stitle',
          label: 'Наименование вопроса',
          align: 'left',
        },
        {
          name: 'answer_src_count',
          field: 'answer_src_count',
          label: 'Количество ответов',
          align: 'center',
        },
      ] as any,
      formFilter: [],
      resultFilter: [
        {
          field_name: 'q.question_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
        {
          field_name: 'q.question_stitle',
          operand: 'ilike',
          value: '',
        },
      ],
      filterProcessed: {
        field_name: 'q.question_processed',
        operand: '=',
        value: false as any,
        or: [] as any,
      },
      resultPagination: {
        sortBy: 'answer_src_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageResultOptions: [10, 25, 50, 100],
      search: '',
      processedSign: null as any,
      processedOptions: [
        { id: 0, title: 'Все вопросы' },
        { id: 1, title: 'Только обработанные' },
        { id: 2, title: 'Только необработанные' },
      ],
      selectedResult: [] as any[],
      expandedResult: [] as any[],
      markedFind: [] as string[],
      refChildTables: [] as any[],
      enjoyCount: '',
      groupInput: '',
      isDropdownOpen: false,
      pollGroupNodes: [] as any,
      pollGroupTickedNodes: [] as any,
      getTableRefVar: [] as any
    }
  },

  computed: {
    date() {
      return date
    },
    isPollGroupPresent() {
      const grpItems = store.getters.getPollGroupItems
      return grpItems && grpItems.length
    },

    isPollPresent() {
      const pItems = store.getters.getPollItems
      return pItems && pItems.length
    },

    isQuestionPresent() {
      const qItems = store.getters.getQuestionItems
      return qItems && qItems.length
    },

    isPollGroupBusy() {
      return store.getters.getIsPollGroupBusy
    },

    isPollBusy() {
      return store.getters.getIsPollBusy
    },

    isQuestionBusy() {
      return store.getters.isQuestionBusy
    },

    isAnswerGrpBusy() {
      return store.getters.isAnswerGrpBusy
    },

    isAnswerGrpResultBusy() {
      return store.getters.isAnswerGrpResultBusy
    },

    isAnswerGrpWorkedBusy() {
      return store.getters.isAnswerGrpWorkedBusy
    },

    userRole() {
      return store.getters.getUserAccess
    },

    getQuestionItems() {
      const items = store.getters.getQuestionItems
      const rows = []

      if (items) {
        for (const i of items) {
          rows.push({
            question_id: i.getQuestionId(),
            question_parent_id: i.getQuestionParentId(),
            poll_id: i.getPollId(),
            question_type_id: i.getQuestionTypeId(),
            question_idx: i.getQuestionIdx(),
            question_title: i.getQuestionTitle(),
            question_stitle: i.getQuestionStitle(),
            question_processed: i.getQuestionProcessed(),
            answer_src_count: i.getAnswerSrcCount(),
            answer_src_sum: i.getAnswerSrcSum(),
            similarity: i.getSimilarity(),
            has_childs: i.getHasChilds(),
          })
        }
      }

      return rows
    }
  },

  created() {
    console.log('addQuestionPanel')
    this.loadData()
  },

  methods: {
    makeExcelForm() {
      console.log('makeExcelForm')
      store
          .dispatch('getAnswerGrpFormItems', {
            filter: this.formFilter,
            sort: {
              name: 'answer_grp_sum',
              exp: 'DESC',
            },
            question_id: this.question.id,
          })
          .then(
              (resolve) => {
                console.log('getAnswerGrpFormItems resolve', resolve)

                if (resolve && resolve.length) {
                  // Маппинг данных
                  const data = resolve.map((item: any) => {
                    return {
                      'Группа(категория) опросов': item.getPollGroupTitle(),
                      'Опрос': item.getPollTitle(),
                      'Вопрос': item.getQuestionStitle(),
                      'Количество ответов': item.getAnswerGrpSum(),
                      'Вопросы': item.getAnswerGrpText(),
                    }
                  })

                  const ws = XLSX.utils.json_to_sheet(data)

                  // Подгоняем ширину столбцов под контент
                  const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
                  for (const d of data) {
                    Object.values(d).forEach((element: any, index) => {
                      const length = element.toString().length
                      if (colLengths[index] < length) {
                        colLengths[index] = length
                      }
                    })
                  }
                  ws["!cols"] = colLengths.map((l) => {
                    return {
                      wch: l,
                    }
                  })

                  const wb = XLSX.utils.book_new()
                  XLSX.utils.book_append_sheet(wb, ws, 'Обобщенные ответы')
                  XLSX.writeFile(wb, 'answers_' + this.getFormattedDateTimeUnderline(null) + '.xlsx')
                } else {
                  console.log('Нет данных для вывода в файл!')
                }
              },
              (reject) => {
                console.log('getAnswerGrpFormItems reject', reject)
              }
          )
    },

    makeWordForm() {
      this.hideModal()
      console.log('makeWordForm')
      this.isActiveModalWordForm = true
      this.isActiveModal = true
    },

    onSelectionResult(details: any) {
      console.log('onSelectionResult details', details)
    },

    openChildTable(prop: any) {
      console.log('openChildTable prop', prop)

      setTimeout(() => {
        let refStr = 'childQuestionTableRow_' + prop.row.question_id,
            childTableRow = this.$refs[refStr] as any
        if (childTableRow) {
          childTableRow.$el.classList.remove('selected')
        }
      }, 300)

      if (this.expandedResult[0] === prop.row.question_id) {
        this.expandedResult = []
        this.getTableRefVar = []
        console.log('da')
      } else {
        this.getTableRefVar = []
        this.expandedResult = [prop.row.question_id]
      }
    },

    returnRef() {
      return this.$refs.addQuestionPanel
    },

    changeProcessedSign(event: any) {
      console.log('changeProcessedSign event', event)
      const processed = JSON.parse(JSON.stringify(event))
      console.log('processed', processed)
      switch (processed.id) {
        case 0:
          if (this.resultFilter.length > 2) {
            this.resultFilter.pop()
          }

          this.findAnswersResult()
          break
        case 1:
          if (this.resultFilter.length < 3) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[2].value = true

          this.findAnswersResult()
          break
        case 2:
          if (this.resultFilter.length < 3) {
            this.resultFilter.push(this.filterProcessed)
          }
          this.resultFilter[2].value = false

          this.findAnswersResult()
          break
        default:
          console.log('Неизвестный признак обработки ответа: ', processed)

          this.findAnswersResult()
          break
      }
      console.log('this.resultFilter', this.resultFilter)
    },

    afterAction(setAnswer: any) {
      console.log('afterAction setAnswer', setAnswer)
      this.search = ''
      this.clearSelectedAnswerResult()
      this.expandedResult = [] // Скрываем все "раскрытые" ответы
      if (this.processedSign.id > 1) {
        this.processedSign = this.processedOptions[0]
        this.changeProcessedSign(this.processedSign)
      }

      this.resultPagination.page = 1
      this.resultPagination.rowsNumber = 0

      console.log('filter', this.resultFilter)

      this.loadQuestionItems(true)
    },

    clearSelectedAnswerResult() {
      store.dispatch('setCurrentQuestion', null).then(() => {
        this.selectedResult = [] // Очищаем селект при поиске или смене фильтров
      })
    },

    onRowClickAnswerResult(row: any, isResetSimilarity: boolean) {
      console.log('onRowClickAnswerResult row', row)
      store.dispatch('setCurrentQuestion', row).then(() => {
        this.selectedResult = [row]
        setTimeout(() => {
          let refStr = 'childTableQuestionRow_' + row.id,
              childTableRow = this.$refs[refStr] as any
          if (childTableRow) {
            childTableRow.$el.classList.remove('selected')
          }
        }, 300)
        const addQuestionPanel: any = this.$refs.addQuestionPanel
        if (addQuestionPanel) {
          addQuestionPanel.loadData(true, isResetSimilarity) // true - загружать ответы
        }
      })
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalWordForm = false
      this.isActiveModalSource = false
      this.currentCountAnswer = null
      this.isFullCount = false
    },

    onRowShowAnswerSource(row: any, isFull: boolean) {
      this.hideModal()
      console.log('onRowShowAnswerSource row', row)
      console.log('isFull', isFull)
      this.currentCountAnswer = Object.assign(row)
      this.isFullCount = isFull
      this.isActiveModalSource = true
      this.isActiveModal = true
    },

    onRequestResult(props: any) {
      console.log('onRequestResult props', props)
      this.resultPagination = props.pagination
      this.loadQuestionItems(false)
    },

    findAnswersResult() {
      this.markedFind = []
      if (this.search.length) {
        this.markedFind.push(this.search)
      }

      this.resultFilter[1].value = this.search

      // Подгрузка ответов учитывая поиск
      console.log('this.resultFilter', this.resultFilter)
      this.loadQuestionItems(true) // true - переключаем на первую страницу
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка ответов в результирующую таблицу
    loadQuestionItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.clearSelectedAnswerResult() // Очищаем селект при поиске или смене фильтров
        this.resultPagination.page = 1
        this.resultPagination.rowsNumber = 0
      }
      console.log('loadQuestionItems filter', this.resultFilter)
      store
          .dispatch('getQuestionItems', {
            filter: this.resultFilter,
            sort: {
              name: this.resultPagination.sortBy,
              exp: this.resultPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.resultPagination.page,
              limit: this.resultPagination.rowsPerPage,
              pages: 0,
              cnt: 0,
            },
            poll_id: this.$props.pollId,
          })
          .then(
              (resolve) => {
                console.log('getQuestionItems resolve', resolve)
                this.resultPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getQuestionItems reject', reject)
              }
          )
    },

    clearQuestionItems() {
      this.question = {
        id: 0,
        poll_id: 0,
        type_id: 2,
        idx: 0,
        title: '',
        stitle: '',
        count: 0,
      }
      store.dispatch('setQuestionItems', [])
      this.questionOptions = []
      this.questionVOptions = []
    },

    clearPollItems() {
      this.clearQuestionItems()
      this.poll = {
        id: 0,
        group_id: 0,
        title: '',
        origin_title: '',
        date_begin: '',
        date_end: '',
        notes: '',
        target: '',
        points: 0,
        total_answers: 0,
        open_answers: 0,
        loaded_answers: 0,
        loading_flag: false,
        date_update: '',
        user_count: 0,
      }
      store.dispatch('setPollItems', [])
      this.pollOptions = []
      this.pollVOptions = []
    },

    loadPollItems() {
      this.groupInput = 'Выбрано групп (' + this.pollGroupTickedNodes.length + ')'

      let tempFilter = {
        field_name: 'p.poll_group_id',
        operand: '>',
        value: 0,
        or: [],
      } as any

      this.pollGroupTickedNodes.forEach((el: any) => {
        tempFilter.or.push({
          field_name: 'p.poll_group_id',
          operand: '=',
          value: el,
        })
      })

      console.log('TEMP FILTER', tempFilter)

      store
          .dispatch('getPollItems', {
            filter: [tempFilter],
            sort: {
              name: 'p.poll_id',
              exp: 'asc'
            }
          })
          .then((resolve: any) => {
            console.log('getPollItems resolve', resolve)
            if (resolve && resolve.items && resolve.items.length) {
              this.pollOptions = []
              for (let pollItem of resolve.items) {
                if (!pollItem.getPollLoadingFlag()) {
                  this.pollOptions.push({
                    id: pollItem.getPollId(),
                    group_id: pollItem.getPollGroupId(),
                    title: pollItem.getPollId() + ' | ' + pollItem.getPollTitle(),
                    origin_title: pollItem.getPollTitle(),
                    date_begin: this.getFormattedDateFromTimestamp(
                        pollItem.getPollDateBegin().getSeconds()
                    ),
                    date_end: this.getFormattedDateFromTimestamp(
                        pollItem.getPollDateEnd().getSeconds()
                    ),
                    notes: pollItem.getPollNotes(),
                    target: pollItem.getPollTarget(),
                    points: pollItem.getPollPoints(),
                    total_answers: pollItem.getPollTotalAnswers(),
                    open_answers: pollItem.getPollOpenAnswers(),
                    loaded_answers: pollItem.getPollLoadedAnswers(),
                    loading_flag: pollItem.getPollLoadingFlag(),
                    date_update: pollItem.getPollDateUpdate(),
                    user_count: pollItem.getPollUserCount(),
                  })
                }
              }
              this.pollVOptions = this.pollOptions
            } else {
              this.clearPollItems()
            }
          })
    },

    clearPollGroupItems() {
      this.clearPollItems()
      this.pollGroup = {
        id: 0,
        title: '',
        count: 0,
      }
      store.dispatch('setPollGroupItems', [])
      this.pollGroupOptions = []
      this.pollGroupVOptions = []
    },

    separateTableChild() {
      let childRef = this.$refs.questionChildRef as any
      childRef.separateChild()
    },

    afterSeparate() {
      this.afterAction(this.expandedResult[0])
      this.getTableRefVar = []
    },

    checkChildLength(childLength: any) {
      this.getTableRefVar = childLength
    },

    loadData() {
      this.hideModal()
      this.processedSign = this.processedOptions[0]
      this.loadQuestionItems(false)
    },

    clickOutside(e: any) {
      const elem = document.querySelector('.project-select-tree') as any
      return this.isDropdownOpen = elem.contains(e.target) && this.isDropdownOpen
    },

    clickChecker() {
      this.isDropdownOpen = !this.isDropdownOpen
      document.addEventListener('click', this.clickOutside)
    },
  },
})
