import * as XLSX from 'xlsx'

type ExportType = 'xlsx' | 'csv'

type RenameObjectKeysFn =
  <TargetObject extends Record<string, any>,
    KeysMap extends Partial<Record<keyof TargetObject, string>>>
    (obj: TargetObject, keyMap: KeysMap) => void

export const useExport = () => {

  const renameObjectKeys: RenameObjectKeysFn = (obj, keyMap) => {
    return Object.keys(keyMap).reduce((acc: Record<string, any>, oldKey) => {
      const newKey = keyMap[oldKey];

      if (!newKey) return acc

      acc[newKey] = acc[oldKey];
      delete acc[oldKey];
      return acc;
    }, obj);
  }

  const exportToExcel = (type: ExportType, fileName: string = '', data: Record<string, unknown>[]) => {

    if (type == 'xlsx') {
      fileName += '.xlsx'
    } else {
      fileName += '.csv'
    }

    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Активность')
    XLSX.writeFile(wb, fileName)
  }

  return {
    exportToExcel,
    renameObjectKeys
  }
}
