
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClassDelete',

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        id: 0,
        parent_id: 0,
        name: '',
        hasChilds: false,
      },
    }
  },

  computed: {
    isClassBusy() {
      return store.getters.getIsClassBusy
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    actualDelete() {
      store.dispatch('deleteClassItem', { id: this.item.id }).then(
        (resolve) => {
          console.log('resolve', resolve)
          store.dispatch('setCurrentNode', null) // Очищаем текущий узел
          this.$emit(
            'afterAction',
            0 !== this.item.parent_id ? this.item.parent_id : null
          )
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
    },

    closeClassDelete() {
      this.$emit('hideModal')
    },

    loadData() {
      let currentNode = store.getters.getCurrentNode
      if (currentNode) {
        this.item = {
          id: currentNode.id,
          parent_id: currentNode.parent_id,
          name: currentNode.name,
          hasChilds: currentNode.hasChilds,
        }
      } else {
        this.closeClassDelete()
      }
    },
  },
})
