import script from "./MetricComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./MetricComponent.vue?vue&type=script&setup=true&lang=ts"

import "./MetricComponent.vue?vue&type=style&index=0&id=3bee8f7e&lang=scss"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QInput,QIcon,QTree});
