<template>
  <q-card class="q-pa-md q-mb-md">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </q-card>
</template>

<script>
export default {
  name: 'GenderAgeStructureChart',
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Половозрастная структура',
        },
        xAxis: {
          categories: ['18-24', '24-35', '36-45', '46-55', '55+'],
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: 'percent',
            borderRadius: 5,
          },
        },
        series: [
          {
            name: 'Ж',
            data: [4, 4, 2, 4, 4],
          },
          {
            name: 'М',
            data: [1, 4, 3, 2, 3],
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss">
// .highcharts-credits {
//   display: none;
// }
</style>
