import {
  DashboardAnomalyFilter,
  DashboardAnomalyFilterItem,
  DashboardAnomalyPagination,
  DashboardAnomalySort,
  ListDashboardAnomalyBlocksRequest,
  ListDashboardAnomalyTypesRequest,
} from "../proto/pb/anomaly_pb";
import { DashboardAnomalyServiceClient } from "../proto/pb/AnomalyServiceClientPb";

import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";
import { getAuthToken } from "@/mixins/authMetadata";

export class AnomalyApi {
  client: DashboardAnomalyServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new DashboardAnomalyServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
  }

  createFilter(data: any) {
    const filter = new DashboardAnomalyFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new DashboardAnomalyFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {
    const sort = new DashboardAnomalySort();
    const name: string = data.name !== undefined ? data.name.toString() : "";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {
    const pagination = new DashboardAnomalyPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number = data.limit !== undefined ? Number(data.limit) : 100;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  listDashboardAnomalyBlocks(
    filter: DashboardAnomalyFilterItem[] | Array<any> = [],
    sort: DashboardAnomalySort | object = {},
    data: ListDashboardAnomalyBlocksRequest.AsObject
  ) {
    const req = new ListDashboardAnomalyBlocksRequest();

    req
      .setFilter(this.createFilter(filter || []))
      .setSort(this.createSort(sort || {}))
      .setIdsList(data.idsList)
      .setProjectIdsList(data.projectIdsList)
      .setDate(data.date);

    console.log("req", req.toObject());

    return this.client.listDashboardAnomalyBlocks(req, getAuthToken());
  }

  listDashboardAnomalyTypes(
    filter: DashboardAnomalyFilterItem[] | Array<any> = [],
    sort: DashboardAnomalySort | object = {},
    pagination: DashboardAnomalyPagination | object = {}
  ) {
    const req = new ListDashboardAnomalyTypesRequest();

    req
      .setFilter(this.createFilter(filter || []))
      .setSort(this.createSort(sort || {}))
      .setPagination(this.createPagination(pagination || {}));

    return this.client.listDashboardAnomalyTypes(req, getAuthToken());
  }
}
