import { createApp } from "vue";

// import * as Sentry from '@sentry/vue'


import moduleStore from "./store/index";
import App from "./App.vue";
import LoginApp from "./LoginApp.vue";
import router from "@/router";

import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";


import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import vennInit from 'highcharts/modules/venn'
import variablepieInit from 'highcharts/modules/variable-pie'
import accessibility from 'highcharts/modules/accessibility'
import NoDataModule from 'highcharts/modules/no-data-to-display'
import heatmap from 'highcharts/modules/heatmap'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import offlineExport from 'highcharts/modules/offline-exporting'
import Stock from 'highcharts/modules/stock'


import barInit from "highcharts/modules/data";
import boost from "highcharts/modules/boost";

import SetPassword from "@/SetPassword.vue";
import ChangePassword from "@/ChangePassword.vue";

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/pagination'
// register Swiper custom elements
register();

accessibility(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
offlineExport(Highcharts)
vennInit(Highcharts)
variablepieInit(Highcharts)
barInit(Highcharts)
heatmap(Highcharts)
NoDataModule(Highcharts)
boost(Highcharts)
Stock(Highcharts)


Highcharts.setOptions({
  lang: {
    noData: "Нет доступных данных",
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    shortMonths: [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
    weekdays: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
  },
  noData: {
    style: {
      fontSize: "24px",
    },
  },
});

// Пробуем авторизоваться
const token = localStorage.getItem("access-key");
let appType;

if (token) {
  appType = App;

  if (router().options.history.location.includes("/registration-confirm")) {
    localStorage.removeItem("access-key");
    localStorage.removeItem("refresh-key");
    appType = SetPassword;
  }

  if (router().options.history.location.includes("/change-password")) {
    localStorage.removeItem("access-key");
    localStorage.removeItem("refresh-key");
    appType = ChangePassword;
  }
} else if (
  router().options.history.location.includes("/registration-confirm")
) {
  appType = SetPassword;
} else if (
  router().options.history.location.includes("/change-password")
) {
  appType = ChangePassword;
} else {
  appType = LoginApp;
}

// router().beforeEach((to, from, next) => {
//   // const isAdmin = to.matched.some((record) => record.meta.admin)
//   // const isModerator = to.matched.some((record) => record.meta.moderator)
//
//   store
//       .dispatch('getUserData', {
//         token: localStorage.getItem('access-key')
//       })
//       .then((res) => {
//         console.log('BEFOREEACH ROUTER'res)
//       })
// })

// const currentD: any = new Date()
// const startHappyHourD = new Date()
// startHappyHourD.setHours(3, 0, 0)
// const endHappyHourD = new Date()
// endHappyHourD.setHours(6, 0, 0)

const app = createApp(appType)
  .use(HighchartsVue as any)
  .use(Quasar, quasarUserOptions)

// Sentry.init({
//   app,
//   dsn: 'https://3fc3e2186566f1532e86455b31f5cfa3@sentry.nigma2.science-soft.ru/5',
//   environment: process.env.SENTRY_ENV,
//   release: process.env.SENTRY_VERSION,
//   integrations: [
//     new Sentry.Breadcrumbs({ console: !process.env.DEV }),
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router()),
//     }),
//     new Sentry.Replay({
//       maskAllInputs: false,
//       maskAllText: false,
//       maskFn: (text) => text,
//     }),
//   ],
//   normalizeDepth: 5,
//
//   ignoreErrors: ['token is expired', 'Loading CSS chunk'],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//
//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost'],
//
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
//
//   sendDefaultPii: true,
// })


app.use(router()).use(moduleStore).mount("#app");
