
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TagEdit',

  props: {
    tagItem: {
      type: Object as any,
    },
  },

  data() {
    return {
      item: {
        index: 0,
        name: '',
        descr: '',
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    saveTag() {
      this.$emit('afterEdit', this.item)
    },

    closeTagEdit() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        index: this.tagItem.index,
        name: this.tagItem.name,
        descr: this.tagItem.descr,
      }
    },
  },
})
