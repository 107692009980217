
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MetricsChoice',

  data() {
    return {
      ticked: [] as any,
      nodes: [] as any,
      expanded: [] as any,
      simple: [] as any,

      // simple: [
      //   {
      //     label: 'Активность',
      //     children: [{ label: 'Активность 111' }, { label: 'Активность 222' }],
      //   },
      // ],
    }
  },

  computed: {
    getMetricItems() {
      const metricItems = store.getters.getMetricItems
      const metricSubItems = store.getters.getMetricValueItems

      let metrics: any = []
      if (metricItems !== undefined) {
        for (let metricItem of metricItems) {
          metrics.push({
            label: metricItem.getMetricName(),
            value: metricItem.getMetricId(),
            key: `metric-${metricItem.getMetricId()}`,
          })
        }

        if (metricSubItems !== undefined) {
          for (let metric of metrics) {
            for (let metricSubItem of metricSubItems) {
              if (metric.label === metricSubItem.getMetricName()) {
                if (!Object.prototype.hasOwnProperty.call(metric, 'children')) {
                  metric.children = []
                }
                metric.children.push({
                  label: metricSubItem.getMetricValueName(),
                  value: metricSubItem.getMetricValueIdx(),
                  key: metricSubItem,
                })
              }
            }
          }
        }
      }
      return metrics
    },
  },

  created() {
    this.loadData()
  },

  // watch: {
  //   ticked(val) {
  //     // console.log(8888, val)
  //   },
  // },

  methods: {
    updateTicked(ticked: any) {
      let testObj: any = {}
      let checkedMetricsName: any = []

      if (ticked.length == 0) {
        testObj = {}
        store.dispatch('setCheckedMetrics', testObj)
        store.dispatch('setCheckedMetricsNames', checkedMetricsName)
        return
      }

      for (let i in ticked) {
        if (
          !Object.prototype.hasOwnProperty.call(
            testObj,
            ticked[i].getMetricId()
          )
        ) {
          if (Object.keys(testObj).length < 2) {
            checkedMetricsName.push(ticked[i].getMetricName())
            // testObj[ticked[i].getMetricId()] = []
            testObj[ticked[i].getMetricId()] = {
              ids: [],
              name: '',
            }
            testObj[ticked[i].getMetricId()].ids.push(
              ticked[i].getMetricValueIdx()
            )
            testObj[ticked[i].getMetricId()].name = ticked[i].getMetricName()
          } else {
            console.log('Check limit is 2')
          }
        } else {
          if (
            !testObj[ticked[i].getMetricId()].ids.includes(
              ticked[i].getMetricValueIdx()
            )
          )
            // testObj[ticked[i].getMetricId()].push(ticked[i].getMetricValueIdx())
            testObj[ticked[i].getMetricId()].ids.push(
              ticked[i].getMetricValueIdx()
            )
          testObj[ticked[i].getMetricId()].name = ticked[i].getMetricName()
        }
      }

      if (Object.keys(testObj).length >= 2) {
        this.getMetricItems.forEach((item: any) => {
          item.disabled = true
          for (let i in testObj) {
            if (item.value == i) {
              item.disabled = false
            }
          }
        })
      } else {
        this.getMetricItems.forEach((item: any) => {
          item.disabled = false
        })
      }
      console.log('TICKED testobj ', testObj)
      store.dispatch('setCheckedMetrics', testObj)
      store.dispatch('setCheckedMetricsNames', checkedMetricsName)
      console.log(
        'TICKED',
        testObj,
        store.getters.getCheckedMetrics,
        store.getters.getCheckedMetricsNames
      )
    },
    getFilters() {
      return []
    },

    loadMetricItems() {
      store
        .dispatch('getMetricItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getMetricItems resolve', resolve)
          },
          (reject) => {
            console.log('getMetricItems reject', reject)
          }
        )
    },

    loadMetricValueItems() {
      store
        .dispatch('getMetricValueItems', {
          filter: this.getFilters(),
        })
        .then(
          (resolve) => {
            console.log('getMetricValueItems resolve', resolve)
          },
          (reject) => {
            console.log('getMetricValueItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadMetricItems()
      this.loadMetricValueItems()
    },

    // makeDefaultMetrics() {
    //   for (let node of this.getMetricItems) {
    //     if (this.ticked.length < 8) {
    //       console.log(777777, node)
    //     }
    //   }
    // },
  },
})
