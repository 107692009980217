/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: poll_group.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as poll_group_pb from './poll_group_pb';


export class PollGroupServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreatePollGroup = new grpcWeb.MethodDescriptor(
    '/proto.PollGroupService/CreatePollGroup',
    grpcWeb.MethodType.UNARY,
    poll_group_pb.CreatePollGroupRequest,
    poll_group_pb.CreatePollGroupResponse,
    (request: poll_group_pb.CreatePollGroupRequest) => {
      return request.serializeBinary();
    },
    poll_group_pb.CreatePollGroupResponse.deserializeBinary
  );

  createPollGroup(
    request: poll_group_pb.CreatePollGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_group_pb.CreatePollGroupResponse>;

  createPollGroup(
    request: poll_group_pb.CreatePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_group_pb.CreatePollGroupResponse) => void): grpcWeb.ClientReadableStream<poll_group_pb.CreatePollGroupResponse>;

  createPollGroup(
    request: poll_group_pb.CreatePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_group_pb.CreatePollGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollGroupService/CreatePollGroup',
        request,
        metadata || {},
        this.methodDescriptorCreatePollGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollGroupService/CreatePollGroup',
    request,
    metadata || {},
    this.methodDescriptorCreatePollGroup);
  }

  methodDescriptorUpdatePollGroup = new grpcWeb.MethodDescriptor(
    '/proto.PollGroupService/UpdatePollGroup',
    grpcWeb.MethodType.UNARY,
    poll_group_pb.UpdatePollGroupRequest,
    poll_group_pb.UpdatePollGroupResponse,
    (request: poll_group_pb.UpdatePollGroupRequest) => {
      return request.serializeBinary();
    },
    poll_group_pb.UpdatePollGroupResponse.deserializeBinary
  );

  updatePollGroup(
    request: poll_group_pb.UpdatePollGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_group_pb.UpdatePollGroupResponse>;

  updatePollGroup(
    request: poll_group_pb.UpdatePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_group_pb.UpdatePollGroupResponse) => void): grpcWeb.ClientReadableStream<poll_group_pb.UpdatePollGroupResponse>;

  updatePollGroup(
    request: poll_group_pb.UpdatePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_group_pb.UpdatePollGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollGroupService/UpdatePollGroup',
        request,
        metadata || {},
        this.methodDescriptorUpdatePollGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollGroupService/UpdatePollGroup',
    request,
    metadata || {},
    this.methodDescriptorUpdatePollGroup);
  }

  methodDescriptorDeletePollGroup = new grpcWeb.MethodDescriptor(
    '/proto.PollGroupService/DeletePollGroup',
    grpcWeb.MethodType.UNARY,
    poll_group_pb.DeletePollGroupRequest,
    poll_group_pb.DeletePollGroupResponse,
    (request: poll_group_pb.DeletePollGroupRequest) => {
      return request.serializeBinary();
    },
    poll_group_pb.DeletePollGroupResponse.deserializeBinary
  );

  deletePollGroup(
    request: poll_group_pb.DeletePollGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_group_pb.DeletePollGroupResponse>;

  deletePollGroup(
    request: poll_group_pb.DeletePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_group_pb.DeletePollGroupResponse) => void): grpcWeb.ClientReadableStream<poll_group_pb.DeletePollGroupResponse>;

  deletePollGroup(
    request: poll_group_pb.DeletePollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_group_pb.DeletePollGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollGroupService/DeletePollGroup',
        request,
        metadata || {},
        this.methodDescriptorDeletePollGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollGroupService/DeletePollGroup',
    request,
    metadata || {},
    this.methodDescriptorDeletePollGroup);
  }

  methodDescriptorListPollGroup = new grpcWeb.MethodDescriptor(
    '/proto.PollGroupService/ListPollGroup',
    grpcWeb.MethodType.UNARY,
    poll_group_pb.ListPollGroupRequest,
    poll_group_pb.ListPollGroupResponse,
    (request: poll_group_pb.ListPollGroupRequest) => {
      return request.serializeBinary();
    },
    poll_group_pb.ListPollGroupResponse.deserializeBinary
  );

  listPollGroup(
    request: poll_group_pb.ListPollGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<poll_group_pb.ListPollGroupResponse>;

  listPollGroup(
    request: poll_group_pb.ListPollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poll_group_pb.ListPollGroupResponse) => void): grpcWeb.ClientReadableStream<poll_group_pb.ListPollGroupResponse>;

  listPollGroup(
    request: poll_group_pb.ListPollGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poll_group_pb.ListPollGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.PollGroupService/ListPollGroup',
        request,
        metadata || {},
        this.methodDescriptorListPollGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.PollGroupService/ListPollGroup',
    request,
    metadata || {},
    this.methodDescriptorListPollGroup);
  }

}

