import script from "./IntersectionTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./IntersectionTable.vue?vue&type=script&setup=true&lang=ts"

import "./IntersectionTable.vue?vue&type=style&index=0&id=0087aedf&lang=scss"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox,QBtn,QTooltip,QTable,QInnerLoading,QSpinner});
