import script from "./SettingsModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./SettingsModal.vue?vue&type=script&setup=true&lang=ts"

import "./SettingsModal.vue?vue&type=style&index=0&id=1d35d53c&lang=scss"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QDialog,QCard,QCardSection,QInput,QSeparator,QCheckbox,QBtn});
