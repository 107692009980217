import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e2213006"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "relative-position"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      (!_ctx.isMakeWordForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Генерация справки по опросу ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item, { class: "text-h6" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.poll.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item_label, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" По классификатору ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_item, { class: "text-h6" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.classifier.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      ref: "WordFormSettings_maxOpenRowsInput",
                      modelValue: _ctx.maxOpenRows,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.maxOpenRows) = $event)),
                      type: "number",
                      min: "1",
                      max: "99",
                      maxlength: "2",
                      label: "Максимальное количество категорий (классов) в списке открытых ответов",
                      "no-error-icon": "",
                      rules: [ val => val.length > 0 && val.length <= 2 || 'Пожалуйста, введите число от 1 до 50', val => val > 0 && val < 51]
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.makeWordChanges
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Выгрузить ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeWordForm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Отмена ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_inner_loading, {
              showing: _ctx.isGeneralPollChartBusy,
              color: "grey"
            }, null, 8, ["showing"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_DataLoading, { "msg-loading": "Загрузка данных" })
          ])),
      (_ctx.answersChartsComputed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answersChartsComputed, (answer, index) => {
              return (_openBlock(), _createBlock(_component_highcharts, {
                key: index,
                ref_for: true,
                ref: 'chart' + index,
                options: _ctx.answersChartsComputed[index],
                class: "hc"
              }, null, 8, ["options"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.openAnswersChartsComputed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openAnswersChartsComputed, (answer, index) => {
              return (_openBlock(), _createBlock(_component_highcharts, {
                key: index,
                ref_for: true,
                ref: 'open_chart' + index,
                options: _ctx.openAnswersChartsComputed[index],
                class: "hc"
              }, null, 8, ["options"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}