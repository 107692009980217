
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'UserEdit',

  components: {
    DataLoading,
  },

  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {
        id: 0,
        last_name: '',
        first_name: '',
        middle_name: '',
        email: '',
        user_role: {
          role: 'user',
          role_name: 'Пользователь',
        },
        username: '',
        password: '',
        is_active: false,
        time_active: '',
      },
    }
  },

  computed: {
    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },

    getUserRoles() {
      const roleItems = store.getters.getUserRoles
      let rows: any = []
      if (roleItems !== undefined) {
        for (let roleItem of roleItems) {
          rows.push({
            role: roleItem.getUserRole(),
            role_name: roleItem.getUserRoleName(),
          })
        }
      }
      return rows
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    saveUser() {
      const surname = this.$refs.surnameEditRef as any
      const firstname = this.$refs.firstnameEditRef as any
      const email = this.$refs.emailAuthEditRef as any
      const login = this.$refs.loginAuthEditRef as any

      surname.validate()
      firstname.validate()
      email.validate()
      login.validate()

      if (surname.hasError || firstname.hasError || email.hasError || login.hasError) {
        console.log(123)
      } else {
        let data = {
          id: this.userId,
          last_name: this.item.last_name,
          first_name: this.item.first_name,
          middle_name: this.item.middle_name,
          email: this.item.email,
          user_role: this.item.user_role.role,
          username: this.item.username,
          password: this.item.password,
          is_active: this.item.is_active,
          time_active: this.item.time_active,
        }

        store.dispatch('updateUsersItem', data).then(
            (resolve) => {
              console.log('resolve', resolve)
              this.$emit('afterAction')
            },
            (reject) => {
              console.log('reject', reject)
            }
        )

        return true
      }
    },

    closeUserEdit() {
      this.$emit('hideModal')
    },

    findRole(role: string) {
        for (let roleItem of this.getUserRoles) {
          if (role == roleItem.role) {
            return roleItem
          }
        }
        return {
          role: 'user',
          role_name: 'Пользователь',
        }
    },

    loadUserItem() {
      store
        .dispatch('getUsersListItem', {
          filter: [
            {
              field_name: 'user_id',
              operand: '=',
              value: this.userId,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('loadData resolve', resolve)
            this.item = {
              id: resolve.getUserId(),
              last_name: resolve.getLastName(),
              first_name: resolve.getFirstName(),
              middle_name: resolve.getMiddleName(),
              email: resolve.getEmail(),
              user_role: this.findRole(resolve.getUserRole()),
              username: resolve.getUsername(),
              password: resolve.getPassword(),
              is_active: resolve.getUserActive(),
              time_active: resolve.getUserActivated(),
            }
          },
          (reject) => {
            console.log('loadData reject', reject)
            this.$emit('hideModal')
          }
        )
    },

    loadData() {
      store.dispatch('getUserRoles').then(
        (resolve) => {
          console.log('resolve', resolve)
          this.loadUserItem()
        },
        (reject) => {
          console.log('reject', reject)
          this.$emit('hideModal')
        }
      )
    },

    checkSpace(e: any) {
      if (e.which === 32) {
        e.preventDefault()
      }
    },
  },
})
