
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'
import MessageFormSingle from "@/components/modules/polls/views/answers/MessageFormSingle.vue";

const defaultSimilarity = '0.6'

export default defineComponent({
  name: 'WorkingMessagePanel',

  components: {
    MessageFormSingle,
    DataLoading,
  },

  data() {
    return {
      isActiveModal: false,
      isActiveModalSplitSingle: false,
      isActiveModalSource: false,
      currentActionMessage: null as any,
      columnsWorked: [
        {
          name: 'text',
          field: 'text',
          label: 'Сообщение',
          align: 'left',
        },
        {
          name: 'sum',
          field: 'sum',
          label: 'Количество голосов',
          align: 'center',
        },
        {
          name: 'action',
          label: '',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 40px',
          style: 'width: 40px',
        },
      ],
      similarColumn: {
        name: 'similarity',
        field: 'similarity',
        label: 'Коэффициент схожести',
        align: 'center',
      },
      workedFilter: [
        {
          field_name: 'ag.message_grp_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
        {
          field_name: 'ag.message_grp_id',
          operand: '<>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      workedPagination: {
        sortBy: 'message_grp_sum',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageWorkedOptions: [10, 25, 50, 100],
      findType: { id: 0, title: 'Поиск по семантике', value: 'similar', disable: true } as any,
      findTypeOptions: [
        { id: 0, title: 'Поиск по семантике', value: 'similar', disable: true },
        { id: 1, title: 'Простой поиск', value: 'single' },
      ],
      markedFind: [] as string[],
      selectedWorked: [],
      search: '',
      similarity: defaultSimilarity as any,
    }
  },

  computed: {

    getQuestionId() {
      let currentQuestion = store.getters.getQuestionItem
      return currentQuestion ? currentQuestion.id : 0
    },

    isCurrentMessageResult() {
      return null !== store.getters.getCurrentMessageResult
    },

    getCurrentMessageResult() {
      let curMessageResult = store.getters.getCurrentMessageResult
      return curMessageResult
          ? curMessageResult
          : {
            id: 0,
            text: '',
            count: 0,
            processed: false,
            sum: 0,
            has_childs: false
          }
    },

    isMessageGrpWorkedBusy() {
      return store.getters.isMessageGrpWorkedBusy
    },

    getMessageGrpWorkedItems() {
      const messageItems = store.getters.getMessageGrpWorkedItems
      let rows: any = []
      if (messageItems !== undefined) {
        for (let messageItem of messageItems) {
          rows.push({
            id: messageItem.getMessageGrpId(),
            text: messageItem.getMessageGrpText(),
            count: messageItem.getMessageGrpCount(),
            processed: messageItem.getMessageGrpProcessed(),
            sum: messageItem.getMessageGrpSum(),
            similarity: messageItem.getSimilarity()
          })
        }
      }
      return rows
    },

    userRole() {
      return store.getters.getUserAccess
    },
  },

  created() {
    this.loadData(false, true)
  },

  methods: {

    onRowShowMessageSource(row: any, isFull: boolean) {
      this.$emit('showSource', row, isFull)
    },

    hideModal() {
      this.isActiveModal = false
      this.isActiveModalSplitSingle = false
      this.currentActionMessage = null
    },

    splitSingle() {
      this.isActiveModalSplitSingle = true
      console.log('splitSingle')
      this.isActiveModal = true
    },

    actionRow(method: any, row: any) {
      console.log('actionRow')
      console.log('method', method)
      console.log('row', row)
      this.currentActionMessage = Object.assign(row)
      switch (method) {
        case 'split':
          this.splitSingle()
          break
        default:
          console.log('Неизвестный метод')
          console.log('method', method)
      }
    },

    afterActionSplitSingle() {
      this.$emit('afterAction', this.getCurrentMessageResult.id)
      this.hideModal()
    },

    makeActionWorked() {
      if (!this.isCurrentMessageResult) {
        console.log('Не выбрано сообщение в левой панели')
        return false
      }
      if (this.selectedWorked.length < 1) {
        console.log('Для объединения ответов должен быть выбран хотя бы один ответ')
        return false
      }

      let reducedIds = this.selectedWorked.map((item: any) => item.id)

      store.dispatch('reduceMessageGrpItems', {
        id: this.getCurrentMessageResult.id,
        reduced_ids: reducedIds
      })
          .then(
              (resolve) => {
                console.log('makeActionWorked resolve', resolve)
                this.$emit('afterAction', resolve.getMessageGrpId())
              },
              (reject) => {
                console.log('makeActionWorked reject', reject)
              }
          )
      return true
    },

    onUpdateSelectedWorked(event: any) {
      console.log('onUpdateSelectedWorked event', event)
    },

    changeFindType(event: any) {
      console.log('changeFindType event', event)
      switch (event.value) {
        case 'single':
          if (this.columnsWorked.length == 4) {
            this.columnsWorked.splice(2, 1)
          }
          break
        case 'similar':
          if (this.columnsWorked.length == 3) {
            this.columnsWorked.splice(2, 0, this.similarColumn)
          }
          break
      }
      this.findMessages()
    },

    onRequestWorked(props: any) {
      console.log('onRequestWorked props', props)
      this.workedPagination = props.pagination
      this.loadWorkedMessages(false)
    },

    findMessages() {
      let extFilter: any[] = []
      this.markedFind = []

      switch (this.findType.value) {
        case 'single': {
          if (this.search.length) {
            extFilter.push({
              field_name: 'ag.message_grp_text',
              operand: 'ilike',
              value: this.search,
            })
            this.markedFind.push(this.search)
          }
          break
        }
        case 'similar': {
          console.log('findMessages - similar')

          let similarity = parseFloat(this.similarity)
          extFilter = [
            {
              field_name: 'ag.message_grp_text',
              operand: 'similar_' + similarity.toFixed(2),
              //operand: 'w_similar_' + similarity.toFixed(2),
              value: this.getCurrentMessageResult.text,
            }
          ]
          break
        }
        default: {
          console.log(
              'findMessages - неизвестный тип поиска this.findType',
              this.findType.value
          )
          this.markedFind = []
          return false
        }
      }

      this.workedFilter[0].or = extFilter
      this.loadWorkedMessages(true) // true - переключаем на первую страницу

      return true
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка непривязанных ответов
    loadWorkedMessages(isResetPagination: boolean) {
      if (isResetPagination) {
        this.workedPagination.page = 1
        this.workedPagination.rowsNumber = 0
      }
      switch (this.findType.value) {
        case 'single': {
          this.workedPagination.sortBy = 'message_grp_sum'
          this.workedPagination.descending = true
          break
        }
        case 'similar': {
          this.workedPagination.sortBy = 'similarity'
          this.workedPagination.descending = true
          break
        }
        default: {
          console.log(
              'loadWorkedMessages - неизвестный тип поиска this.findType',
              this.findType.value
          )
        }
      }
      console.log('loadWorkedMessages this.workedFilter', this.workedFilter)
      store
          .dispatch('getMessageGrpWorkedItems', {
            filter: this.workedFilter,
            sort: {
              name: this.workedPagination.sortBy,
              exp: this.workedPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.workedPagination.page,
              limit: this.workedPagination.rowsPerPage,
              pages: 0,
              cnt: this.workedPagination.rowsNumber,
            },
            question_id: this.getQuestionId,
          })
          .then(
              (resolve) => {
                console.log('getMessageGrpWorkedItems resolve', resolve)
                this.workedPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageGrpWorkedItems reject', reject)
              }
          )
    },

    loadData(isLoadMessages: boolean, isResetSimilarity: boolean) {
      this.findType = this.findTypeOptions[0]
      let findType = this.findType.value

      this.selectedWorked = []
      this.markedFind = []

      if (isResetSimilarity) {
        this.search = ''
        findType = 'similar'
        this.similarity = defaultSimilarity
      }
      this.findTypeOptions[0].disable = !this.isCurrentMessageResult
      if (('similar' == findType) && !this.isCurrentMessageResult) {
        findType = 'single'
      }
      switch (findType) {
        case 'single':
          if (this.columnsWorked.length == 4) {
            this.columnsWorked.splice(2, 1)
          }
          break
        case 'similar':
          if (this.columnsWorked.length == 3) {
            this.columnsWorked.splice(2, 0, this.similarColumn)
          }
          break
      }
      this.findType.value = findType

      if (isLoadMessages) {
        let extFilter: any[] = []
        this.workedFilter[1].value = this.getCurrentMessageResult.id

        switch (this.findType.value) {
          case 'single': {
            if (this.search.length) {
              extFilter.push({
                field_name: 'ag.message_grp_text',
                operand: 'ilike',
                value: this.search,
              })
              this.markedFind.push(this.search)
            }
            break
          }
          case 'similar': {
            console.log('findMessages - similar')
            let similarity = parseFloat(this.similarity)
            extFilter = [
              {
                field_name: 'ag.message_grp_text',
                operand: 'similar_' + similarity.toFixed(2),
                //operand: 'w_similar_' + similarity.toFixed(2),
                value: this.getCurrentMessageResult.text,
              }
            ]
            break
          }
          default: {
            console.log(
                'findMessages - неизвестный тип поиска this.findType',
                this.findType.value
            )
            this.markedFind = []
            return false
          }
        }
        this.workedFilter[0].or = extFilter
        this.loadWorkedMessages(true)
      }
    },
  },
})
