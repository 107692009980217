
import store from '@/store'
import { defineComponent } from 'vue'
import DataLoading from '@/components/DataLoading.vue'

export default defineComponent({
  name: 'ClassPanel',

  components: {
    DataLoading,
  },

  data() {
    return {
      splitterSize: 70,
    }
  },

  computed: {
    isClassBusy() {
      return store.getters.getIsClassBusy
    },

    isClassTagBusy() {
      return store.getters.getIsClassTagBusy
    },

    isSelectedNode() {
      return null !== store.getters.getCurrentNode
    },

    node() {
      let currentNode = store.getters.getCurrentNode
      return currentNode
        ? currentNode
        : {
            id: 0,
            cls_id: 0,
            parent_id: 0,
            idx: 0,
            name: '',
            group_name: '',
            color: '',
            descr: '',
            notes: '',
            parentName: '',
            ratio: 0,
          }
    },

    classTagItems() {
      return store.getters.getClassTagItems
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {},
  },
})
