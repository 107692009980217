import {
  UserServiceClient
} from "../../proto/pb/UserServiceClientPb";
import {
  User,
  UserRole,
  ListUserRequest,
  CreateUserRequest,
  UpdateUserRequest,
  DeleteUserRequest,
  ListUserRolesRequest,
  UserFilter,
  UserFilterItem,
  UserPagination,
  UserSort,
  AccessRequest,
  RegisterConfirmRequest,
  ResendRegisterRequest,
  CheckActivatedTokenRequest,

} from "../../proto/pb/user_pb";
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";
import { AuthApi } from "@/components/modules/users/services/api/AuthApi";

// Количество на странице по-умолчанию
const defaultLimit: number = 100;

export class UsersApi {
  items: Array<User> = [];
  client: UserServiceClient;
  metadata: any;
  authApi: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new UserServiceClient(host, null, { unaryInterceptors: [new RefreshTokenInterceptor()] });
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
    this.authApi = new AuthApi()
  }

  // Установка и получение фильтров
  createFilter(data: any) {

    const filter = new UserFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name || data[i].fieldName) {
          const fItem = new UserFilterItem();
          fItem.setFieldName(data[i]?.field_name?.toString() || data[i]?.fieldName?.toString());
          fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
          fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
          fItem.setOr(this.createFilter(data[i].or?.itemsList || data[i]?.or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {

    const sort = new UserSort();
    const name: string = data.name !== undefined ? data.name.toString() : 'user_id';
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {

    const pagination = new UserPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getUsersListItems(filter: UserFilterItem.AsObject[], sort: any, pagination: any) {
    const request = new ListUserRequest();


    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));
    console.log('REQ USERS', request.toObject())

    return this.client.listUser(request, this.metadata);
  }

  // Получение элемента
  getUsersListItem(filter: any) {

    const request = new ListUserRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listUser(request, this.metadata)
      .then((message) => {
        const itemList = message.getItemsList()

        if (itemList && (itemList.length > 0)) {
          return itemList[0]
        }
      })
  }

  // Создание элемента
  createUsersItem(data: any) {
    const request = new CreateUserRequest();
    request.setFirstName(data.first_name);
    request.setLastName(data.last_name);
    request.setMiddleName(data.middle_name);
    request.setEmail(data.email);
    request.setUserRole(data.user_role);
    request.setUsername(data.username);

    console.log(data, data.username);

    return this.client.createUser(request, this.metadata);
  }

  // Редактировать элемент
  updateUsersItem(data: any) {

    const request = new UpdateUserRequest();
    request.setUserId(data.id);
    request.setFirstName(data.first_name);
    request.setLastName(data.last_name);
    request.setMiddleName(data.middle_name);
    request.setEmail(data.email);
    request.setUserRole(data.user_role);
    request.setUsername(data.username);
    request.setPassword(data.password);
    request.setUserActive(data.is_active)
    request.setUserActivated(data.time_active)

    console.log(data, data.username);

    return this.client.updateUser(request, this.metadata);
  }

  // Удаление элемента
  deleteUsersItem(data: any) {

    const request = new DeleteUserRequest();
    request.setUserId(data.id);

    return this.client.deleteUser(request, this.metadata);
  }

  // Список ролей
  getListUserRoles() {

    const request = new ListUserRolesRequest();

    return this.client.listUserRoles(request, this.metadata);
  }

  checkUserRole() {
    const request = new AccessRequest()

    return this.client.access(request, this.metadata)
  }

  setupPassword(data: any) {
    const request = new RegisterConfirmRequest()

    request
      .setPassword(data.password)
      .setPasswordConfirmation(data.repassword)
      .setToken(data.token)

    return this.client.registerConfirm(request, this.metadata)
  }

  resendMail(data: any) {
    const request = new ResendRegisterRequest()

    request
      .setUserId(data.id)

    return this.client.resendRegister(request, this.metadata)
  }

  checkToken(data: any) {
    const request = new CheckActivatedTokenRequest()

    request
      .setToken(data.token)

    console.log(request)

    return this.client.checkActivatedToken(request, this.metadata)
  }
}
