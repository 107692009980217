import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Создание значения ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.item.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.title) = $event)),
                label: "Наименование",
                maxlength: "1024"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.item.idx,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.idx) = $event)),
                type: "number",
                min: "1",
                label: "Порядок в списке значений"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.item.points,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.points) = $event)),
                type: "number",
                label: "Балл"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.item.check_rule,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.check_rule) = $event)),
                type: "textarea",
                label: "Правила проверки"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, { align: "right" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.saveValue
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Сохранить ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.closeValueAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отмена ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}