/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: accounts.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as accounts_pb from './accounts_pb';


export class AccountsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAccounts = new grpcWeb.MethodDescriptor(
    '/proto.AccountsService/ListAccounts',
    grpcWeb.MethodType.UNARY,
    accounts_pb.ListAccountsRequest,
    accounts_pb.ListAccountsResponse,
    (request: accounts_pb.ListAccountsRequest) => {
      return request.serializeBinary();
    },
    accounts_pb.ListAccountsResponse.deserializeBinary
  );

  listAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_pb.ListAccountsResponse>;

  listAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_pb.ListAccountsResponse) => void): grpcWeb.ClientReadableStream<accounts_pb.ListAccountsResponse>;

  listAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_pb.ListAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountsService/ListAccounts',
        request,
        metadata || {},
        this.methodDescriptorListAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountsService/ListAccounts',
    request,
    metadata || {},
    this.methodDescriptorListAccounts);
  }

  methodDescriptorItemAccounts = new grpcWeb.MethodDescriptor(
    '/proto.AccountsService/ItemAccounts',
    grpcWeb.MethodType.UNARY,
    accounts_pb.ItemAccountsRequest,
    accounts_pb.ItemAccountsResponse,
    (request: accounts_pb.ItemAccountsRequest) => {
      return request.serializeBinary();
    },
    accounts_pb.ItemAccountsResponse.deserializeBinary
  );

  itemAccounts(
    request: accounts_pb.ItemAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_pb.ItemAccountsResponse>;

  itemAccounts(
    request: accounts_pb.ItemAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_pb.ItemAccountsResponse) => void): grpcWeb.ClientReadableStream<accounts_pb.ItemAccountsResponse>;

  itemAccounts(
    request: accounts_pb.ItemAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_pb.ItemAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountsService/ItemAccounts',
        request,
        metadata || {},
        this.methodDescriptorItemAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountsService/ItemAccounts',
    request,
    metadata || {},
    this.methodDescriptorItemAccounts);
  }

  methodDescriptorListSearchAccounts = new grpcWeb.MethodDescriptor(
    '/proto.AccountsService/ListSearchAccounts',
    grpcWeb.MethodType.UNARY,
    accounts_pb.ListAccountsRequest,
    accounts_pb.ListAccountsResponse,
    (request: accounts_pb.ListAccountsRequest) => {
      return request.serializeBinary();
    },
    accounts_pb.ListAccountsResponse.deserializeBinary
  );

  listSearchAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_pb.ListAccountsResponse>;

  listSearchAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_pb.ListAccountsResponse) => void): grpcWeb.ClientReadableStream<accounts_pb.ListAccountsResponse>;

  listSearchAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_pb.ListAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountsService/ListSearchAccounts',
        request,
        metadata || {},
        this.methodDescriptorListSearchAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountsService/ListSearchAccounts',
    request,
    metadata || {},
    this.methodDescriptorListSearchAccounts);
  }

  methodDescriptorLoadPersonalListFromFile = new grpcWeb.MethodDescriptor(
    '/proto.AccountsService/LoadPersonalListFromFile',
    grpcWeb.MethodType.UNARY,
    accounts_pb.LoadPersonalListFromFileRequest,
    accounts_pb.LoadPersonalListFromFileResponse,
    (request: accounts_pb.LoadPersonalListFromFileRequest) => {
      return request.serializeBinary();
    },
    accounts_pb.LoadPersonalListFromFileResponse.deserializeBinary
  );

  loadPersonalListFromFile(
    request: accounts_pb.LoadPersonalListFromFileRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_pb.LoadPersonalListFromFileResponse>;

  loadPersonalListFromFile(
    request: accounts_pb.LoadPersonalListFromFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_pb.LoadPersonalListFromFileResponse) => void): grpcWeb.ClientReadableStream<accounts_pb.LoadPersonalListFromFileResponse>;

  loadPersonalListFromFile(
    request: accounts_pb.LoadPersonalListFromFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_pb.LoadPersonalListFromFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountsService/LoadPersonalListFromFile',
        request,
        metadata || {},
        this.methodDescriptorLoadPersonalListFromFile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountsService/LoadPersonalListFromFile',
    request,
    metadata || {},
    this.methodDescriptorLoadPersonalListFromFile);
  }

  methodDescriptorExportListAccounts = new grpcWeb.MethodDescriptor(
    '/proto.AccountsService/ExportListAccounts',
    grpcWeb.MethodType.UNARY,
    accounts_pb.ListAccountsRequest,
    accounts_pb.ExportListAccountsResponse,
    (request: accounts_pb.ListAccountsRequest) => {
      return request.serializeBinary();
    },
    accounts_pb.ExportListAccountsResponse.deserializeBinary
  );

  exportListAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_pb.ExportListAccountsResponse>;

  exportListAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_pb.ExportListAccountsResponse) => void): grpcWeb.ClientReadableStream<accounts_pb.ExportListAccountsResponse>;

  exportListAccounts(
    request: accounts_pb.ListAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_pb.ExportListAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountsService/ExportListAccounts',
        request,
        metadata || {},
        this.methodDescriptorExportListAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountsService/ExportListAccounts',
    request,
    metadata || {},
    this.methodDescriptorExportListAccounts);
  }

}

