<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pb-md">
      Оценка пользователей
    </div>
    <EstimationList />
  </div>
</template>

<script>

import EstimationList from "@/components/EstimationList.vue";

export default {
    name: "EstimationUser",
    components: {EstimationList},
}
</script>

<style scoped>

</style>