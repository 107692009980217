
import { defineComponent } from "vue";
import MenuElement from "./modules/user-digital-project/components/Design/MenuElement.vue";

export default defineComponent({
  name: "ReferenceList",

  components: {
    MenuElement,
  },

  data() {
    return {
      addonList: [
        {
          title: "Справочник проектов",
          url: "reference/project-directory",
        },
        // {
        //   title: 'Справочник параметров настройки модели',
        //   url: 'reference/set-model',
        // },
        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },
});
