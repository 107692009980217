import script from "./ReportList.vue?vue&type=script&setup=true&lang=ts"
export * from "./ReportList.vue?vue&type=script&setup=true&lang=ts"

import "./ReportList.vue?vue&type=style&index=0&id=9ab44844&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QCardActions,QBtn});
