import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "q-pa-md col-12 items-start q-gutter-md" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "self-center full-width no-outline" }
const _hoisted_5 = { class: "self-center full-width no-outline" }
const _hoisted_6 = { class: "self-center full-width no-outline" }
const _hoisted_7 = { class: "self-center full-width no-outline" }
const _hoisted_8 = { class: "self-center full-width no-outline row items-center" }
const _hoisted_9 = { class: "self-center full-width no-outline" }
const _hoisted_10 = { class: "self-center full-width no-outline" }
const _hoisted_11 = {
  key: 0,
  class: "col-12"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_splitter = _resolveComponent("q-splitter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { class: "text-h5" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.node.name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_splitter, {
              modelValue: _ctx.splitterSize,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.splitterSize) = $event))
            }, {
              before: _withCtx(() => [
                _createVNode(_component_q_card, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      ('' !== _ctx.node.parentName)
                        ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_field, {
                                "model-value": _ctx.node.parent_id,
                                label: "Родительский класс"
                              }, {
                                control: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.node.parentName), 1)
                                ]),
                                _: 1
                              }, 8, ["model-value"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.name,
                            label: "Наименование"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.node.name), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.ratio,
                            label: "Влияние на лояльность"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.node.ratio), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.group_name,
                            label: "Обозначение группы"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.node.group_name), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.color,
                            label: "Цвет обозначения группы"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", {
                                  style: _normalizeStyle([{"width":"21px","height":"21px"}, 'background:' + _ctx.node.color]),
                                  class: "q-ml-sm"
                                }, null, 4)
                              ])
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.descr,
                            label: "Описание"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.node.descr), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.notes,
                            label: "Примечания"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.node.notes), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              after: _withCtx(() => [
                _createVNode(_component_q_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_label, { class: "text-h6 text-center" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Теги ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_separator),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        (!_ctx.isClassTagBusy)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classTagItems, (item, index) => {
                                return (_openBlock(), _createBlock(_component_q_chip, { key: index }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.getTagName()) + " ", 1),
                                    _createVNode(_component_q_tooltip, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.getTagDescr()), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(_component_DataLoading, { "msg-loading": "Загрузка тэгов" })
                            ]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ], 512), [
          [_vShow, _ctx.isSelectedNode]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_13, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Не выбран узел дерева ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, !_ctx.isSelectedNode]
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isClassBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading)
    ], 512), [
      [_vShow, _ctx.isClassBusy]
    ])
  ], 64))
}