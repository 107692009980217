/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: answer_grp.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as answer_grp_pb from './answer_grp_pb';


export class AnswerGrpServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/CreateAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.CreateAnswerGrpRequest,
    answer_grp_pb.CreateAnswerGrpResponse,
    (request: answer_grp_pb.CreateAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.CreateAnswerGrpResponse.deserializeBinary
  );

  createAnswerGrp(
    request: answer_grp_pb.CreateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.CreateAnswerGrpResponse>;

  createAnswerGrp(
    request: answer_grp_pb.CreateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.CreateAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.CreateAnswerGrpResponse>;

  createAnswerGrp(
    request: answer_grp_pb.CreateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.CreateAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/CreateAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorCreateAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/CreateAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorCreateAnswerGrp);
  }

  methodDescriptorUpdateAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/UpdateAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.UpdateAnswerGrpRequest,
    answer_grp_pb.UpdateAnswerGrpResponse,
    (request: answer_grp_pb.UpdateAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.UpdateAnswerGrpResponse.deserializeBinary
  );

  updateAnswerGrp(
    request: answer_grp_pb.UpdateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.UpdateAnswerGrpResponse>;

  updateAnswerGrp(
    request: answer_grp_pb.UpdateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.UpdateAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.UpdateAnswerGrpResponse>;

  updateAnswerGrp(
    request: answer_grp_pb.UpdateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.UpdateAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/UpdateAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorUpdateAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/UpdateAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorUpdateAnswerGrp);
  }

  methodDescriptorUpdateTextAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/UpdateTextAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.UpdateTextAnswerGrpRequest,
    answer_grp_pb.UpdateTextAnswerGrpResponse,
    (request: answer_grp_pb.UpdateTextAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.UpdateTextAnswerGrpResponse.deserializeBinary
  );

  updateTextAnswerGrp(
    request: answer_grp_pb.UpdateTextAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.UpdateTextAnswerGrpResponse>;

  updateTextAnswerGrp(
    request: answer_grp_pb.UpdateTextAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.UpdateTextAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.UpdateTextAnswerGrpResponse>;

  updateTextAnswerGrp(
    request: answer_grp_pb.UpdateTextAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.UpdateTextAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/UpdateTextAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorUpdateTextAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/UpdateTextAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorUpdateTextAnswerGrp);
  }

  methodDescriptorDeleteAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/DeleteAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.DeleteAnswerGrpRequest,
    answer_grp_pb.DeleteAnswerGrpResponse,
    (request: answer_grp_pb.DeleteAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.DeleteAnswerGrpResponse.deserializeBinary
  );

  deleteAnswerGrp(
    request: answer_grp_pb.DeleteAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.DeleteAnswerGrpResponse>;

  deleteAnswerGrp(
    request: answer_grp_pb.DeleteAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.DeleteAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.DeleteAnswerGrpResponse>;

  deleteAnswerGrp(
    request: answer_grp_pb.DeleteAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.DeleteAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/DeleteAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorDeleteAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/DeleteAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorDeleteAnswerGrp);
  }

  methodDescriptorListAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/ListAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.ListAnswerGrpRequest,
    answer_grp_pb.ListAnswerGrpResponse,
    (request: answer_grp_pb.ListAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.ListAnswerGrpResponse.deserializeBinary
  );

  listAnswerGrp(
    request: answer_grp_pb.ListAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.ListAnswerGrpResponse>;

  listAnswerGrp(
    request: answer_grp_pb.ListAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.ListAnswerGrpResponse>;

  listAnswerGrp(
    request: answer_grp_pb.ListAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/ListAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorListAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/ListAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorListAnswerGrp);
  }

  methodDescriptorListAnswerGrpWithFindAnswer = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/ListAnswerGrpWithFindAnswer',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.ListAnswerGrpWithFindAnswerRequest,
    answer_grp_pb.ListAnswerGrpWithFindAnswerResponse,
    (request: answer_grp_pb.ListAnswerGrpWithFindAnswerRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.ListAnswerGrpWithFindAnswerResponse.deserializeBinary
  );

  listAnswerGrpWithFindAnswer(
    request: answer_grp_pb.ListAnswerGrpWithFindAnswerRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.ListAnswerGrpWithFindAnswerResponse>;

  listAnswerGrpWithFindAnswer(
    request: answer_grp_pb.ListAnswerGrpWithFindAnswerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpWithFindAnswerResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.ListAnswerGrpWithFindAnswerResponse>;

  listAnswerGrpWithFindAnswer(
    request: answer_grp_pb.ListAnswerGrpWithFindAnswerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpWithFindAnswerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/ListAnswerGrpWithFindAnswer',
        request,
        metadata || {},
        this.methodDescriptorListAnswerGrpWithFindAnswer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/ListAnswerGrpWithFindAnswer',
    request,
    metadata || {},
    this.methodDescriptorListAnswerGrpWithFindAnswer);
  }

  methodDescriptorListAnswerGrpChilds = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/ListAnswerGrpChilds',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.ListAnswerGrpChildsRequest,
    answer_grp_pb.ListAnswerGrpChildsResponse,
    (request: answer_grp_pb.ListAnswerGrpChildsRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.ListAnswerGrpChildsResponse.deserializeBinary
  );

  listAnswerGrpChilds(
    request: answer_grp_pb.ListAnswerGrpChildsRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.ListAnswerGrpChildsResponse>;

  listAnswerGrpChilds(
    request: answer_grp_pb.ListAnswerGrpChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpChildsResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.ListAnswerGrpChildsResponse>;

  listAnswerGrpChilds(
    request: answer_grp_pb.ListAnswerGrpChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ListAnswerGrpChildsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/ListAnswerGrpChilds',
        request,
        metadata || {},
        this.methodDescriptorListAnswerGrpChilds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/ListAnswerGrpChilds',
    request,
    metadata || {},
    this.methodDescriptorListAnswerGrpChilds);
  }

  methodDescriptorReduceAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/ReduceAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.ReduceAnswerGrpRequest,
    answer_grp_pb.ReduceAnswerGrpResponse,
    (request: answer_grp_pb.ReduceAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.ReduceAnswerGrpResponse.deserializeBinary
  );

  reduceAnswerGrp(
    request: answer_grp_pb.ReduceAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.ReduceAnswerGrpResponse>;

  reduceAnswerGrp(
    request: answer_grp_pb.ReduceAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ReduceAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.ReduceAnswerGrpResponse>;

  reduceAnswerGrp(
    request: answer_grp_pb.ReduceAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.ReduceAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/ReduceAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorReduceAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/ReduceAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorReduceAnswerGrp);
  }

  methodDescriptorSplitAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/SplitAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.SplitAnswerGrpRequest,
    answer_grp_pb.SplitAnswerGrpResponse,
    (request: answer_grp_pb.SplitAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.SplitAnswerGrpResponse.deserializeBinary
  );

  splitAnswerGrp(
    request: answer_grp_pb.SplitAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.SplitAnswerGrpResponse>;

  splitAnswerGrp(
    request: answer_grp_pb.SplitAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.SplitAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.SplitAnswerGrpResponse>;

  splitAnswerGrp(
    request: answer_grp_pb.SplitAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.SplitAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/SplitAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorSplitAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/SplitAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorSplitAnswerGrp);
  }

  methodDescriptorSeparateAnswerGrp = new grpcWeb.MethodDescriptor(
    '/proto.AnswerGrpService/SeparateAnswerGrp',
    grpcWeb.MethodType.UNARY,
    answer_grp_pb.SeparateAnswerGrpRequest,
    answer_grp_pb.SeparateAnswerGrpResponse,
    (request: answer_grp_pb.SeparateAnswerGrpRequest) => {
      return request.serializeBinary();
    },
    answer_grp_pb.SeparateAnswerGrpResponse.deserializeBinary
  );

  separateAnswerGrp(
    request: answer_grp_pb.SeparateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null): Promise<answer_grp_pb.SeparateAnswerGrpResponse>;

  separateAnswerGrp(
    request: answer_grp_pb.SeparateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_grp_pb.SeparateAnswerGrpResponse) => void): grpcWeb.ClientReadableStream<answer_grp_pb.SeparateAnswerGrpResponse>;

  separateAnswerGrp(
    request: answer_grp_pb.SeparateAnswerGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_grp_pb.SeparateAnswerGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerGrpService/SeparateAnswerGrp',
        request,
        metadata || {},
        this.methodDescriptorSeparateAnswerGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerGrpService/SeparateAnswerGrp',
    request,
    metadata || {},
    this.methodDescriptorSeparateAnswerGrp);
  }

}

