
import { defineComponent } from "vue";
import store from "@/store";
import MenuElement from "./modules/user-digital-project/components/Design/MenuElement.vue";

export default defineComponent({
  name: "RPList",

  components: {
    MenuElement,
  },

  data() {
    return {
      addonList: [
        {
          title: "Обобщение",
          url: "/cls/ag_polls/generalizing",
          access: "admin editor user",
        },
        {
          title: "Классификация",
          url: "/cls/ag_polls/answers",
          access: "admin editor user",
        },
        {
          title: "Загрузка (обновление) данных",
          url: "/cls/ag_polls/load_polls",
          access: "admin editor",
        },
        // {
        //     title: 'Кластеризации',
        //     url: 'cls/clustering',
        // },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },

  computed: {
    userRole() {
      return store.getters.getUserAccess;
    },
  },
});
