import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e6589d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-black checkbox-label" }
const _hoisted_2 = { class: "text-black checkbox-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        class: "col-12 q-pa-md q-mb-md"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_option_group, {
            modelValue: _ctx.checkedProjects,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedProjects) = $event)),
            options: _ctx.getProjectsItems,
            color: "secondary",
            type: "checkbox",
            inline: _ctx.isGroupInline
          }, _createSlots({
            label: _withCtx((opt) => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(opt.label), 1)
            ]),
            _: 2
          }, [
            _renderList(_ctx.tooltips, (item) => {
              return {
                name: item,
                fn: _withCtx((opt) => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(opt.label), 1),
                  _createVNode(_component_q_tooltip, {
                    class: "bg-secondary",
                    offset: [0, 0]
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Максимально 3 проекта ")
                    ]),
                    _: 1
                  })
                ])
              }
            })
          ]), 1032, ["modelValue", "options", "inline"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}