import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/recommend-system/preparing-difficult-tasks',
    name: 'PrepDifficultTasks',
    component: () => import('../views/PrepDifficultTasks.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сервис подготовки сложных заданий' }
      ],
    },
  },
  {
    path: '/recommend-system/user-groups-scenarios',
    name: 'UserGroupScenarios',
    component: () => import('../views/UserGroupScenarios.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сценарии для групп пользователей' }
      ],
    },
  },
  {
    path: '/recommend-system/nsi',
    name: 'RecSystemNsi',
    component: () => import('../views/RecSystemNsi.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'НСИ рекомендательной системы' }
      ],
    },
  },
  {
    path: '/recommend-system/nsi/parameters',
    name: 'RecSystemNsiParameters',
    component: () => import('../views/AdminUserGroup.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'НСИ рекомендательной системы', to: { name: 'RecSystemNsi' } },
        { text: 'Параметры рекомендательной системы' }
      ],
    },
  },
  {
    path: '/recommend-system/preview-blocks',
    name: 'RecSystemPreview',
    component: () => import('../views/PreviewBlocksHierarchy.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Предпросмотр иерархии блоков' }
      ],
    },
  },

]

export default {
  routes
}
