/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: tags.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as tags_pb from './tags_pb';


export class TagsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateTags = new grpcWeb.MethodDescriptor(
    '/proto.TagsService/CreateTags',
    grpcWeb.MethodType.UNARY,
    tags_pb.CreateTagsRequest,
    tags_pb.CreateTagsResponse,
    (request: tags_pb.CreateTagsRequest) => {
      return request.serializeBinary();
    },
    tags_pb.CreateTagsResponse.deserializeBinary
  );

  createTags(
    request: tags_pb.CreateTagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<tags_pb.CreateTagsResponse>;

  createTags(
    request: tags_pb.CreateTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tags_pb.CreateTagsResponse) => void): grpcWeb.ClientReadableStream<tags_pb.CreateTagsResponse>;

  createTags(
    request: tags_pb.CreateTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tags_pb.CreateTagsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TagsService/CreateTags',
        request,
        metadata || {},
        this.methodDescriptorCreateTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TagsService/CreateTags',
    request,
    metadata || {},
    this.methodDescriptorCreateTags);
  }

  methodDescriptorDeleteTags = new grpcWeb.MethodDescriptor(
    '/proto.TagsService/DeleteTags',
    grpcWeb.MethodType.UNARY,
    tags_pb.DeleteTagsRequest,
    tags_pb.DeleteTagsResponse,
    (request: tags_pb.DeleteTagsRequest) => {
      return request.serializeBinary();
    },
    tags_pb.DeleteTagsResponse.deserializeBinary
  );

  deleteTags(
    request: tags_pb.DeleteTagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<tags_pb.DeleteTagsResponse>;

  deleteTags(
    request: tags_pb.DeleteTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tags_pb.DeleteTagsResponse) => void): grpcWeb.ClientReadableStream<tags_pb.DeleteTagsResponse>;

  deleteTags(
    request: tags_pb.DeleteTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tags_pb.DeleteTagsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TagsService/DeleteTags',
        request,
        metadata || {},
        this.methodDescriptorDeleteTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TagsService/DeleteTags',
    request,
    metadata || {},
    this.methodDescriptorDeleteTags);
  }

  methodDescriptorUpdateTags = new grpcWeb.MethodDescriptor(
    '/proto.TagsService/UpdateTags',
    grpcWeb.MethodType.UNARY,
    tags_pb.UpdateTagsRequest,
    tags_pb.UpdateTagsResponse,
    (request: tags_pb.UpdateTagsRequest) => {
      return request.serializeBinary();
    },
    tags_pb.UpdateTagsResponse.deserializeBinary
  );

  updateTags(
    request: tags_pb.UpdateTagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<tags_pb.UpdateTagsResponse>;

  updateTags(
    request: tags_pb.UpdateTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tags_pb.UpdateTagsResponse) => void): grpcWeb.ClientReadableStream<tags_pb.UpdateTagsResponse>;

  updateTags(
    request: tags_pb.UpdateTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tags_pb.UpdateTagsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TagsService/UpdateTags',
        request,
        metadata || {},
        this.methodDescriptorUpdateTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TagsService/UpdateTags',
    request,
    metadata || {},
    this.methodDescriptorUpdateTags);
  }

  methodDescriptorListTags = new grpcWeb.MethodDescriptor(
    '/proto.TagsService/ListTags',
    grpcWeb.MethodType.UNARY,
    tags_pb.ListTagsRequest,
    tags_pb.ListTagsResponse,
    (request: tags_pb.ListTagsRequest) => {
      return request.serializeBinary();
    },
    tags_pb.ListTagsResponse.deserializeBinary
  );

  listTags(
    request: tags_pb.ListTagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<tags_pb.ListTagsResponse>;

  listTags(
    request: tags_pb.ListTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tags_pb.ListTagsResponse) => void): grpcWeb.ClientReadableStream<tags_pb.ListTagsResponse>;

  listTags(
    request: tags_pb.ListTagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tags_pb.ListTagsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.TagsService/ListTags',
        request,
        metadata || {},
        this.methodDescriptorListTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.TagsService/ListTags',
    request,
    metadata || {},
    this.methodDescriptorListTags);
  }

}

