/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: classifier.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as classifier_pb from './classifier_pb';


export class ClassifierServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateClassifier = new grpcWeb.MethodDescriptor(
    '/proto.ClassifierService/CreateClassifier',
    grpcWeb.MethodType.UNARY,
    classifier_pb.CreateClassifierRequest,
    classifier_pb.CreateClassifierResponse,
    (request: classifier_pb.CreateClassifierRequest) => {
      return request.serializeBinary();
    },
    classifier_pb.CreateClassifierResponse.deserializeBinary
  );

  createClassifier(
    request: classifier_pb.CreateClassifierRequest,
    metadata: grpcWeb.Metadata | null): Promise<classifier_pb.CreateClassifierResponse>;

  createClassifier(
    request: classifier_pb.CreateClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classifier_pb.CreateClassifierResponse) => void): grpcWeb.ClientReadableStream<classifier_pb.CreateClassifierResponse>;

  createClassifier(
    request: classifier_pb.CreateClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classifier_pb.CreateClassifierResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassifierService/CreateClassifier',
        request,
        metadata || {},
        this.methodDescriptorCreateClassifier,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassifierService/CreateClassifier',
    request,
    metadata || {},
    this.methodDescriptorCreateClassifier);
  }

  methodDescriptorUpdateClassifier = new grpcWeb.MethodDescriptor(
    '/proto.ClassifierService/UpdateClassifier',
    grpcWeb.MethodType.UNARY,
    classifier_pb.UpdateClassifierRequest,
    classifier_pb.UpdateClassifierResponse,
    (request: classifier_pb.UpdateClassifierRequest) => {
      return request.serializeBinary();
    },
    classifier_pb.UpdateClassifierResponse.deserializeBinary
  );

  updateClassifier(
    request: classifier_pb.UpdateClassifierRequest,
    metadata: grpcWeb.Metadata | null): Promise<classifier_pb.UpdateClassifierResponse>;

  updateClassifier(
    request: classifier_pb.UpdateClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classifier_pb.UpdateClassifierResponse) => void): grpcWeb.ClientReadableStream<classifier_pb.UpdateClassifierResponse>;

  updateClassifier(
    request: classifier_pb.UpdateClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classifier_pb.UpdateClassifierResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassifierService/UpdateClassifier',
        request,
        metadata || {},
        this.methodDescriptorUpdateClassifier,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassifierService/UpdateClassifier',
    request,
    metadata || {},
    this.methodDescriptorUpdateClassifier);
  }

  methodDescriptorDeleteClassifier = new grpcWeb.MethodDescriptor(
    '/proto.ClassifierService/DeleteClassifier',
    grpcWeb.MethodType.UNARY,
    classifier_pb.DeleteClassifierRequest,
    classifier_pb.DeleteClassifierResponse,
    (request: classifier_pb.DeleteClassifierRequest) => {
      return request.serializeBinary();
    },
    classifier_pb.DeleteClassifierResponse.deserializeBinary
  );

  deleteClassifier(
    request: classifier_pb.DeleteClassifierRequest,
    metadata: grpcWeb.Metadata | null): Promise<classifier_pb.DeleteClassifierResponse>;

  deleteClassifier(
    request: classifier_pb.DeleteClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classifier_pb.DeleteClassifierResponse) => void): grpcWeb.ClientReadableStream<classifier_pb.DeleteClassifierResponse>;

  deleteClassifier(
    request: classifier_pb.DeleteClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classifier_pb.DeleteClassifierResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassifierService/DeleteClassifier',
        request,
        metadata || {},
        this.methodDescriptorDeleteClassifier,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassifierService/DeleteClassifier',
    request,
    metadata || {},
    this.methodDescriptorDeleteClassifier);
  }

  methodDescriptorListClassifier = new grpcWeb.MethodDescriptor(
    '/proto.ClassifierService/ListClassifier',
    grpcWeb.MethodType.UNARY,
    classifier_pb.ListClassifierRequest,
    classifier_pb.ListClassifierResponse,
    (request: classifier_pb.ListClassifierRequest) => {
      return request.serializeBinary();
    },
    classifier_pb.ListClassifierResponse.deserializeBinary
  );

  listClassifier(
    request: classifier_pb.ListClassifierRequest,
    metadata: grpcWeb.Metadata | null): Promise<classifier_pb.ListClassifierResponse>;

  listClassifier(
    request: classifier_pb.ListClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classifier_pb.ListClassifierResponse) => void): grpcWeb.ClientReadableStream<classifier_pb.ListClassifierResponse>;

  listClassifier(
    request: classifier_pb.ListClassifierRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classifier_pb.ListClassifierResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ClassifierService/ListClassifier',
        request,
        metadata || {},
        this.methodDescriptorListClassifier,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ClassifierService/ListClassifier',
    request,
    metadata || {},
    this.methodDescriptorListClassifier);
  }

}

