
import { defineComponent } from "vue";
import MenuElement from "./components/modules/user-digital-project/components/Design/MenuElement.vue";

export default defineComponent({
  name: "RPGeneralList",

  components: {
    MenuElement,
  },

  data() {
    return {
      addonList: [
        {
          title: "Обобщение",
          url: "/cls/general/generalizing",
        },
        {
          title: "Классификация",
          url: "/cls/general/answers",
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },
});
