
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TagAdd',

  data() {
    return {
      item: {
        name: '',
        descr: '',
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    saveTag() {
      if (!this.item.name) {
        console.log('Наименование тэга не введено')
        return false
      }

      this.$emit('afterAdd', this.item)
    },

    closeTagAdd() {
      this.$emit('hideModal')
    },

    loadData() {
      this.item = {
        name: '',
        descr: '',
      }
    },
  },
})
