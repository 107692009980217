
import store from "@/store"
import { defineComponent } from "vue"
import DataLoading from "@/components/DataLoading.vue"

const parentRootText = "Нет (это основное слово)"

export default defineComponent({
  name: "WordsAdd",

  components: {
    DataLoading,
  },

  data() {
    return {
      item: {
        parent_id: -1,
        parentText: parentRootText,
        text: "",
        is_linked: true,
      },
      isActiveModal: false,
      isActiveModalTree: false,
      selectedNode: null as any,
      selectedText: "",
    }
  },

  computed: {
    isWordsBusy() {
      return store.getters.getIsWordsBusy
    },

    isWordsTreeFormBusy() {
      return store.getters.getIsWordsTreeFormBusy
    },

    getWordsTreeFormItems() {
      let nodes = store.getters.getWordsTreeFormItems
      return nodes ? nodes : []
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalTree = false
    },

    changeParent() {
      console.log("changeParent")
      this.isActiveModalTree = true
      this.isActiveModal = true

      store
        .dispatch("getWordsTreeFormItems", {
          filter: [],
          root: {
            id: -1,
            text: parentRootText,
            is_linked: true,
          },
          disabled: [],
        })
        .then(
          (resolve) => {
            console.log("getWordsTreeFormItems resolve", resolve)
            this.setTreeNode(this.item.parent_id)
          },
          (reject) => {
            console.log("getWordsTreeFormItems reject", reject)
          }
        )
    },

    selectNode() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.wordsParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode !== null) {
          this.selectedText = curNode.text
        } else {
          this.selectedText = ""
        }
      } else {
        this.selectedText = ""
      }
    },

    setTreeNode(setNode: any) {
      if (setNode !== null) {
        console.log("WordAdd setTreeNode setNode", setNode)
        this.selectedNode = setNode
        const tree: any = this.$refs.wordsParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        console.log('curNode', curNode)
        if (curNode !== null) {
          this.selectedText = curNode.text
          while (curNode && curNode.parent_id !== undefined) {
            curNode = JSON.parse(
              JSON.stringify(tree.getNodeByKey(curNode.parent_id))
            )
            if (curNode) {
              tree.setExpanded(curNode.id, true)
            }
          }
        }
      }
    },

    selectParent() {
      if (this.selectedNode !== null) {
        const tree: any = this.$refs.wordsParentTree
        let curNode: any =
          tree !== undefined
            ? JSON.parse(JSON.stringify(tree.getNodeByKey(this.selectedNode)))
            : null
        if (curNode) {
          this.item.parent_id = curNode.id
          this.item.parentText = curNode.text
          this.item.is_linked = curNode.is_linked
          this.hideModal()
        }
      }
    },

    saveWord() {
      if (!this.item.text) {
        console.log("Текст слова не введен")
        return false
      }

      let data = {
        parent_id: (this.item.parent_id == -1) ? 0 : this.item.parent_id,
        text: this.item.text,
        is_linked: this.item.is_linked,
      }
      console.log("saveWord data", data)
      store.dispatch("createWordsItem", data).then(
        (resolve) => {
          console.log("resolve", resolve)
          const wordId = resolve.getWordId()
          this.$emit("afterAction", wordId)
        },
        (reject) => {
          console.log("reject", reject)
        }
      )
      return true
    },

    closeWordAdd() {
      this.$emit("hideModal")
    },

    loadData() {
      this.isActiveModal = false
      let currentNode = JSON.parse(JSON.stringify(store.getters.getCurrentWordsNode))
      console.log('loadData currentNode', currentNode)
      if (currentNode) {
        this.item = {
          parent_id: (currentNode.parent_id > 0) || !currentNode.is_linked ? currentNode.parent_id : currentNode.id,
          parentText: ('' != currentNode.parentText) ? currentNode.parentText : currentNode.text,
          text: "",
          is_linked: currentNode.is_linked,
        }
      } else {
        this.item = {
          parent_id: -1,
          parentText: parentRootText,
          text: "",
          is_linked: true,
        }
      }
    },
  },
})
