import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "doc-container" }
const _hoisted_2 = { class: "row q-col-gutter-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuElement = _resolveComponent("MenuElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addonList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-4",
          style: _normalizeStyle(item.access.includes(_ctx.userRole) ? null : 'display: none')
        }, [
          _createVNode(_component_MenuElement, {
            url: item.url,
            title: item.title
          }, null, 8, ["url", "title"])
        ], 4))
      }), 128))
    ])
  ]))
}