import { QbuilderServiceClient } from "../../proto/pb/QbuilderServiceClientPb";
import {
  Query,
  QueryLimit,
  QueryWhere,
  QueryWhereItem,
  QuerySelect,
  QueryField,
  QueryFrom,
  ListQbuilderRequest,
  CreateQbuilderRequest,
  UpdateQbuilderRequest,
  QbuilderFilter,
  QbuilderFilterItem,
  QuerySort,
  QbuilderPagination,
  DeleteQbuilderRequest,
  ExecuteQbuilderRequest,
  QueryJoin,
  QueryJoinItem,
  QueryGroup,
} from "../../proto/pb/qbuilder_pb";

import { getAuthToken } from "@/mixins/authMetadata";
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

type QbuilderItemFrom = {
  schema: string;
  table: string;
  alias: string;
  tableParams: string;
  checkSum: string;
};

export type QbuilderItem = {
  id: number;
  name?: string;
  authorId?: number;
  description?: string;
  sourceId?: number;
  select?: SourceTreeChildren[];
  from?: QbuilderItemFrom;
  where?: QueryWhereItem.AsObject[];
  query?: string;
  sort?: QuerySort.AsObject[];
  group?: QueryGroup.AsObject[];
  join?: QueryJoinItem.AsObject[];
  fields: string;
  limit: QueryLimit.AsObject;
  isSaved: boolean;
  exectAll: boolean;
};

export type SourceTreeChildren = {
  idx: number;
  tableName: string;
  tableId: string;
  table: string;
  label: string;
  name: string;
  operand: string;
  value: string;
  type: string;
  selected: boolean;
  alias: string;
  sort: string;
  group: string | null;
  logicalOperator: string;
};

export class SqlBuilderApi {
  client: QbuilderServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new QbuilderServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
  }

  createFilter(data: any) {
    const filter = new QbuilderFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new QbuilderFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {
    const sort = new QuerySort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "qbuilder_name";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {
    const pagination = new QbuilderPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number = data.limit !== undefined ? Number(data.limit) : 100;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  listQbuilder(
    filter: QbuilderFilterItem[] | Array<any> = [],
    sort: QuerySort | object = {},
    pagination: QbuilderPagination | object = {}
  ) {
    const req = new ListQbuilderRequest();

    req
      .setFilter(this.createFilter(filter || []))
      .setPagination(this.createPagination(pagination || {}))
      .setSort(this.createSort(sort || {}));
    return this.client.listQbuilder(req, getAuthToken());
  }

  from(item: QueryFrom.AsObject | undefined) {
    const from = new QueryFrom();

    from
      .setSchema(item?.schema ?? "")
      .setTable(item?.table ?? "")
      .setTableParams(item?.tableParams ?? "")
      .setAlias(item?.alias ?? "");

    return from;
  }

  select(item: SourceTreeChildren[] | undefined) {
    const select = new QuerySelect();

    const selected: QueryField[] = [];
    item?.forEach((select) => {
      const fields = new QueryField();
      fields
        .setFieldName(select.name ?? "")
        .setFieldAlias(select.alias ?? "")
        .setFieldTableAlias(select.table ?? "")
        .setFieldLabel(select.label ?? "")
        .setFieldAggFunc(
          select.group === "distinct" ? "count" : select.group ?? ""
        )
        .setFieldUseDistinct(select.group === "distinct")
        .setType(select.type);

      selected.push(fields);
    });
    select.setFieldListList(selected);

    return select;
  }

  where(items: QueryWhereItem.AsObject[] | undefined) {
    const where = new QueryWhere();

    const whereList: QueryWhereItem[] = [];
    items?.forEach((item) => {
      const whereItem = new QueryWhereItem();
      whereItem
        .setTable(item.table ?? "")
        .setFieldName(item.fieldName ?? "")
        .setOperand(item.operand ?? "")
        .setValue(item.value?.toString() ?? "")
        .setType(item.type ?? "")
        .setLogicalOperator(
          item.logicalOperator === "И"
            ? "AND"
            : item.logicalOperator === "ИЛИ"
            ? "OR"
            : ""
        );
      whereList.push(whereItem);
    });

    where.setItemsList(whereList);

    return where;
  }

  group(items: QueryGroup.AsObject[] | undefined) {
    const groupList: QueryGroup[] = [];

    items?.forEach((item) => {
      const groupItem = new QueryGroup();
      groupItem.setTable(item.table ?? "").setByField(item.byField ?? "");

      groupList.push(groupItem);
    });

    return groupList;
  }

  sort(items: QuerySort.AsObject[] | undefined) {
    const sortList: QuerySort[] = [];
    items?.forEach((item) => {
      const sortItem = new QuerySort();
      sortItem
        .setTable(item.table ?? "")
        .setName(item.name ?? "")
        .setExp(item.exp ?? "")
        .setPriority(item.priority ?? 0);

      sortList.push(sortItem);
    });

    return sortList;
  }

  limit(items: QueryLimit.AsObject) {
    const req = new QueryLimit();

    req.setLimit(items.limit).setOffset(items.offset);

    return req;
  }

  join(items: QueryJoinItem.AsObject[] | undefined) {
    const join = new QueryJoin();

    const joinList: QueryJoinItem[] = [];
    items?.forEach((item) => {
      const join = new QueryJoinItem();

      join
        .setLeftSchema(item.rightSchema)
        .setLeftTable(item.rightTable)
        .setLeftTableAlias(item.rightTableAlias)
        .setLeftTableField(item.rightTableField)
        .setRightSchema(item.leftSchema)
        .setRightTable(item.leftTable)
        .setRightTableAlias(item.leftTableAlias)
        .setRightTableField(item.leftTableField);

      joinList.push(join);
    });

    join.setItemsList(joinList);
    return join;
  }

  createQbuilder(item: QbuilderItem) {
    const req = new CreateQbuilderRequest();
    const query = new Query();

    query
      .setSelect(this.select(item.select))
      .setFrom(this.from(item.from))
      .setWhere(this.where(item.where))
      .setSortList(this.sort(item.sort))
      .setGroupList(this.group(item.group))
      .setLimit(this.limit(item.limit))
      .setJoin(this.join(item.join));

    req
      .setQbuilderName(item.name ?? "")
      .setQbuilderAuthorId(item.authorId ?? 0)
      .setQbuilderSourceId(item.sourceId ?? 0)
      .setQbuilderDescription(item.description ?? "")
      .setQbuilderQuery(query)
      .setQbuilderFields(item.fields ?? "")
      .setQbuilderExecAll(item.exectAll ?? true);

    return this.client.createQbuilder(req, getAuthToken());
  }

  updateQbuilder(item: QbuilderItem) {
    const req = new UpdateQbuilderRequest();
    const query = new Query();

    query
      .setSelect(this.select(item.select))
      .setFrom(this.from(item.from))
      .setWhere(this.where(item.where))
      .setSortList(this.sort(item.sort))
      .setGroupList(this.group(item.group))
      .setLimit(this.limit(item.limit))
      .setJoin(this.join(item.join));

    req
      .setQbuilderId(item.id)
      .setQbuilderName(item.name ?? "")
      .setQbuilderDescription(item.description ?? "")
      .setQbuilderQuery(query)
      .setQbuilderFields(item.fields ?? "")
      .setQbuilderExecAll(item.exectAll ?? true);

    return this.client.updateQbuilder(req, getAuthToken());
  }

  deleteQbuilder(id: number) {
    const req = new DeleteQbuilderRequest();

    req.setQbuilderId(id);

    return this.client.deleteQbuilder(req, getAuthToken());
  }

  qbuilderReq(item: QbuilderItem) {
    const req = new ExecuteQbuilderRequest();
    const query = new Query();

    query
      .setSelect(this.select(item.select))
      .setFrom(this.from(item.from))
      .setWhere(this.where(item.where))
      .setSortList(this.sort(item.sort))
      .setGroupList(this.group(item.group))
      .setLimit(this.limit(item.limit))
      .setJoin(this.join(item.join));

    req.setQbuilderId(0).setQbuilderQuery(query);

    return req;
  }

  executeQbuilder(item: QbuilderItem) {
    return this.client.executeQbuilder(this.qbuilderReq(item), getAuthToken());
  }

  getSqlRequest(item: QbuilderItem) {
    return this.client.getSqlQbuilder(this.qbuilderReq(item), getAuthToken());
  }

  exportQbuilder(item: QbuilderItem) {
    return this.client.exportExecuteQbuilder(
      this.qbuilderReq(item),
      getAuthToken()
    );
  }
}
