import { GetterTree, MutationTree, ActionTree } from "vuex";
import { LoadPolls } from "../services/api/LoadPolls";
import Loading from 'quasar/src/plugins/loading/Loading.js';import Notify from 'quasar/src/plugins/notify/Notify.js';

const loadPolls = new LoadPolls()

class State {
    isListPollLoadBusy: boolean = false;
    listPollLoadItems: any;
    loadPoll: any
    loadStatus: any
    listPagination: any
    stream: any
    loadButton: boolean
    pollByDate: any
    isPollByDate: boolean
    voteUsers: any
    isVoteUsers: boolean
    pollSource: any
    isPollSource: boolean
}

const getters = <GetterTree<State, any>> {
    isListPollLoadBusy(state): boolean {
        return state.isListPollLoadBusy;
    },

    getListPollLoadItems(state): unknown[] {
        return state.listPollLoadItems;
    },

    getLoadStatus(state) {
        return state.loadStatus
    },

    getListPagination(state) {
        return state.listPagination
    },

    getStream(state) {
        return state.stream
    },

    getLoadButton(state) {
        return state.loadButton
    },

    getPollByDate(state) {
        return state.pollByDate
    },

    isPollByDate(state) {
        return state.isPollByDate
    },

    getVoteUsers(state) {
        return state.voteUsers
    },

    isVoteUsers(state) {
        return state.isVoteUsers
    },

    getPollSource(state) {
        return state.pollSource
    },

    isPollSource(state) {
        return state.isPollSource
    }
}

const mutations = <MutationTree<State>> {
    setIsListPollLoadBusy(state, payload) {
        state.isListPollLoadBusy = payload
    },

    setListPollLoadItems(state, payload) {
        state.listPollLoadItems = payload
    },

    setLoadStatus(state, payload) {
        state.loadStatus = payload
    },

    setListPagination(state, payload) {
        state.listPagination = payload
    },

    setStream(state, payload) {
        state.stream = payload
    },

    setLoadButton(state, payload) {
        state.loadButton = payload
    },

    setPollByDate(state, payload) {
        state.pollByDate = payload
    },

    setIsPollByDate(state, payload) {
        state.isPollByDate = payload
    },

    setVoteUsers(state, payload) {
        state.voteUsers = payload
    },

    setIsVoteUsers(state, payload) {
        state.isVoteUsers = payload
    },

    setPollSource(state, payload) {
        state.pollSource = payload
    },

    setIsPollSource(state, payload) {
        state.isPollSource = payload
    }
}

const actions = <ActionTree<State, any>> {
    getListPollLoadItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsListPollLoadBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {
            loadPolls.getPollLoadItems(filter, sort, pagination)
                .then((msg: any) => {
                    const items = msg.getItemsList()
                    const sort = msg.getParams().getSort()
                    const pagination = msg.getParams().getPagination()
                    commit('setListPollLoadItems', items)
                    commit('setIsListPollLoadBusy', false)
                    resolve({
                        items: items,
                        sort: sort,
                        pagination: pagination
                    })
                })
                .catch((err) => {
                    commit('setIsListPollLoadBusy', false)
                    reject(err)
                })
        })

        // const stream = loadPolls.getPollLoadItems(filter, sort, pagination)
        // let items = {} as any
        //
        // commit('setStream', stream)
        //
        // stream
        //     .on('data', (response: any) => {
        //         console.log('LOAD POLLS RESP', response)
        //         items = {
        //             items: response.getItemsList(),
        //             sort: response.getParams().getSort(),
        //             pagination: response.getParams().getPagination()
        //         }
        //
        //         commit('setIsListPollLoadBusy', false)
        //         commit('setListPollLoadItems', items.items)
        //         commit('setListPagination', items.pagination)
        //
        //         console.log('datadatadata')
        //     })
        //     .on('end', () => {
        //         commit('setListPollLoadItems', items.items)
        //         commit('setListPagination', items.pagination)
        //         commit('setIsListPollLoadBusy', false)
        //
        //         console.log('endendend')
        //
        //         // Notify.create({
        //         //     type: 'positive',
        //         //     message: 'Опрос успешно загружен'
        //         // })
        //     })
    },

    loadPolls({ commit }, data: any) {
        const poll_id: any = data !== undefined && data.poll_id !== undefined ? data.poll_id : 0;

        console.log('POLLID', poll_id, data)

        return new Promise((resolve, reject) => {
            loadPolls.getPollLoad(poll_id)
                .then((msg) => {
                    console.log('POLL LOAD MSG', msg)
                    resolve(msg)
                })
                .catch((err) => {
                    console.log('POLL LOAD ERR', err)
                    reject(err)
                })
        })
    },

    checkLoadStatus({ commit }, data: any) {
        return new Promise((resolve, reject) => {
            loadPolls.checkPollLoad()
                .then((msg) => {
                    console.log('POLL LOAD STATUS MSG', msg)
                    commit('setLoadStatus', msg)
                    resolve(msg)
                })
                .catch((err) => {
                    console.log('POLL LOAD STATUS ERR', err)
                    reject(err)
                })
        })
    },

    getPollsByDate({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsPollByDate', true);

        return new Promise((resolve, reject) => {
            loadPolls.getPollsByDate(data)
                .then((msg: any) => {
                    const items = msg.getItemsList()

                    commit('setPollByDate', items)
                    commit('setIsPollByDate', false)
                    resolve(items)
                })
                .catch((err) => {
                    commit('setIsPollByDate', false)
                    reject(err)
                })
        })
    },

    getVoteUsers({ commit }, data: any) {
        // Устанавливаем событие загрузки
        commit('setIsVoteUsers', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};

        return new Promise((resolve, reject) => {
            loadPolls.getVoteUsers(filter, pagination, sort, data)
                .then((msg: any) => {
                    const items = msg.getItemsList()
                    const pagination = msg.getPagination()

                    commit('setVoteUsers', items)
                    commit('setIsVoteUsers', false)

                    resolve({
                        items: items,
                        pagination: pagination
                    })
                })
                .catch((err) => {
                    commit('setIsVoteUsers', false)
                    reject(err)
                })
        })
    },

    getSourcePolls({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsPollSource', true);

        return new Promise((resolve, reject) => {
            loadPolls.getPollSourceChart(data)
                .then((msg: any) => {
                    const items = msg.getItemsList()

                    commit('setPollSource', items)
                    commit('setIsPollSource', false)
                    resolve(items)
                })
                .catch((err) => {
                    commit('setIsPollSource', false)
                    reject(err)
                })
        })
    },
}

const LoadPollsModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default LoadPollsModule