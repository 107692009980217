<template>
  <q-card class="q-pa-xl">
    <div
      v-show="!enableTopActivityChart"
      class="text-h6 text-center"
    >
      Для анализа показателей выберите проект в диаграмме
      <span class="text-bold">"Количество пользователей проекта"</span>
    </div>
    <highcharts
      v-show="enableTopActivityChart"
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </q-card>
</template>

<script>
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TopActivitiesChart',
  data() {
    return {
      enableTopActivityChart: false,
      // enableTopActivityChart: true,
      chartOptions: {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Топ активностей',
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: 'Количество пользователей: {point.y} чел.',
          // clusterFormat: "Clustered points: {point.clusterPointsAmount}",
        },
        xAxis: {
          // categories: ['Golosovanie', 'Otvet'],
          categories: [],
        },
        yAxis: {
          // categories: ['<30', '30-60', '60-150', '150-365', '365+'],
          visible: false,
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: '365+',
          //   data: [32754, 34334],
          // },
          // {
          //   name: '150-365',
          //   data: [37902, 135754],
          // },
          // {
          //   name: '60-150',
          //   data: [9812, 9787],
          // },
          // {
          //   name: '30-60',
          //   data: [141454, 71242],
          // },
          // {
          //   name: '<30',
          //   data: [31480, 80746],
          // },
        ],
      },
    }
  },

  computed: {
    getCurrentProject() {
      return store.getters.getCurrentProject
    },

    getActivityValueItems() {
      return store.getters.getActivityValueItems
    },
  },

  watch: {
    /* eslint-disable */
    getCurrentProject() {
      if (this.getActivityValueItems !== undefined) {
        let categories = []
        let scorecards = {}
        for (let activityItem of this.getActivityValueItems) {
          if (activityItem.getProjectName() === this.getCurrentProject) {
            categories.push(activityItem.getScorecardTitle())
            if (
              Object.prototype.hasOwnProperty.call(
                scorecards,
                activityItem.getPeriodId()
              )
            ) {
              scorecards[activityItem.getPeriodId()].name =
                activityItem.getPeriodTitle()
              scorecards[activityItem.getPeriodId()].data.push(
                activityItem.getActivityValue()
              )
            } else {
              scorecards[activityItem.getPeriodId()] = {}
              scorecards[activityItem.getPeriodId()].name =
                activityItem.getPeriodTitle()
              scorecards[activityItem.getPeriodId()].data = []
              scorecards[activityItem.getPeriodId()].data.push(
                activityItem.getActivityValue()
              )
            }
          }
        }
        this.chartOptions.xAxis.categories = categories.filter(
          (el, ind) => ind === categories.indexOf(el)
        )
        this.chartOptions.title.text = `Топ активностей проекта "${this.getCurrentProject}"`
        this.chartOptions.series = Object.values(scorecards).reverse()
        this.enableTopActivityChart = !!this.getCurrentProject
      }
    },
  },

  methods: {
    getActivityValueFilters() {
      return []
    },

    loadActivityValueItems() {
      store
        .dispatch('getActivityValueItems', {
          filter: this.getActivityValueFilters(),
        })
        .then(
          (resolve) => {
            console.log('getActivityValueItems resolve', resolve)
          },
          (reject) => {
            console.log('getActivityValueItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadActivityValueItems()
    },
  },

  created() {
    this.loadData()
  },
})
</script>
