import script from "./AnomalyMonitor.vue?vue&type=script&setup=true&lang=ts"
export * from "./AnomalyMonitor.vue?vue&type=script&setup=true&lang=ts"

import "./AnomalyMonitor.vue?vue&type=style&index=0&id=2cb150a5&lang=scss"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QInput,QIcon,QTree,QPopupProxy,QDate,QBtn,QSkeleton,QSlider});qInstall(script, 'directives', {ClosePopup});
