<template>
  <q-card class="q-pa-md q-mb-md">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </q-card>
</template>

<script>
export default {
  name: 'MaritalStatusChart',
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Семейное положение',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage} %',
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'Проценты',
            colorByPoint: true,
            data: [
              {
                name: 'Холост',
                y: 35,
              },
              {
                name: 'Разведен',
                y: 30,
              },
              {
                name: 'В браке (нет детей)',
                y: 25,
              },
              {
                name: 'В браке (есть дети)',
                y: 10,
                sliced: true,
                selected: true,
              },
            ],
          },
        ],
      },
    }
  },
}
</script>
