import IntersectionMain from "../views/IntersectionMain.vue";
const routes = [
  {
    path: "/intersection",
    name: "IntersectionMain",
    component: IntersectionMain,
    meta: {
      breadCrumb: [
        {
          text: "Главная",
          to: { name: "HomePage" },
          icon: "home",
        },
        { text: "Сводная аналитика" },
      ],
    },
  },
  {
    path: "/user-digital-project/report/pl",
    name: "LoyaltyTest",
    component: () => import("../views/LoyaltyProject.vue"),
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        // { text: 'Проект', to: { name: 'ProjectList' } },
        { text: "Цифровой профиль проекта ПЛ" },
      ],
    },
  },
];

export default {
  routes,
};
