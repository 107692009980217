
import { defineComponent } from "vue";
import MenuElement from "./modules/user-digital-project/components/Design/MenuElement.vue";

export default defineComponent({
  name: "NSIList",

  components: {
    MenuElement,
  },

  data() {
    return {
      addonList: [
        {
          title: "Классификаторы",
          url: "nsi/classifiers",
        },
        // {
        //     title: 'Справочник проектов',
        //     url: 'nsi/project-directory',
        // },
        {
          title: 'Теги пользователя в профиле ПЛ',
          url: 'nsi/tags',
        },
        {
          title: "Словарь",
          url: "nsi/dictionary",
        },

        // {
        //   title: "Просмотр профиля выбранного пользователя",
        //   url: "aehr",
        // },
        // {
        //   title: "Прогнозирование аудитории",
        //   url: "dky",
        // },
        // {
        //   title: "Оповещение аудитории",
        //   url: "sh",
        // },
      ],
    };
  },
});
