<template>
  <q-card class="q-pa-md">
    <highcharts
      ref="chart"
      class="hc"
      :options="chartOptions"
    />
  </q-card>
</template>

<script>
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ActivityPercentageChart',
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Процент наибольшей активности пользователей в рамках проектов',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.2f} %',
            },
          },
        },
        series: [
          {
            name: 'Проценты',
            colorByPoint: true,
            data: [
              // {
              //   name: "Активный гражданин",
              //   y: 45,
              //   sliced: true,
              //   selected: true,
              // },
            ],
          },
        ],
      },
    }
  },

  computed: {
    getActivityValueItems() {
      return store.getters.getActivityValueItems
    },
  },

  watch: {
    getActivityValueItems() {
      if (this.getActivityValueItems !== undefined) {
        let percentageActivity = {}
        for (let activityItem of this.getActivityValueItems) {
          if (
            Object.prototype.hasOwnProperty.call(
              percentageActivity,
              activityItem.getProjectId()
            )
          ) {
            percentageActivity[activityItem.getProjectId()].name =
              activityItem.getProjectName()
            percentageActivity[activityItem.getProjectId()].y +=
              activityItem.getActivityValue()
          } else {
            percentageActivity[activityItem.getProjectId()] = {}
            percentageActivity[activityItem.getProjectId()].name =
              activityItem.getProjectName()
            percentageActivity[activityItem.getProjectId()].y = 0
            percentageActivity[activityItem.getProjectId()].y +=
              activityItem.getActivityValue()
          }
        }
        this.chartOptions.series[0].data = Object.values(percentageActivity)
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    getActivityValueFilters() {
      return []
    },

    loadActivityValueItems() {
      store
        .dispatch('getActivityValueItems', {
          filter: this.getActivityValueFilters(),
        })
        .then(
          (resolve) => {
            console.log('getActivityValueItems resolve', resolve)
          },
          (reject) => {
            console.log('getActivityValueItems reject', reject)
          }
        )
    },

    loadData() {
      this.loadActivityValueItems()
    },
  },
})
</script>
