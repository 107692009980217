
import store from "@/store"
import { defineComponent } from "vue"
import DataLoading from "@/components/DataLoading.vue"

export default defineComponent({
  name: "WordsPanel",

  components: {
    DataLoading,
  },

  data() {
    return {
    }
  },

  computed: {
    isWordsBusy() {
      return store.getters.getIsWordsBusy
    },

    isSelectedWordsNode() {
      return null !== store.getters.getCurrentWordsNode
    },

    node() {
      let currentWordsNode = store.getters.getCurrentWordsNode
      return currentWordsNode
        ? currentWordsNode
        : {
            id: 0,
            parent_id: 0,
            text: "",
            is_linked: false,
            parentText: "",
          }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {},
  },
})
