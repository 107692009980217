// source: qbuilder.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.CreateQbuilderRequest', null, global);
goog.exportSymbol('proto.proto.CreateQbuilderResponse', null, global);
goog.exportSymbol('proto.proto.DeleteQbuilderRequest', null, global);
goog.exportSymbol('proto.proto.DeleteQbuilderResponse', null, global);
goog.exportSymbol('proto.proto.ExecuteQbuilderRequest', null, global);
goog.exportSymbol('proto.proto.ExecuteQbuilderResponse', null, global);
goog.exportSymbol('proto.proto.ExportExecuteQbuilderResponse', null, global);
goog.exportSymbol('proto.proto.ForcedLimit', null, global);
goog.exportSymbol('proto.proto.GetSqlRequest', null, global);
goog.exportSymbol('proto.proto.GetSqlResponse', null, global);
goog.exportSymbol('proto.proto.GetTableAliasRequest', null, global);
goog.exportSymbol('proto.proto.GetTableAliasResponse', null, global);
goog.exportSymbol('proto.proto.ListQbuilderRequest', null, global);
goog.exportSymbol('proto.proto.ListQbuilderResponse', null, global);
goog.exportSymbol('proto.proto.Qbuilder', null, global);
goog.exportSymbol('proto.proto.QbuilderFilter', null, global);
goog.exportSymbol('proto.proto.QbuilderFilterItem', null, global);
goog.exportSymbol('proto.proto.QbuilderPagination', null, global);
goog.exportSymbol('proto.proto.QbuilderSort', null, global);
goog.exportSymbol('proto.proto.Query', null, global);
goog.exportSymbol('proto.proto.QueryField', null, global);
goog.exportSymbol('proto.proto.QueryFrom', null, global);
goog.exportSymbol('proto.proto.QueryGroup', null, global);
goog.exportSymbol('proto.proto.QueryJoin', null, global);
goog.exportSymbol('proto.proto.QueryJoinItem', null, global);
goog.exportSymbol('proto.proto.QueryLimit', null, global);
goog.exportSymbol('proto.proto.QuerySelect', null, global);
goog.exportSymbol('proto.proto.QuerySort', null, global);
goog.exportSymbol('proto.proto.QueryWhere', null, global);
goog.exportSymbol('proto.proto.QueryWhereItem', null, global);
goog.exportSymbol('proto.proto.SubQuery', null, global);
goog.exportSymbol('proto.proto.UpdateQbuilderRequest', null, global);
goog.exportSymbol('proto.proto.UpdateQbuilderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Qbuilder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Qbuilder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Qbuilder.displayName = 'proto.proto.Qbuilder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Query = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Query.repeatedFields_, null);
};
goog.inherits(proto.proto.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Query.displayName = 'proto.proto.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SubQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SubQuery.repeatedFields_, null);
};
goog.inherits(proto.proto.SubQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SubQuery.displayName = 'proto.proto.SubQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QuerySelect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.QuerySelect.repeatedFields_, null);
};
goog.inherits(proto.proto.QuerySelect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QuerySelect.displayName = 'proto.proto.QuerySelect';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryField.displayName = 'proto.proto.QueryField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryFrom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryFrom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryFrom.displayName = 'proto.proto.QueryFrom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryWhere = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.QueryWhere.repeatedFields_, null);
};
goog.inherits(proto.proto.QueryWhere, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryWhere.displayName = 'proto.proto.QueryWhere';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryWhereItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryWhereItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryWhereItem.displayName = 'proto.proto.QueryWhereItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryJoin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.QueryJoin.repeatedFields_, null);
};
goog.inherits(proto.proto.QueryJoin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryJoin.displayName = 'proto.proto.QueryJoin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryJoinItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryJoinItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryJoinItem.displayName = 'proto.proto.QueryJoinItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QuerySort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QuerySort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QuerySort.displayName = 'proto.proto.QuerySort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryGroup.displayName = 'proto.proto.QueryGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QueryLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QueryLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QueryLimit.displayName = 'proto.proto.QueryLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QbuilderFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QbuilderFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QbuilderFilterItem.displayName = 'proto.proto.QbuilderFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QbuilderFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.QbuilderFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.QbuilderFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QbuilderFilter.displayName = 'proto.proto.QbuilderFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QbuilderSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QbuilderSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QbuilderSort.displayName = 'proto.proto.QbuilderSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.QbuilderPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.QbuilderPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.QbuilderPagination.displayName = 'proto.proto.QbuilderPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ForcedLimit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ForcedLimit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ForcedLimit.displayName = 'proto.proto.ForcedLimit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListQbuilderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListQbuilderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListQbuilderRequest.displayName = 'proto.proto.ListQbuilderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListQbuilderResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListQbuilderResponse.displayName = 'proto.proto.ListQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateQbuilderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateQbuilderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateQbuilderRequest.displayName = 'proto.proto.CreateQbuilderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateQbuilderResponse.displayName = 'proto.proto.CreateQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateQbuilderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateQbuilderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateQbuilderRequest.displayName = 'proto.proto.UpdateQbuilderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateQbuilderResponse.displayName = 'proto.proto.UpdateQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteQbuilderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteQbuilderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteQbuilderRequest.displayName = 'proto.proto.DeleteQbuilderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteQbuilderResponse.displayName = 'proto.proto.DeleteQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExecuteQbuilderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExecuteQbuilderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExecuteQbuilderRequest.displayName = 'proto.proto.ExecuteQbuilderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExecuteQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExecuteQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExecuteQbuilderResponse.displayName = 'proto.proto.ExecuteQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetTableAliasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetTableAliasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetTableAliasRequest.displayName = 'proto.proto.GetTableAliasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetTableAliasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetTableAliasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetTableAliasResponse.displayName = 'proto.proto.GetTableAliasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExportExecuteQbuilderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExportExecuteQbuilderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExportExecuteQbuilderResponse.displayName = 'proto.proto.ExportExecuteQbuilderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetSqlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetSqlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetSqlRequest.displayName = 'proto.proto.GetSqlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetSqlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetSqlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetSqlResponse.displayName = 'proto.proto.GetSqlResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Qbuilder.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Qbuilder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Qbuilder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Qbuilder.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qbuilderSourceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    qbuilderSourceDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qbuilderName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    qbuilderDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    qbuilderCreated: jspb.Message.getFieldWithDefault(msg, 6, ""),
    qbuilderFields: jspb.Message.getFieldWithDefault(msg, 7, ""),
    qbuilderQuery: jspb.Message.getFieldWithDefault(msg, 8, ""),
    qbuilderConformed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    qbuilderExecAll: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    qbuilderAuthorId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    qbuilderAuthorFirstName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    qbuilderAuthorMiddleName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    qbuilderAuthorLastName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Qbuilder}
 */
proto.proto.Qbuilder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Qbuilder;
  return proto.proto.Qbuilder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Qbuilder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Qbuilder}
 */
proto.proto.Qbuilder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderSourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderSourceDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderCreated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderFields(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderQuery(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQbuilderConformed(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQbuilderExecAll(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderAuthorId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderAuthorFirstName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderAuthorMiddleName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderAuthorLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Qbuilder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Qbuilder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Qbuilder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Qbuilder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQbuilderSourceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getQbuilderSourceDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQbuilderName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQbuilderDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQbuilderCreated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQbuilderFields();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQbuilderQuery();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQbuilderConformed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getQbuilderExecAll();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getQbuilderAuthorId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getQbuilderAuthorFirstName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQbuilderAuthorMiddleName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getQbuilderAuthorLastName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.Qbuilder.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 qbuilder_source_id = 2;
 * @return {number}
 */
proto.proto.Qbuilder.prototype.getQbuilderSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string qbuilder_source_description = 3;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderSourceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderSourceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string qbuilder_name = 4;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string qbuilder_description = 5;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string qbuilder_created = 6;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string qbuilder_fields = 7;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderFields = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderFields = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string qbuilder_query = 8;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool qbuilder_conformed = 9;
 * @return {boolean}
 */
proto.proto.Qbuilder.prototype.getQbuilderConformed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderConformed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool qbuilder_exec_all = 10;
 * @return {boolean}
 */
proto.proto.Qbuilder.prototype.getQbuilderExecAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderExecAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 qbuilder_author_id = 11;
 * @return {number}
 */
proto.proto.Qbuilder.prototype.getQbuilderAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderAuthorId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string qbuilder_author_first_name = 12;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderAuthorFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderAuthorFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string qbuilder_author_middle_name = 13;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderAuthorMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderAuthorMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string qbuilder_author_last_name = 14;
 * @return {string}
 */
proto.proto.Qbuilder.prototype.getQbuilderAuthorLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Qbuilder} returns this
 */
proto.proto.Qbuilder.prototype.setQbuilderAuthorLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Query.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Query.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Query.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Query} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Query.toObject = function(includeInstance, msg) {
  var f, obj = {
    select: (f = msg.getSelect()) && proto.proto.QuerySelect.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.proto.QueryFrom.toObject(includeInstance, f),
    fromSubquery: (f = msg.getFromSubquery()) && proto.proto.SubQuery.toObject(includeInstance, f),
    where: (f = msg.getWhere()) && proto.proto.QueryWhere.toObject(includeInstance, f),
    join: (f = msg.getJoin()) && proto.proto.QueryJoin.toObject(includeInstance, f),
    sortList: jspb.Message.toObjectList(msg.getSortList(),
    proto.proto.QuerySort.toObject, includeInstance),
    groupList: jspb.Message.toObjectList(msg.getGroupList(),
    proto.proto.QueryGroup.toObject, includeInstance),
    limit: (f = msg.getLimit()) && proto.proto.QueryLimit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Query}
 */
proto.proto.Query.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Query;
  return proto.proto.Query.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Query}
 */
proto.proto.Query.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QuerySelect;
      reader.readMessage(value,proto.proto.QuerySelect.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    case 2:
      var value = new proto.proto.QueryFrom;
      reader.readMessage(value,proto.proto.QueryFrom.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.proto.SubQuery;
      reader.readMessage(value,proto.proto.SubQuery.deserializeBinaryFromReader);
      msg.setFromSubquery(value);
      break;
    case 4:
      var value = new proto.proto.QueryWhere;
      reader.readMessage(value,proto.proto.QueryWhere.deserializeBinaryFromReader);
      msg.setWhere(value);
      break;
    case 5:
      var value = new proto.proto.QueryJoin;
      reader.readMessage(value,proto.proto.QueryJoin.deserializeBinaryFromReader);
      msg.setJoin(value);
      break;
    case 6:
      var value = new proto.proto.QuerySort;
      reader.readMessage(value,proto.proto.QuerySort.deserializeBinaryFromReader);
      msg.addSort(value);
      break;
    case 7:
      var value = new proto.proto.QueryGroup;
      reader.readMessage(value,proto.proto.QueryGroup.deserializeBinaryFromReader);
      msg.addGroup(value);
      break;
    case 8:
      var value = new proto.proto.QueryLimit;
      reader.readMessage(value,proto.proto.QueryLimit.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Query.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Query.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.QuerySelect.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.QueryFrom.serializeBinaryToWriter
    );
  }
  f = message.getFromSubquery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SubQuery.serializeBinaryToWriter
    );
  }
  f = message.getWhere();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.QueryWhere.serializeBinaryToWriter
    );
  }
  f = message.getJoin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.QueryJoin.serializeBinaryToWriter
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.proto.QuerySort.serializeBinaryToWriter
    );
  }
  f = message.getGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.QueryGroup.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.proto.QueryLimit.serializeBinaryToWriter
    );
  }
};


/**
 * optional QuerySelect select = 1;
 * @return {?proto.proto.QuerySelect}
 */
proto.proto.Query.prototype.getSelect = function() {
  return /** @type{?proto.proto.QuerySelect} */ (
    jspb.Message.getWrapperField(this, proto.proto.QuerySelect, 1));
};


/**
 * @param {?proto.proto.QuerySelect|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setSelect = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QueryFrom from = 2;
 * @return {?proto.proto.QueryFrom}
 */
proto.proto.Query.prototype.getFrom = function() {
  return /** @type{?proto.proto.QueryFrom} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryFrom, 2));
};


/**
 * @param {?proto.proto.QueryFrom|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SubQuery from_subquery = 3;
 * @return {?proto.proto.SubQuery}
 */
proto.proto.Query.prototype.getFromSubquery = function() {
  return /** @type{?proto.proto.SubQuery} */ (
    jspb.Message.getWrapperField(this, proto.proto.SubQuery, 3));
};


/**
 * @param {?proto.proto.SubQuery|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setFromSubquery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearFromSubquery = function() {
  return this.setFromSubquery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasFromSubquery = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QueryWhere where = 4;
 * @return {?proto.proto.QueryWhere}
 */
proto.proto.Query.prototype.getWhere = function() {
  return /** @type{?proto.proto.QueryWhere} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryWhere, 4));
};


/**
 * @param {?proto.proto.QueryWhere|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setWhere = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearWhere = function() {
  return this.setWhere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasWhere = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QueryJoin join = 5;
 * @return {?proto.proto.QueryJoin}
 */
proto.proto.Query.prototype.getJoin = function() {
  return /** @type{?proto.proto.QueryJoin} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryJoin, 5));
};


/**
 * @param {?proto.proto.QueryJoin|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setJoin = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearJoin = function() {
  return this.setJoin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasJoin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated QuerySort sort = 6;
 * @return {!Array<!proto.proto.QuerySort>}
 */
proto.proto.Query.prototype.getSortList = function() {
  return /** @type{!Array<!proto.proto.QuerySort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QuerySort, 6));
};


/**
 * @param {!Array<!proto.proto.QuerySort>} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setSortList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.proto.QuerySort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QuerySort}
 */
proto.proto.Query.prototype.addSort = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.proto.QuerySort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * repeated QueryGroup group = 7;
 * @return {!Array<!proto.proto.QueryGroup>}
 */
proto.proto.Query.prototype.getGroupList = function() {
  return /** @type{!Array<!proto.proto.QueryGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QueryGroup, 7));
};


/**
 * @param {!Array<!proto.proto.QueryGroup>} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.QueryGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QueryGroup}
 */
proto.proto.Query.prototype.addGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.QueryGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearGroupList = function() {
  return this.setGroupList([]);
};


/**
 * optional QueryLimit limit = 8;
 * @return {?proto.proto.QueryLimit}
 */
proto.proto.Query.prototype.getLimit = function() {
  return /** @type{?proto.proto.QueryLimit} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryLimit, 8));
};


/**
 * @param {?proto.proto.QueryLimit|undefined} value
 * @return {!proto.proto.Query} returns this
*/
proto.proto.Query.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Query} returns this
 */
proto.proto.Query.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Query.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SubQuery.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SubQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SubQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SubQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SubQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    alias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    select: (f = msg.getSelect()) && proto.proto.QuerySelect.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.proto.QueryFrom.toObject(includeInstance, f),
    where: (f = msg.getWhere()) && proto.proto.QueryWhere.toObject(includeInstance, f),
    join: (f = msg.getJoin()) && proto.proto.QueryJoin.toObject(includeInstance, f),
    sortList: jspb.Message.toObjectList(msg.getSortList(),
    proto.proto.QuerySort.toObject, includeInstance),
    groupList: jspb.Message.toObjectList(msg.getGroupList(),
    proto.proto.QueryGroup.toObject, includeInstance),
    limit: (f = msg.getLimit()) && proto.proto.QueryLimit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SubQuery}
 */
proto.proto.SubQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SubQuery;
  return proto.proto.SubQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SubQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SubQuery}
 */
proto.proto.SubQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 2:
      var value = new proto.proto.QuerySelect;
      reader.readMessage(value,proto.proto.QuerySelect.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    case 3:
      var value = new proto.proto.QueryFrom;
      reader.readMessage(value,proto.proto.QueryFrom.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new proto.proto.QueryWhere;
      reader.readMessage(value,proto.proto.QueryWhere.deserializeBinaryFromReader);
      msg.setWhere(value);
      break;
    case 5:
      var value = new proto.proto.QueryJoin;
      reader.readMessage(value,proto.proto.QueryJoin.deserializeBinaryFromReader);
      msg.setJoin(value);
      break;
    case 6:
      var value = new proto.proto.QuerySort;
      reader.readMessage(value,proto.proto.QuerySort.deserializeBinaryFromReader);
      msg.addSort(value);
      break;
    case 7:
      var value = new proto.proto.QueryGroup;
      reader.readMessage(value,proto.proto.QueryGroup.deserializeBinaryFromReader);
      msg.addGroup(value);
      break;
    case 8:
      var value = new proto.proto.QueryLimit;
      reader.readMessage(value,proto.proto.QueryLimit.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SubQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SubQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SubQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SubQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.QuerySelect.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.QueryFrom.serializeBinaryToWriter
    );
  }
  f = message.getWhere();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.QueryWhere.serializeBinaryToWriter
    );
  }
  f = message.getJoin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.QueryJoin.serializeBinaryToWriter
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.proto.QuerySort.serializeBinaryToWriter
    );
  }
  f = message.getGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.proto.QueryGroup.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.proto.QueryLimit.serializeBinaryToWriter
    );
  }
};


/**
 * optional string alias = 1;
 * @return {string}
 */
proto.proto.SubQuery.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional QuerySelect select = 2;
 * @return {?proto.proto.QuerySelect}
 */
proto.proto.SubQuery.prototype.getSelect = function() {
  return /** @type{?proto.proto.QuerySelect} */ (
    jspb.Message.getWrapperField(this, proto.proto.QuerySelect, 2));
};


/**
 * @param {?proto.proto.QuerySelect|undefined} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setSelect = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SubQuery.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional QueryFrom from = 3;
 * @return {?proto.proto.QueryFrom}
 */
proto.proto.SubQuery.prototype.getFrom = function() {
  return /** @type{?proto.proto.QueryFrom} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryFrom, 3));
};


/**
 * @param {?proto.proto.QueryFrom|undefined} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SubQuery.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QueryWhere where = 4;
 * @return {?proto.proto.QueryWhere}
 */
proto.proto.SubQuery.prototype.getWhere = function() {
  return /** @type{?proto.proto.QueryWhere} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryWhere, 4));
};


/**
 * @param {?proto.proto.QueryWhere|undefined} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setWhere = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearWhere = function() {
  return this.setWhere(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SubQuery.prototype.hasWhere = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QueryJoin join = 5;
 * @return {?proto.proto.QueryJoin}
 */
proto.proto.SubQuery.prototype.getJoin = function() {
  return /** @type{?proto.proto.QueryJoin} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryJoin, 5));
};


/**
 * @param {?proto.proto.QueryJoin|undefined} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setJoin = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearJoin = function() {
  return this.setJoin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SubQuery.prototype.hasJoin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated QuerySort sort = 6;
 * @return {!Array<!proto.proto.QuerySort>}
 */
proto.proto.SubQuery.prototype.getSortList = function() {
  return /** @type{!Array<!proto.proto.QuerySort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QuerySort, 6));
};


/**
 * @param {!Array<!proto.proto.QuerySort>} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setSortList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.proto.QuerySort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QuerySort}
 */
proto.proto.SubQuery.prototype.addSort = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.proto.QuerySort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * repeated QueryGroup group = 7;
 * @return {!Array<!proto.proto.QueryGroup>}
 */
proto.proto.SubQuery.prototype.getGroupList = function() {
  return /** @type{!Array<!proto.proto.QueryGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QueryGroup, 7));
};


/**
 * @param {!Array<!proto.proto.QueryGroup>} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.proto.QueryGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QueryGroup}
 */
proto.proto.SubQuery.prototype.addGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.proto.QueryGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearGroupList = function() {
  return this.setGroupList([]);
};


/**
 * optional QueryLimit limit = 8;
 * @return {?proto.proto.QueryLimit}
 */
proto.proto.SubQuery.prototype.getLimit = function() {
  return /** @type{?proto.proto.QueryLimit} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryLimit, 8));
};


/**
 * @param {?proto.proto.QueryLimit|undefined} value
 * @return {!proto.proto.SubQuery} returns this
*/
proto.proto.SubQuery.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SubQuery} returns this
 */
proto.proto.SubQuery.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SubQuery.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.QuerySelect.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QuerySelect.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QuerySelect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QuerySelect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QuerySelect.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldListList: jspb.Message.toObjectList(msg.getFieldListList(),
    proto.proto.QueryField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QuerySelect}
 */
proto.proto.QuerySelect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QuerySelect;
  return proto.proto.QuerySelect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QuerySelect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QuerySelect}
 */
proto.proto.QuerySelect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QueryField;
      reader.readMessage(value,proto.proto.QueryField.deserializeBinaryFromReader);
      msg.addFieldList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QuerySelect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QuerySelect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QuerySelect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QuerySelect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.QueryField.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryField field_list = 1;
 * @return {!Array<!proto.proto.QueryField>}
 */
proto.proto.QuerySelect.prototype.getFieldListList = function() {
  return /** @type{!Array<!proto.proto.QueryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QueryField, 1));
};


/**
 * @param {!Array<!proto.proto.QueryField>} value
 * @return {!proto.proto.QuerySelect} returns this
*/
proto.proto.QuerySelect.prototype.setFieldListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.QueryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QueryField}
 */
proto.proto.QuerySelect.prototype.addFieldList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.QueryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.QuerySelect} returns this
 */
proto.proto.QuerySelect.prototype.clearFieldListList = function() {
  return this.setFieldListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryField.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldTableAlias: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fieldAggFunc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fieldUseDistinct: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryField}
 */
proto.proto.QueryField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryField;
  return proto.proto.QueryField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryField}
 */
proto.proto.QueryField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldTableAlias(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldAlias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldAggFunc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFieldUseDistinct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldTableAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFieldAggFunc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFieldUseDistinct();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string field_table_alias = 1;
 * @return {string}
 */
proto.proto.QueryField.prototype.getFieldTableAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldTableAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.proto.QueryField.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field_label = 3;
 * @return {string}
 */
proto.proto.QueryField.prototype.getFieldLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string field_alias = 4;
 * @return {string}
 */
proto.proto.QueryField.prototype.getFieldAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string field_agg_func = 5;
 * @return {string}
 */
proto.proto.QueryField.prototype.getFieldAggFunc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldAggFunc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string type = 6;
 * @return {string}
 */
proto.proto.QueryField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool field_use_distinct = 7;
 * @return {boolean}
 */
proto.proto.QueryField.prototype.getFieldUseDistinct = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.QueryField} returns this
 */
proto.proto.QueryField.prototype.setFieldUseDistinct = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryFrom.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryFrom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryFrom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryFrom.toObject = function(includeInstance, msg) {
  var f, obj = {
    schema: jspb.Message.getFieldWithDefault(msg, 1, ""),
    table: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tableParams: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryFrom}
 */
proto.proto.QueryFrom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryFrom;
  return proto.proto.QueryFrom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryFrom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryFrom}
 */
proto.proto.QueryFrom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryFrom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryFrom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryFrom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryFrom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTableParams();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string schema = 1;
 * @return {string}
 */
proto.proto.QueryFrom.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryFrom} returns this
 */
proto.proto.QueryFrom.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table = 2;
 * @return {string}
 */
proto.proto.QueryFrom.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryFrom} returns this
 */
proto.proto.QueryFrom.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alias = 3;
 * @return {string}
 */
proto.proto.QueryFrom.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryFrom} returns this
 */
proto.proto.QueryFrom.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string table_params = 4;
 * @return {string}
 */
proto.proto.QueryFrom.prototype.getTableParams = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryFrom} returns this
 */
proto.proto.QueryFrom.prototype.setTableParams = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.QueryWhere.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryWhere.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryWhere.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryWhere} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryWhere.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.QueryWhereItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryWhere}
 */
proto.proto.QueryWhere.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryWhere;
  return proto.proto.QueryWhere.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryWhere} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryWhere}
 */
proto.proto.QueryWhere.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QueryWhereItem;
      reader.readMessage(value,proto.proto.QueryWhereItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryWhere.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryWhere.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryWhere} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryWhere.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.QueryWhereItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryWhereItem items = 1;
 * @return {!Array<!proto.proto.QueryWhereItem>}
 */
proto.proto.QueryWhere.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.QueryWhereItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QueryWhereItem, 1));
};


/**
 * @param {!Array<!proto.proto.QueryWhereItem>} value
 * @return {!proto.proto.QueryWhere} returns this
*/
proto.proto.QueryWhere.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.QueryWhereItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QueryWhereItem}
 */
proto.proto.QueryWhere.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.QueryWhereItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.QueryWhere} returns this
 */
proto.proto.QueryWhere.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryWhereItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryWhereItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryWhereItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryWhereItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    table: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    logicalOperator: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryWhereItem}
 */
proto.proto.QueryWhereItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryWhereItem;
  return proto.proto.QueryWhereItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryWhereItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryWhereItem}
 */
proto.proto.QueryWhereItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryWhereItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryWhereItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryWhereItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryWhereItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLogicalOperator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string table = 1;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string operand = 3;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string logical_operator = 6;
 * @return {string}
 */
proto.proto.QueryWhereItem.prototype.getLogicalOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryWhereItem} returns this
 */
proto.proto.QueryWhereItem.prototype.setLogicalOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.QueryJoin.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryJoin.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryJoin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryJoin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryJoin.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.QueryJoinItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryJoin}
 */
proto.proto.QueryJoin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryJoin;
  return proto.proto.QueryJoin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryJoin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryJoin}
 */
proto.proto.QueryJoin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QueryJoinItem;
      reader.readMessage(value,proto.proto.QueryJoinItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryJoin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryJoin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryJoin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryJoin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.QueryJoinItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryJoinItem items = 1;
 * @return {!Array<!proto.proto.QueryJoinItem>}
 */
proto.proto.QueryJoin.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.QueryJoinItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QueryJoinItem, 1));
};


/**
 * @param {!Array<!proto.proto.QueryJoinItem>} value
 * @return {!proto.proto.QueryJoin} returns this
*/
proto.proto.QueryJoin.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.QueryJoinItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QueryJoinItem}
 */
proto.proto.QueryJoin.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.QueryJoinItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.QueryJoin} returns this
 */
proto.proto.QueryJoin.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryJoinItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryJoinItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryJoinItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryJoinItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leftSchema: jspb.Message.getFieldWithDefault(msg, 2, ""),
    leftTable: jspb.Message.getFieldWithDefault(msg, 3, ""),
    leftTableAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    leftTableField: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rightSchema: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rightTable: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rightTableAlias: jspb.Message.getFieldWithDefault(msg, 8, ""),
    rightTableField: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rightTableParams: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryJoinItem}
 */
proto.proto.QueryJoinItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryJoinItem;
  return proto.proto.QueryJoinItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryJoinItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryJoinItem}
 */
proto.proto.QueryJoinItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftSchema(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftTable(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftTableAlias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftTableField(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightSchema(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightTable(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightTableAlias(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightTableField(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightTableParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryJoinItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryJoinItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryJoinItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryJoinItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeftSchema();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLeftTable();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLeftTableAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLeftTableField();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRightSchema();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRightTable();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRightTableAlias();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRightTableField();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRightTableParams();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string left_schema = 2;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getLeftSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setLeftSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string left_table = 3;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getLeftTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setLeftTable = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string left_table_alias = 4;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getLeftTableAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setLeftTableAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string left_table_field = 5;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getLeftTableField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setLeftTableField = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string right_schema = 6;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getRightSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setRightSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string right_table = 7;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getRightTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setRightTable = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string right_table_alias = 8;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getRightTableAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setRightTableAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string right_table_field = 9;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getRightTableField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setRightTableField = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string right_table_params = 10;
 * @return {string}
 */
proto.proto.QueryJoinItem.prototype.getRightTableParams = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryJoinItem} returns this
 */
proto.proto.QueryJoinItem.prototype.setRightTableParams = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QuerySort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QuerySort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QuerySort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QuerySort.toObject = function(includeInstance, msg) {
  var f, obj = {
    table: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QuerySort}
 */
proto.proto.QuerySort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QuerySort;
  return proto.proto.QuerySort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QuerySort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QuerySort}
 */
proto.proto.QuerySort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QuerySort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QuerySort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QuerySort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QuerySort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string table = 1;
 * @return {string}
 */
proto.proto.QuerySort.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QuerySort} returns this
 */
proto.proto.QuerySort.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.proto.QuerySort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QuerySort} returns this
 */
proto.proto.QuerySort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string exp = 3;
 * @return {string}
 */
proto.proto.QuerySort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QuerySort} returns this
 */
proto.proto.QuerySort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 priority = 4;
 * @return {number}
 */
proto.proto.QuerySort.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QuerySort} returns this
 */
proto.proto.QuerySort.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    table: jspb.Message.getFieldWithDefault(msg, 1, ""),
    byField: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryGroup}
 */
proto.proto.QueryGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryGroup;
  return proto.proto.QueryGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryGroup}
 */
proto.proto.QueryGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setByField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getByField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string table = 1;
 * @return {string}
 */
proto.proto.QueryGroup.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryGroup} returns this
 */
proto.proto.QueryGroup.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string by_field = 2;
 * @return {string}
 */
proto.proto.QueryGroup.prototype.getByField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QueryGroup} returns this
 */
proto.proto.QueryGroup.prototype.setByField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QueryLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QueryLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QueryLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QueryLimit}
 */
proto.proto.QueryLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QueryLimit;
  return proto.proto.QueryLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QueryLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QueryLimit}
 */
proto.proto.QueryLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QueryLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QueryLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QueryLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QueryLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.proto.QueryLimit.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QueryLimit} returns this
 */
proto.proto.QueryLimit.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.proto.QueryLimit.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QueryLimit} returns this
 */
proto.proto.QueryLimit.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QbuilderFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QbuilderFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QbuilderFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.QbuilderFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QbuilderFilterItem}
 */
proto.proto.QbuilderFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QbuilderFilterItem;
  return proto.proto.QbuilderFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QbuilderFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QbuilderFilterItem}
 */
proto.proto.QbuilderFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.QbuilderFilter;
      reader.readMessage(value,proto.proto.QbuilderFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QbuilderFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QbuilderFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QbuilderFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.QbuilderFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.QbuilderFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QbuilderFilterItem} returns this
 */
proto.proto.QbuilderFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.QbuilderFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QbuilderFilterItem} returns this
 */
proto.proto.QbuilderFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.QbuilderFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QbuilderFilterItem} returns this
 */
proto.proto.QbuilderFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional QbuilderFilter or = 4;
 * @return {?proto.proto.QbuilderFilter}
 */
proto.proto.QbuilderFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.QbuilderFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.QbuilderFilter, 4));
};


/**
 * @param {?proto.proto.QbuilderFilter|undefined} value
 * @return {!proto.proto.QbuilderFilterItem} returns this
*/
proto.proto.QbuilderFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.QbuilderFilterItem} returns this
 */
proto.proto.QbuilderFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.QbuilderFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.QbuilderFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QbuilderFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QbuilderFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QbuilderFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.QbuilderFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QbuilderFilter}
 */
proto.proto.QbuilderFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QbuilderFilter;
  return proto.proto.QbuilderFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QbuilderFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QbuilderFilter}
 */
proto.proto.QbuilderFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QbuilderFilterItem;
      reader.readMessage(value,proto.proto.QbuilderFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QbuilderFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QbuilderFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QbuilderFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.QbuilderFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QbuilderFilterItem items = 1;
 * @return {!Array<!proto.proto.QbuilderFilterItem>}
 */
proto.proto.QbuilderFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.QbuilderFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.QbuilderFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.QbuilderFilterItem>} value
 * @return {!proto.proto.QbuilderFilter} returns this
*/
proto.proto.QbuilderFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.QbuilderFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.QbuilderFilterItem}
 */
proto.proto.QbuilderFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.QbuilderFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.QbuilderFilter} returns this
 */
proto.proto.QbuilderFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QbuilderSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QbuilderSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QbuilderSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QbuilderSort}
 */
proto.proto.QbuilderSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QbuilderSort;
  return proto.proto.QbuilderSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QbuilderSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QbuilderSort}
 */
proto.proto.QbuilderSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QbuilderSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QbuilderSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QbuilderSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.QbuilderSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QbuilderSort} returns this
 */
proto.proto.QbuilderSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.QbuilderSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.QbuilderSort} returns this
 */
proto.proto.QbuilderSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.QbuilderPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.QbuilderPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.QbuilderPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.QbuilderPagination}
 */
proto.proto.QbuilderPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.QbuilderPagination;
  return proto.proto.QbuilderPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.QbuilderPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.QbuilderPagination}
 */
proto.proto.QbuilderPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.QbuilderPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.QbuilderPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.QbuilderPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.QbuilderPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.QbuilderPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QbuilderPagination} returns this
 */
proto.proto.QbuilderPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.QbuilderPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QbuilderPagination} returns this
 */
proto.proto.QbuilderPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.QbuilderPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QbuilderPagination} returns this
 */
proto.proto.QbuilderPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.QbuilderPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.QbuilderPagination} returns this
 */
proto.proto.QbuilderPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ForcedLimit.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ForcedLimit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ForcedLimit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ForcedLimit.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ForcedLimit}
 */
proto.proto.ForcedLimit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ForcedLimit;
  return proto.proto.ForcedLimit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ForcedLimit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ForcedLimit}
 */
proto.proto.ForcedLimit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ForcedLimit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ForcedLimit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ForcedLimit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ForcedLimit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.proto.ForcedLimit.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ForcedLimit} returns this
 */
proto.proto.ForcedLimit.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.proto.ForcedLimit.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ForcedLimit} returns this
 */
proto.proto.ForcedLimit.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListQbuilderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListQbuilderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListQbuilderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListQbuilderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.QbuilderFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.QbuilderSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.QbuilderPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListQbuilderRequest}
 */
proto.proto.ListQbuilderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListQbuilderRequest;
  return proto.proto.ListQbuilderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListQbuilderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListQbuilderRequest}
 */
proto.proto.ListQbuilderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QbuilderFilter;
      reader.readMessage(value,proto.proto.QbuilderFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.QbuilderSort;
      reader.readMessage(value,proto.proto.QbuilderSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.QbuilderPagination;
      reader.readMessage(value,proto.proto.QbuilderPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListQbuilderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListQbuilderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListQbuilderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListQbuilderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.QbuilderFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.QbuilderSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.QbuilderPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional QbuilderFilter filter = 1;
 * @return {?proto.proto.QbuilderFilter}
 */
proto.proto.ListQbuilderRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.QbuilderFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.QbuilderFilter, 1));
};


/**
 * @param {?proto.proto.QbuilderFilter|undefined} value
 * @return {!proto.proto.ListQbuilderRequest} returns this
*/
proto.proto.ListQbuilderRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListQbuilderRequest} returns this
 */
proto.proto.ListQbuilderRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListQbuilderRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QbuilderSort sort = 2;
 * @return {?proto.proto.QbuilderSort}
 */
proto.proto.ListQbuilderRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.QbuilderSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.QbuilderSort, 2));
};


/**
 * @param {?proto.proto.QbuilderSort|undefined} value
 * @return {!proto.proto.ListQbuilderRequest} returns this
*/
proto.proto.ListQbuilderRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListQbuilderRequest} returns this
 */
proto.proto.ListQbuilderRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListQbuilderRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional QbuilderPagination pagination = 3;
 * @return {?proto.proto.QbuilderPagination}
 */
proto.proto.ListQbuilderRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.QbuilderPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.QbuilderPagination, 3));
};


/**
 * @param {?proto.proto.QbuilderPagination|undefined} value
 * @return {!proto.proto.ListQbuilderRequest} returns this
*/
proto.proto.ListQbuilderRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListQbuilderRequest} returns this
 */
proto.proto.ListQbuilderRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListQbuilderRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListQbuilderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Qbuilder.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListQbuilderRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListQbuilderResponse}
 */
proto.proto.ListQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListQbuilderResponse;
  return proto.proto.ListQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListQbuilderResponse}
 */
proto.proto.ListQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Qbuilder;
      reader.readMessage(value,proto.proto.Qbuilder.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListQbuilderRequest;
      reader.readMessage(value,proto.proto.ListQbuilderRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Qbuilder.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListQbuilderRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Qbuilder items = 1;
 * @return {!Array<!proto.proto.Qbuilder>}
 */
proto.proto.ListQbuilderResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Qbuilder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Qbuilder, 1));
};


/**
 * @param {!Array<!proto.proto.Qbuilder>} value
 * @return {!proto.proto.ListQbuilderResponse} returns this
*/
proto.proto.ListQbuilderResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Qbuilder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Qbuilder}
 */
proto.proto.ListQbuilderResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Qbuilder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListQbuilderResponse} returns this
 */
proto.proto.ListQbuilderResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListQbuilderRequest params = 2;
 * @return {?proto.proto.ListQbuilderRequest}
 */
proto.proto.ListQbuilderResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListQbuilderRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListQbuilderRequest, 2));
};


/**
 * @param {?proto.proto.ListQbuilderRequest|undefined} value
 * @return {!proto.proto.ListQbuilderResponse} returns this
*/
proto.proto.ListQbuilderResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListQbuilderResponse} returns this
 */
proto.proto.ListQbuilderResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListQbuilderResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateQbuilderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateQbuilderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateQbuilderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateQbuilderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    qbuilderAuthorId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    qbuilderDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qbuilderSourceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    qbuilderFields: jspb.Message.getFieldWithDefault(msg, 5, ""),
    qbuilderQuery: (f = msg.getQbuilderQuery()) && proto.proto.Query.toObject(includeInstance, f),
    qbuilderExecAll: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateQbuilderRequest}
 */
proto.proto.CreateQbuilderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateQbuilderRequest;
  return proto.proto.CreateQbuilderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateQbuilderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateQbuilderRequest}
 */
proto.proto.CreateQbuilderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderAuthorId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderSourceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderFields(value);
      break;
    case 6:
      var value = new proto.proto.Query;
      reader.readMessage(value,proto.proto.Query.deserializeBinaryFromReader);
      msg.setQbuilderQuery(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQbuilderExecAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateQbuilderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateQbuilderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateQbuilderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateQbuilderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQbuilderAuthorId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getQbuilderDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQbuilderSourceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getQbuilderFields();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQbuilderQuery();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.proto.Query.serializeBinaryToWriter
    );
  }
  f = message.getQbuilderExecAll();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string qbuilder_name = 1;
 * @return {string}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 qbuilder_author_id = 2;
 * @return {number}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderAuthorId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string qbuilder_description = 3;
 * @return {string}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 qbuilder_source_id = 4;
 * @return {number}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string qbuilder_fields = 5;
 * @return {string}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderFields = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderFields = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Query qbuilder_query = 6;
 * @return {?proto.proto.Query}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderQuery = function() {
  return /** @type{?proto.proto.Query} */ (
    jspb.Message.getWrapperField(this, proto.proto.Query, 6));
};


/**
 * @param {?proto.proto.Query|undefined} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
*/
proto.proto.CreateQbuilderRequest.prototype.setQbuilderQuery = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.clearQbuilderQuery = function() {
  return this.setQbuilderQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateQbuilderRequest.prototype.hasQbuilderQuery = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool qbuilder_exec_all = 7;
 * @return {boolean}
 */
proto.proto.CreateQbuilderRequest.prototype.getQbuilderExecAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CreateQbuilderRequest} returns this
 */
proto.proto.CreateQbuilderRequest.prototype.setQbuilderExecAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateQbuilderResponse}
 */
proto.proto.CreateQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateQbuilderResponse;
  return proto.proto.CreateQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateQbuilderResponse}
 */
proto.proto.CreateQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.CreateQbuilderResponse.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateQbuilderResponse} returns this
 */
proto.proto.CreateQbuilderResponse.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateQbuilderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateQbuilderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateQbuilderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateQbuilderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qbuilderName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qbuilderDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qbuilderFields: jspb.Message.getFieldWithDefault(msg, 4, ""),
    qbuilderQuery: (f = msg.getQbuilderQuery()) && proto.proto.Query.toObject(includeInstance, f),
    qbuilderConformed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    qbuilderExecAll: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateQbuilderRequest}
 */
proto.proto.UpdateQbuilderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateQbuilderRequest;
  return proto.proto.UpdateQbuilderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateQbuilderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateQbuilderRequest}
 */
proto.proto.UpdateQbuilderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQbuilderFields(value);
      break;
    case 5:
      var value = new proto.proto.Query;
      reader.readMessage(value,proto.proto.Query.deserializeBinaryFromReader);
      msg.setQbuilderQuery(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQbuilderConformed(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQbuilderExecAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateQbuilderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateQbuilderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateQbuilderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateQbuilderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQbuilderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQbuilderDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQbuilderFields();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQbuilderQuery();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.proto.Query.serializeBinaryToWriter
    );
  }
  f = message.getQbuilderConformed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getQbuilderExecAll();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string qbuilder_name = 2;
 * @return {string}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string qbuilder_description = 3;
 * @return {string}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string qbuilder_fields = 4;
 * @return {string}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderFields = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderFields = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Query qbuilder_query = 5;
 * @return {?proto.proto.Query}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderQuery = function() {
  return /** @type{?proto.proto.Query} */ (
    jspb.Message.getWrapperField(this, proto.proto.Query, 5));
};


/**
 * @param {?proto.proto.Query|undefined} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
*/
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderQuery = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.clearQbuilderQuery = function() {
  return this.setQbuilderQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateQbuilderRequest.prototype.hasQbuilderQuery = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool qbuilder_conformed = 6;
 * @return {boolean}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderConformed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderConformed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool qbuilder_exec_all = 7;
 * @return {boolean}
 */
proto.proto.UpdateQbuilderRequest.prototype.getQbuilderExecAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateQbuilderRequest} returns this
 */
proto.proto.UpdateQbuilderRequest.prototype.setQbuilderExecAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateQbuilderResponse}
 */
proto.proto.UpdateQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateQbuilderResponse;
  return proto.proto.UpdateQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateQbuilderResponse}
 */
proto.proto.UpdateQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.UpdateQbuilderResponse.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateQbuilderResponse} returns this
 */
proto.proto.UpdateQbuilderResponse.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteQbuilderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteQbuilderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteQbuilderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteQbuilderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteQbuilderRequest}
 */
proto.proto.DeleteQbuilderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteQbuilderRequest;
  return proto.proto.DeleteQbuilderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteQbuilderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteQbuilderRequest}
 */
proto.proto.DeleteQbuilderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteQbuilderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteQbuilderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteQbuilderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteQbuilderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.DeleteQbuilderRequest.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteQbuilderRequest} returns this
 */
proto.proto.DeleteQbuilderRequest.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteQbuilderResponse}
 */
proto.proto.DeleteQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteQbuilderResponse;
  return proto.proto.DeleteQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteQbuilderResponse}
 */
proto.proto.DeleteQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.DeleteQbuilderResponse.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteQbuilderResponse} returns this
 */
proto.proto.DeleteQbuilderResponse.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExecuteQbuilderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExecuteQbuilderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExecuteQbuilderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteQbuilderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qbuilderQuery: (f = msg.getQbuilderQuery()) && proto.proto.Query.toObject(includeInstance, f),
    forcedLimit: (f = msg.getForcedLimit()) && proto.proto.ForcedLimit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExecuteQbuilderRequest}
 */
proto.proto.ExecuteQbuilderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExecuteQbuilderRequest;
  return proto.proto.ExecuteQbuilderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExecuteQbuilderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExecuteQbuilderRequest}
 */
proto.proto.ExecuteQbuilderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    case 2:
      var value = new proto.proto.Query;
      reader.readMessage(value,proto.proto.Query.deserializeBinaryFromReader);
      msg.setQbuilderQuery(value);
      break;
    case 3:
      var value = new proto.proto.ForcedLimit;
      reader.readMessage(value,proto.proto.ForcedLimit.deserializeBinaryFromReader);
      msg.setForcedLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExecuteQbuilderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExecuteQbuilderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExecuteQbuilderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteQbuilderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQbuilderQuery();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Query.serializeBinaryToWriter
    );
  }
  f = message.getForcedLimit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ForcedLimit.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.ExecuteQbuilderRequest.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ExecuteQbuilderRequest} returns this
 */
proto.proto.ExecuteQbuilderRequest.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Query qbuilder_query = 2;
 * @return {?proto.proto.Query}
 */
proto.proto.ExecuteQbuilderRequest.prototype.getQbuilderQuery = function() {
  return /** @type{?proto.proto.Query} */ (
    jspb.Message.getWrapperField(this, proto.proto.Query, 2));
};


/**
 * @param {?proto.proto.Query|undefined} value
 * @return {!proto.proto.ExecuteQbuilderRequest} returns this
*/
proto.proto.ExecuteQbuilderRequest.prototype.setQbuilderQuery = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ExecuteQbuilderRequest} returns this
 */
proto.proto.ExecuteQbuilderRequest.prototype.clearQbuilderQuery = function() {
  return this.setQbuilderQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ExecuteQbuilderRequest.prototype.hasQbuilderQuery = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ForcedLimit forced_limit = 3;
 * @return {?proto.proto.ForcedLimit}
 */
proto.proto.ExecuteQbuilderRequest.prototype.getForcedLimit = function() {
  return /** @type{?proto.proto.ForcedLimit} */ (
    jspb.Message.getWrapperField(this, proto.proto.ForcedLimit, 3));
};


/**
 * @param {?proto.proto.ForcedLimit|undefined} value
 * @return {!proto.proto.ExecuteQbuilderRequest} returns this
*/
proto.proto.ExecuteQbuilderRequest.prototype.setForcedLimit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ExecuteQbuilderRequest} returns this
 */
proto.proto.ExecuteQbuilderRequest.prototype.clearForcedLimit = function() {
  return this.setForcedLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ExecuteQbuilderRequest.prototype.hasForcedLimit = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExecuteQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExecuteQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExecuteQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryResult: jspb.Message.getFieldWithDefault(msg, 1, ""),
    querySql: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExecuteQbuilderResponse}
 */
proto.proto.ExecuteQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExecuteQbuilderResponse;
  return proto.proto.ExecuteQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExecuteQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExecuteQbuilderResponse}
 */
proto.proto.ExecuteQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuerySql(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExecuteQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExecuteQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExecuteQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryResult();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuerySql();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string query_result = 1;
 * @return {string}
 */
proto.proto.ExecuteQbuilderResponse.prototype.getQueryResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ExecuteQbuilderResponse} returns this
 */
proto.proto.ExecuteQbuilderResponse.prototype.setQueryResult = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query_sql = 2;
 * @return {string}
 */
proto.proto.ExecuteQbuilderResponse.prototype.getQuerySql = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ExecuteQbuilderResponse} returns this
 */
proto.proto.ExecuteQbuilderResponse.prototype.setQuerySql = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetTableAliasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetTableAliasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetTableAliasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetTableAliasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tablePath: (f = msg.getTablePath()) && proto.proto.QueryFrom.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetTableAliasRequest}
 */
proto.proto.GetTableAliasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetTableAliasRequest;
  return proto.proto.GetTableAliasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetTableAliasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetTableAliasRequest}
 */
proto.proto.GetTableAliasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.QueryFrom;
      reader.readMessage(value,proto.proto.QueryFrom.deserializeBinaryFromReader);
      msg.setTablePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetTableAliasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetTableAliasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetTableAliasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetTableAliasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTablePath();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.QueryFrom.serializeBinaryToWriter
    );
  }
};


/**
 * optional QueryFrom table_path = 1;
 * @return {?proto.proto.QueryFrom}
 */
proto.proto.GetTableAliasRequest.prototype.getTablePath = function() {
  return /** @type{?proto.proto.QueryFrom} */ (
    jspb.Message.getWrapperField(this, proto.proto.QueryFrom, 1));
};


/**
 * @param {?proto.proto.QueryFrom|undefined} value
 * @return {!proto.proto.GetTableAliasRequest} returns this
*/
proto.proto.GetTableAliasRequest.prototype.setTablePath = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetTableAliasRequest} returns this
 */
proto.proto.GetTableAliasRequest.prototype.clearTablePath = function() {
  return this.setTablePath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetTableAliasRequest.prototype.hasTablePath = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetTableAliasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetTableAliasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetTableAliasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetTableAliasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableAlias: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetTableAliasResponse}
 */
proto.proto.GetTableAliasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetTableAliasResponse;
  return proto.proto.GetTableAliasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetTableAliasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetTableAliasResponse}
 */
proto.proto.GetTableAliasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetTableAliasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetTableAliasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetTableAliasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetTableAliasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableAlias();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string table_alias = 1;
 * @return {string}
 */
proto.proto.GetTableAliasResponse.prototype.getTableAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetTableAliasResponse} returns this
 */
proto.proto.GetTableAliasResponse.prototype.setTableAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExportExecuteQbuilderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExportExecuteQbuilderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportExecuteQbuilderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExportExecuteQbuilderResponse}
 */
proto.proto.ExportExecuteQbuilderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExportExecuteQbuilderResponse;
  return proto.proto.ExportExecuteQbuilderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExportExecuteQbuilderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExportExecuteQbuilderResponse}
 */
proto.proto.ExportExecuteQbuilderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExportExecuteQbuilderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExportExecuteQbuilderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportExecuteQbuilderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.ExportExecuteQbuilderResponse} returns this
 */
proto.proto.ExportExecuteQbuilderResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetSqlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetSqlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetSqlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetSqlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbuilderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qbuilderQuery: (f = msg.getQbuilderQuery()) && proto.proto.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetSqlRequest}
 */
proto.proto.GetSqlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetSqlRequest;
  return proto.proto.GetSqlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetSqlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetSqlRequest}
 */
proto.proto.GetSqlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQbuilderId(value);
      break;
    case 2:
      var value = new proto.proto.Query;
      reader.readMessage(value,proto.proto.Query.deserializeBinaryFromReader);
      msg.setQbuilderQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetSqlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetSqlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetSqlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetSqlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQbuilderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQbuilderQuery();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 qbuilder_id = 1;
 * @return {number}
 */
proto.proto.GetSqlRequest.prototype.getQbuilderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetSqlRequest} returns this
 */
proto.proto.GetSqlRequest.prototype.setQbuilderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Query qbuilder_query = 2;
 * @return {?proto.proto.Query}
 */
proto.proto.GetSqlRequest.prototype.getQbuilderQuery = function() {
  return /** @type{?proto.proto.Query} */ (
    jspb.Message.getWrapperField(this, proto.proto.Query, 2));
};


/**
 * @param {?proto.proto.Query|undefined} value
 * @return {!proto.proto.GetSqlRequest} returns this
*/
proto.proto.GetSqlRequest.prototype.setQbuilderQuery = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetSqlRequest} returns this
 */
proto.proto.GetSqlRequest.prototype.clearQbuilderQuery = function() {
  return this.setQbuilderQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetSqlRequest.prototype.hasQbuilderQuery = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetSqlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetSqlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetSqlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetSqlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sqlQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    params: (f = msg.getParams()) && proto.proto.GetSqlRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetSqlResponse}
 */
proto.proto.GetSqlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetSqlResponse;
  return proto.proto.GetSqlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetSqlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetSqlResponse}
 */
proto.proto.GetSqlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSqlQuery(value);
      break;
    case 2:
      var value = new proto.proto.GetSqlRequest;
      reader.readMessage(value,proto.proto.GetSqlRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetSqlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetSqlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetSqlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetSqlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSqlQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.GetSqlRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sql_query = 1;
 * @return {string}
 */
proto.proto.GetSqlResponse.prototype.getSqlQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetSqlResponse} returns this
 */
proto.proto.GetSqlResponse.prototype.setSqlQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GetSqlRequest params = 2;
 * @return {?proto.proto.GetSqlRequest}
 */
proto.proto.GetSqlResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.GetSqlRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.GetSqlRequest, 2));
};


/**
 * @param {?proto.proto.GetSqlRequest|undefined} value
 * @return {!proto.proto.GetSqlResponse} returns this
*/
proto.proto.GetSqlResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetSqlResponse} returns this
 */
proto.proto.GetSqlResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetSqlResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
