import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","min-height":"334px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Редактирование проекта ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isProjectsBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.short_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.short_name) = $event)),
                      label: "Краткое наименование",
                      maxlength: "32"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.name) = $event)),
                      label: "Полное наименование",
                      maxlength: "256"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.saveProject
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Сохранить ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeProjectEdit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Отмена ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_DataLoading, { "msg-loading": "Сохранение" })
          ]))
    ]),
    _: 1
  }))
}