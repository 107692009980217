// source: message_grp_cls.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.proto.ListMessageGrpClsInRequest', null, global);
goog.exportSymbol('proto.proto.ListMessageGrpClsInResponse', null, global);
goog.exportSymbol('proto.proto.ListMessageGrpClsOutRequest', null, global);
goog.exportSymbol('proto.proto.ListMessageGrpClsOutResponse', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsFilter', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsFilterItem', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsIn', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsOut', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsPagination', null, global);
goog.exportSymbol('proto.proto.MessageGrpClsSort', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageGrpClsIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsIn.displayName = 'proto.proto.MessageGrpClsIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageGrpClsOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsOut.displayName = 'proto.proto.MessageGrpClsOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageGrpClsFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsFilterItem.displayName = 'proto.proto.MessageGrpClsFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.MessageGrpClsFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.MessageGrpClsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsFilter.displayName = 'proto.proto.MessageGrpClsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageGrpClsSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsSort.displayName = 'proto.proto.MessageGrpClsSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.MessageGrpClsPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.MessageGrpClsPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.MessageGrpClsPagination.displayName = 'proto.proto.MessageGrpClsPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageGrpClsInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListMessageGrpClsInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageGrpClsInRequest.displayName = 'proto.proto.ListMessageGrpClsInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageGrpClsInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMessageGrpClsInResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMessageGrpClsInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageGrpClsInResponse.displayName = 'proto.proto.ListMessageGrpClsInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageGrpClsOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListMessageGrpClsOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageGrpClsOutRequest.displayName = 'proto.proto.ListMessageGrpClsOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListMessageGrpClsOutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListMessageGrpClsOutResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListMessageGrpClsOutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListMessageGrpClsOutResponse.displayName = 'proto.proto.ListMessageGrpClsOutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsIn.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageGrpSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsIn}
 */
proto.proto.MessageGrpClsIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsIn;
  return proto.proto.MessageGrpClsIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsIn}
 */
proto.proto.MessageGrpClsIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpSum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.MessageGrpClsIn.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_grp_text = 2;
 * @return {string}
 */
proto.proto.MessageGrpClsIn.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 message_grp_count = 3;
 * @return {number}
 */
proto.proto.MessageGrpClsIn.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 message_grp_sum = 4;
 * @return {number}
 */
proto.proto.MessageGrpClsIn.prototype.getMessageGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setMessageGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 class_id = 5;
 * @return {number}
 */
proto.proto.MessageGrpClsIn.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string notes = 6;
 * @return {string}
 */
proto.proto.MessageGrpClsIn.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsIn} returns this
 */
proto.proto.MessageGrpClsIn.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsOut.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageGrpSum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasClasses: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsOut}
 */
proto.proto.MessageGrpClsOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsOut;
  return proto.proto.MessageGrpClsOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsOut}
 */
proto.proto.MessageGrpClsOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpSum(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasClasses();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.MessageGrpClsOut.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsOut} returns this
 */
proto.proto.MessageGrpClsOut.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_grp_text = 2;
 * @return {string}
 */
proto.proto.MessageGrpClsOut.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsOut} returns this
 */
proto.proto.MessageGrpClsOut.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 message_grp_count = 3;
 * @return {number}
 */
proto.proto.MessageGrpClsOut.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsOut} returns this
 */
proto.proto.MessageGrpClsOut.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 message_grp_sum = 4;
 * @return {number}
 */
proto.proto.MessageGrpClsOut.prototype.getMessageGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsOut} returns this
 */
proto.proto.MessageGrpClsOut.prototype.setMessageGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_classes = 5;
 * @return {boolean}
 */
proto.proto.MessageGrpClsOut.prototype.getHasClasses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.MessageGrpClsOut} returns this
 */
proto.proto.MessageGrpClsOut.prototype.setHasClasses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.MessageGrpClsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsFilterItem}
 */
proto.proto.MessageGrpClsFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsFilterItem;
  return proto.proto.MessageGrpClsFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsFilterItem}
 */
proto.proto.MessageGrpClsFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.MessageGrpClsFilter;
      reader.readMessage(value,proto.proto.MessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.MessageGrpClsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.MessageGrpClsFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsFilterItem} returns this
 */
proto.proto.MessageGrpClsFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.MessageGrpClsFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsFilterItem} returns this
 */
proto.proto.MessageGrpClsFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.MessageGrpClsFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsFilterItem} returns this
 */
proto.proto.MessageGrpClsFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MessageGrpClsFilter or = 4;
 * @return {?proto.proto.MessageGrpClsFilter}
 */
proto.proto.MessageGrpClsFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.MessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsFilter, 4));
};


/**
 * @param {?proto.proto.MessageGrpClsFilter|undefined} value
 * @return {!proto.proto.MessageGrpClsFilterItem} returns this
*/
proto.proto.MessageGrpClsFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.MessageGrpClsFilterItem} returns this
 */
proto.proto.MessageGrpClsFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.MessageGrpClsFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.MessageGrpClsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageGrpClsFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsFilter}
 */
proto.proto.MessageGrpClsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsFilter;
  return proto.proto.MessageGrpClsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsFilter}
 */
proto.proto.MessageGrpClsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageGrpClsFilterItem;
      reader.readMessage(value,proto.proto.MessageGrpClsFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageGrpClsFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageGrpClsFilterItem items = 1;
 * @return {!Array<!proto.proto.MessageGrpClsFilterItem>}
 */
proto.proto.MessageGrpClsFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageGrpClsFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageGrpClsFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.MessageGrpClsFilterItem>} value
 * @return {!proto.proto.MessageGrpClsFilter} returns this
*/
proto.proto.MessageGrpClsFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageGrpClsFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageGrpClsFilterItem}
 */
proto.proto.MessageGrpClsFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageGrpClsFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.MessageGrpClsFilter} returns this
 */
proto.proto.MessageGrpClsFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsSort}
 */
proto.proto.MessageGrpClsSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsSort;
  return proto.proto.MessageGrpClsSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsSort}
 */
proto.proto.MessageGrpClsSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.MessageGrpClsSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsSort} returns this
 */
proto.proto.MessageGrpClsSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.MessageGrpClsSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.MessageGrpClsSort} returns this
 */
proto.proto.MessageGrpClsSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.MessageGrpClsPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.MessageGrpClsPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.MessageGrpClsPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.MessageGrpClsPagination}
 */
proto.proto.MessageGrpClsPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.MessageGrpClsPagination;
  return proto.proto.MessageGrpClsPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.MessageGrpClsPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.MessageGrpClsPagination}
 */
proto.proto.MessageGrpClsPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.MessageGrpClsPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.MessageGrpClsPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.MessageGrpClsPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.MessageGrpClsPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.MessageGrpClsPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsPagination} returns this
 */
proto.proto.MessageGrpClsPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.MessageGrpClsPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsPagination} returns this
 */
proto.proto.MessageGrpClsPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.MessageGrpClsPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsPagination} returns this
 */
proto.proto.MessageGrpClsPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.MessageGrpClsPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.MessageGrpClsPagination} returns this
 */
proto.proto.MessageGrpClsPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageGrpClsInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageGrpClsInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.MessageGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.MessageGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.MessageGrpClsPagination.toObject(includeInstance, f),
    classId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageGrpClsInRequest}
 */
proto.proto.ListMessageGrpClsInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageGrpClsInRequest;
  return proto.proto.ListMessageGrpClsInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageGrpClsInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageGrpClsInRequest}
 */
proto.proto.ListMessageGrpClsInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageGrpClsFilter;
      reader.readMessage(value,proto.proto.MessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.MessageGrpClsSort;
      reader.readMessage(value,proto.proto.MessageGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.MessageGrpClsPagination;
      reader.readMessage(value,proto.proto.MessageGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageGrpClsInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageGrpClsInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.MessageGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.MessageGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.MessageGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional MessageGrpClsFilter filter = 1;
 * @return {?proto.proto.MessageGrpClsFilter}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.MessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.MessageGrpClsFilter|undefined} value
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
*/
proto.proto.ListMessageGrpClsInRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
 */
proto.proto.ListMessageGrpClsInRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageGrpClsSort sort = 2;
 * @return {?proto.proto.MessageGrpClsSort}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.MessageGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsSort, 2));
};


/**
 * @param {?proto.proto.MessageGrpClsSort|undefined} value
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
*/
proto.proto.ListMessageGrpClsInRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
 */
proto.proto.ListMessageGrpClsInRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MessageGrpClsPagination pagination = 3;
 * @return {?proto.proto.MessageGrpClsPagination}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.MessageGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.MessageGrpClsPagination|undefined} value
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
*/
proto.proto.ListMessageGrpClsInRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
 */
proto.proto.ListMessageGrpClsInRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 class_id = 4;
 * @return {number}
 */
proto.proto.ListMessageGrpClsInRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMessageGrpClsInRequest} returns this
 */
proto.proto.ListMessageGrpClsInRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMessageGrpClsInResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageGrpClsInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageGrpClsInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageGrpClsIn.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListMessageGrpClsInRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageGrpClsInResponse}
 */
proto.proto.ListMessageGrpClsInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageGrpClsInResponse;
  return proto.proto.ListMessageGrpClsInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageGrpClsInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageGrpClsInResponse}
 */
proto.proto.ListMessageGrpClsInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageGrpClsIn;
      reader.readMessage(value,proto.proto.MessageGrpClsIn.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListMessageGrpClsInRequest;
      reader.readMessage(value,proto.proto.ListMessageGrpClsInRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageGrpClsInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageGrpClsInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageGrpClsIn.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListMessageGrpClsInRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageGrpClsIn items = 1;
 * @return {!Array<!proto.proto.MessageGrpClsIn>}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageGrpClsIn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageGrpClsIn, 1));
};


/**
 * @param {!Array<!proto.proto.MessageGrpClsIn>} value
 * @return {!proto.proto.ListMessageGrpClsInResponse} returns this
*/
proto.proto.ListMessageGrpClsInResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageGrpClsIn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageGrpClsIn}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageGrpClsIn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMessageGrpClsInResponse} returns this
 */
proto.proto.ListMessageGrpClsInResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListMessageGrpClsInRequest params = 2;
 * @return {?proto.proto.ListMessageGrpClsInRequest}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListMessageGrpClsInRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListMessageGrpClsInRequest, 2));
};


/**
 * @param {?proto.proto.ListMessageGrpClsInRequest|undefined} value
 * @return {!proto.proto.ListMessageGrpClsInResponse} returns this
*/
proto.proto.ListMessageGrpClsInResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsInResponse} returns this
 */
proto.proto.ListMessageGrpClsInResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsInResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageGrpClsOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageGrpClsOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.MessageGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.MessageGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.MessageGrpClsPagination.toObject(includeInstance, f),
    classId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    notClassifierOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageGrpClsOutRequest}
 */
proto.proto.ListMessageGrpClsOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageGrpClsOutRequest;
  return proto.proto.ListMessageGrpClsOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageGrpClsOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageGrpClsOutRequest}
 */
proto.proto.ListMessageGrpClsOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageGrpClsFilter;
      reader.readMessage(value,proto.proto.MessageGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.MessageGrpClsSort;
      reader.readMessage(value,proto.proto.MessageGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.MessageGrpClsPagination;
      reader.readMessage(value,proto.proto.MessageGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotClassifierOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageGrpClsOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageGrpClsOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.MessageGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.MessageGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.MessageGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNotClassifierOnly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional MessageGrpClsFilter filter = 1;
 * @return {?proto.proto.MessageGrpClsFilter}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.MessageGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.MessageGrpClsFilter|undefined} value
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
*/
proto.proto.ListMessageGrpClsOutRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MessageGrpClsSort sort = 2;
 * @return {?proto.proto.MessageGrpClsSort}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.MessageGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsSort, 2));
};


/**
 * @param {?proto.proto.MessageGrpClsSort|undefined} value
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
*/
proto.proto.ListMessageGrpClsOutRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MessageGrpClsPagination pagination = 3;
 * @return {?proto.proto.MessageGrpClsPagination}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.MessageGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.MessageGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.MessageGrpClsPagination|undefined} value
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
*/
proto.proto.ListMessageGrpClsOutRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 class_id = 4;
 * @return {number}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool not_classifier_only = 5;
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.getNotClassifierOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListMessageGrpClsOutRequest} returns this
 */
proto.proto.ListMessageGrpClsOutRequest.prototype.setNotClassifierOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListMessageGrpClsOutResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListMessageGrpClsOutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListMessageGrpClsOutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsOutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.MessageGrpClsOut.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListMessageGrpClsOutRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListMessageGrpClsOutResponse}
 */
proto.proto.ListMessageGrpClsOutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListMessageGrpClsOutResponse;
  return proto.proto.ListMessageGrpClsOutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListMessageGrpClsOutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListMessageGrpClsOutResponse}
 */
proto.proto.ListMessageGrpClsOutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.MessageGrpClsOut;
      reader.readMessage(value,proto.proto.MessageGrpClsOut.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListMessageGrpClsOutRequest;
      reader.readMessage(value,proto.proto.ListMessageGrpClsOutRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListMessageGrpClsOutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListMessageGrpClsOutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListMessageGrpClsOutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.MessageGrpClsOut.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListMessageGrpClsOutRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MessageGrpClsOut items = 1;
 * @return {!Array<!proto.proto.MessageGrpClsOut>}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.MessageGrpClsOut>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.MessageGrpClsOut, 1));
};


/**
 * @param {!Array<!proto.proto.MessageGrpClsOut>} value
 * @return {!proto.proto.ListMessageGrpClsOutResponse} returns this
*/
proto.proto.ListMessageGrpClsOutResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.MessageGrpClsOut=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.MessageGrpClsOut}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.MessageGrpClsOut, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListMessageGrpClsOutResponse} returns this
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListMessageGrpClsOutRequest params = 2;
 * @return {?proto.proto.ListMessageGrpClsOutRequest}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListMessageGrpClsOutRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListMessageGrpClsOutRequest, 2));
};


/**
 * @param {?proto.proto.ListMessageGrpClsOutRequest|undefined} value
 * @return {!proto.proto.ListMessageGrpClsOutResponse} returns this
*/
proto.proto.ListMessageGrpClsOutResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListMessageGrpClsOutResponse} returns this
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListMessageGrpClsOutResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
